import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AfterViewInit, ElementRef, ViewChild , signal} from '@angular/core';
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import * as moment from "moment";
import { MatMenu } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { CONSTANTS } from '../../app.constants';

@Component({
  selector: "app-rb-commitments",
  templateUrl: "./rb-commitments.component.html",
  styleUrls: ["./rb-commitments.component.scss"],
})
export class RbCommitmentsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  constants = CONSTANTS;
  @Input() sectionData: any;
  @Input() sectionName: any;
  @Output() isValid: EventEmitter<boolean> = new EventEmitter();
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  searchPackage: any = {};
  orderDetails: any;
  isQualiaOrder = false;
  isRemoteLanguage = null;
  isCreateSend = false;
  sectionType: any = 0;
  commitmentItemTypes: any;
  categoryOptions = [
    { label: "Requirement", value: "R" },
    { label: "Exception", value: "E" },
    { label: "Vesting", value: "V" },
    { label: "No Entry", value: "N" },
  ];
  Cust_Commit_Type_Id: any;
  commitmentItem: any = {};
  dbCommitmentItem: any = null;
  fieldSet = [];
  placeHolderPairs = [
    ["_", "_"],
    ["{", "}"],
  ];
  Cmtmnt_Mappings = {
    1: "SRC_PT",
    2: "ASS_NS",
    3: "TAX_ES",
    4: "VES_DD",
    5: "CHN_TL",
    6: "SCR_IN",
    7: "LEN_JG",
    8: "ERA",
  };
  pippinExpsItems: any = [];
  pippinReqsItems: any = [];
  qualiaExpsItems: any = [];
  qualiaReqsItems: any = [];
  errors: any = [];
  templateOptions: any = [];
  templateText = signal<String>("");

  constructor(
    private frmBuilder: UntypedFormBuilder,
    private builder: RptBuilderService,
    private dialog: MatDialog
  ) {
    this.dataForm = this.frmBuilder.group({
      Category: [null],
      Type_Id: [null],
      Statement: [null],
      Language: [null],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveItem(this.dataForm));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initCommitment(data));
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dataTypes) => {
        if (dataTypes && dataTypes.SP_Item_Types !== undefined) {
          let sectionBlock = dataTypes.SP_Item_Types.find(
            (docType) =>
              docType.Name.toLowerCase() == this.sectionName.toLowerCase()
          );
          if (sectionBlock !== undefined) {
            this.sectionType = sectionBlock.Id;
            this.initComponent();
          }
        }
      });
    this.builder.isRemoteLanguage$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.isRemoteLanguage = data;
      });
    this.builder.isQualiaOrder$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.isQualiaOrder = data;
      });
    this.builder.commtmntItmTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.commitmentItemTypes = data;
        if (this.commitmentItemTypes && !this.isQualiaOrder) {
          this.pippinReqsItems = this.commitmentItemTypes.filter(
            (item) => item && item.Commitment_Type && item.Commitment_Type == 1
          );
          this.pippinExpsItems = this.commitmentItemTypes.filter(
            (item) => item && item.Commitment_Type && item.Commitment_Type == 2
          );

          if (this.pippinReqsItems.length > 0 && this.commitmentItem) {
      
            this.pippinReqsItems = [
              ...this.pippinReqsItems,
              {
                Commitment_Type: 1,
                Cust_Commit_Type_Id: this.constants.otherItemKey,
                Id: this.constants.otherItemKey, // Unique Id
                Language: "Others",
                Mandatory_Fields: null,
                Pippin_Company_Id: null,
                Type_Name: "Other",
              },
            ];
          }
  
          if (this.pippinExpsItems.length > 0 && this.commitmentItem) {
        
            this.pippinExpsItems = [
              ...this.pippinExpsItems,
              {
                Commitment_Type: 2,
                Cust_Commit_Type_Id:this.constants.otherItemKey,
                Id: this.constants.otherItemKey, // Unique Id
                Language: "Others",
                Mandatory_Fields: null,
                Pippin_Company_Id: null,
                Type_Name: "Other",
              },
            ];
          }
        }else if (this.commitmentItemTypes && this.isQualiaOrder) {
          if(this.commitmentItemTypes.accepted_requirements) {
            this.qualiaReqsItems = this.commitmentItemTypes.accepted_requirements;
          }

          if(this.commitmentItemTypes.accepted_exceptions) {
            this.qualiaExpsItems = this.commitmentItemTypes.accepted_exceptions;
          }

          if (this.qualiaReqsItems.length > 0 && this.commitmentItem) {
            this.qualiaReqsItems = [
              ...this.qualiaReqsItems,
              {
                Index: this.constants.otherItemKey,
                code: this.constants.otherItemKey,
                description: "Other",
                sampleText: "",
                type: "other"
              }
            ];
          }
      
          if (this.qualiaExpsItems.length > 0 && this.commitmentItem) {
            this.qualiaExpsItems = [
              ...this.qualiaExpsItems,
              {
                Index: this.constants.otherItemKey,
                code: this.constants.otherItemKey,
                description: "Other",
                sampleText: "",
                type: "other"
              }
            ];
          }
      }


      });
  }

  ngAfterViewInit() {}

  updateCommitItemLanguage(finalText, fieldSet) {
    const finalAcceptedValue = finalText;
    this.commitmentItem["Language"] = finalAcceptedValue;
    this.commitmentItem["Field_Set"] = fieldSet;
    this.fieldSet = fieldSet;
    this.saveCommitment();
    this.validateForm();
  }

  async fetchCommitmentLogs() {
    const commitmentItemId = this.commitmentItem.Id;
    return await this.builder.getCommitmentLogs(commitmentItemId).toPromise();
  }

  async handleAutoFill() {
    const orderId = this.orderDetails.Order_ID;
    const reportId = this.searchPackage.Id;
    const typeId = this.sectionType;

    const templateText = this.templateText() ?? this.commitmentItem.Statement;
    const sectionId = this.sectionData.Id;
    const sectionData = this.sectionData;
    const commitmentId = this.commitmentItem.Id;

    const response = await this.builder
      .autofillCommitmentFromAI(templateText, {
        orderId,
        reportId,
        typeId,
        sectionId,
        commitmentId,
        sectionData,
      })
      .toPromise();

    return response;
  }

  collectCommitment() {
    this.builder.commitments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((commitments) => {
        if (commitments && commitments.curData) {
          let curItem = commitments.curData.find(
            (ci) =>
              ci.Sp_Id == this.searchPackage.Id &&
              ci.Item_Type_Id == this.sectionType &&
              ci.Record_Id == this.sectionData.Id
          );
          if (curItem !== undefined) {
            this.commitmentItem = curItem;
            if (!this.templateText()) {
              this.templateText.set(
                curItem.placeholder_state?.originalTemplateText ??
                  curItem.Language
              );
            }
            this.dbCommitmentItem = { ...curItem };
            this.getFieldSet();
            this.validateForm();
          } else this.createCommitment();
        }
      });
  }

  isFieldSetArray() {
    return Array.isArray(this.fieldSet);
  }

  createCommitment() {
    if (!this.isCreateSend) {
      this.isCreateSend = true;
      this.commitmentItem = {
        Sp_Id: this.searchPackage.Id,
        Pippin_Order_Id: this.orderDetails.Order_ID,
        Item_Type_Id: this.sectionType,
        Record_Id: this.sectionData.Id,
        Category: "N",
        Field_Set: [],
        Statement: "",
        Language: "",
        SortOrder: this.sectionData.Sort_Order,
        Cust_Commit_Type_Id: null,
        Applies: 1,
      };
      this.builder
        .createCommitment(this.commitmentItem, this.sectionData.Sp_Id)
        .subscribe();
    }
  }

  initComponent() {
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData) {
          this.searchPackage = data.dbData;
          if (this.searchPackage && this.searchPackage.Id !== undefined)
            this.collectCommitment();
        }
      });
  }

  initCommitment(order) {
    this.orderDetails = order;
  }

  getEntities() {
    const response = this.isQualiaOrder
      ? this.getQualiaEntities()
      : this.getPippinEntities();
    this.templateOptions = response;
    return response;
  }

  getQualiaEntities() {
    if (this.commitmentItemTypes) {
      if (
        this.commitmentItem &&
        this.commitmentItem["Category"] == "R" &&
        this.commitmentItemTypes.accepted_requirements
      ){
        return this.qualiaReqsItems;
      }
      else if (
        this.commitmentItem &&
        this.commitmentItem["Category"] == "E" &&
        this.commitmentItemTypes.accepted_exceptions
      ){
        return this.qualiaExpsItems;
      }
      else return [];
    } else return [];
  }

  getPippinEntities() {
    if (this.commitmentItemTypes && this.commitmentItemTypes.length > 0) {
      if (this.commitmentItem && this.commitmentItem["Category"] == "R")
        return this.pippinReqsItems;
      else if (this.commitmentItem && this.commitmentItem["Category"] == "E")
        return this.pippinExpsItems;
      else return [];
    } else return [];
  }

  getSearchKey() {
    return this.isQualiaOrder
      ? ["description", "code"]
      : ["Type_Name", "Cust_Commit_Type_Id"];
  }

  getSelectKey() {
    return this.isQualiaOrder ? "Index" : "Cust_Commit_Type_Id";
  }

  getFilteredOptions() {
    if (this.sectionType > 0) {
      if ([4, 5].includes(this.sectionType))
        return this.categoryOptions.filter(
          (ops) => ops.value == "V" || ops.value == "N"
        );
      else
        return this.categoryOptions.filter(
          (ops) => ops.value == "R" || ops.value == "E" || ops.value == "N"
        );
    }
  }

  getLabel(itemVal) {
    let selectedOpt = this.categoryOptions.filter(
      (cat) => cat.value == itemVal
    );
    return selectedOpt[0].label;
  }

  getFieldSet() {
    if (this.commitmentItem && this.commitmentItem["Field_Set"]) {
      if (typeof this.commitmentItem["Field_Set"] == "string") {
        if (this.commitmentItem["Field_Set"].length == 4)
          this.commitmentItem["Field_Set"] = [];
        else
          this.commitmentItem["Field_Set"] = JSON.parse(
            this.commitmentItem["Field_Set"]
          );
      }
      this.fieldSet = this.commitmentItem["Field_Set"];
      if (typeof this.fieldSet !== "object")
        this.fieldSet = JSON.parse(this.fieldSet);
    }
  }

  saveItem(event) {
    if (!event.pristine) this.saveCommitment();
  }

  saveCommitment() {
    this.commitmentItem.Language = this.commitmentItem.Language.trim();
    this.builder
      .updatecommitment(
        this.commitmentItem,
        this.dbCommitmentItem,
        this.searchPackage.Id
      )
      .pipe(take(1))
      .subscribe();
  }

  saveCommitmentState(placeholderState) {
    const placeholder_state = placeholderState;
    this.builder
      .updateCommitmentPlaceholderState(
        placeholder_state,
        this.dbCommitmentItem,
        this.searchPackage.Id
      )
      .pipe(take(1))
      .subscribe();
  }

  handleTypeChange() {
    this.commitmentItem["Language"] = "";
    this.commitmentItem["Statement"] = "";
    this.commitmentItem["Cust_Commit_Type_Id"] = null;
    this.commitmentItem["Field_Set"] = [];
    this.commitmentItem["placeholder_state"] = {
      textState: {},
      placeholderState: {},
      parsedTemplate: [],
      originalTemplateText: "",
    };
    this.templateText.set("");
    this.fieldSet = [];
    this.isValid.emit(true);
  }

  optionChangeHandler(event) {
    if (event) {
      if (this.isQualiaOrder) this.handleQualiaOptionChange(event);
      else this.handlePippinOptionChange(event);
    }
  }

  handleQualiaOptionChange(event) {
    let optionList =
      this.commitmentItem["Category"] == "R"
        ? this.commitmentItemTypes.accepted_requirements
        : this.commitmentItemTypes.accepted_exceptions;
    let matchedIndex = optionList.findIndex(
      (itemTypes) => itemTypes.Index == event
    );
    if (matchedIndex > -1) {
      this.commitmentItem["Statement"] = optionList[matchedIndex].sampleText;
      this.commitmentItem["Language"] = optionList[matchedIndex].sampleText;
      //TODO: update backend for the original templateText to be updated
      this.templateText.set(this.commitmentItem["Language"]);
      this.saveCommitmentState({
        originalTemplateText: this.commitmentItem["Language"],
        parsedTemplate: null,
        textState: null,
        placeholderState: null,
      });
    }else{
      this.commitmentItem["Statement"] = "";
      this.commitmentItem["Language"] = "";
      this.commitmentItem["Field_Set"] = [];
      this.fieldSet = [];
      this.errors=[]
    }
  }

  handlePippinOptionChange(event) {
    let commitType = 1;
    if (this.commitmentItem["Category"] == "E") commitType = 2;
    let matchedIndex = this.commitmentItemTypes.findIndex(
      (itemTypes) =>
        itemTypes.Cust_Commit_Type_Id == event &&
        itemTypes.Commitment_Type == commitType
    );
    if (matchedIndex > -1) {
      this.commitmentItem["Statement"] =
        this.commitmentItemTypes[matchedIndex].Language;
      this.commitmentItem["Language"] =
        this.commitmentItemTypes[matchedIndex].Language;
      this.getLangFieldSet(this.commitmentItemTypes[matchedIndex].Language);
      //TODO: update backend for the original templateText to be updated
      this.saveCommitmentState({
        originalTemplateText: this.commitmentItem["Language"],
        parsedTemplate: null,
        textState: null,
        placeholderState: null,
      });
      this.templateText.set(this.commitmentItem["Language"]);
    } else {
      this.commitmentItem["Statement"] = "";
      this.commitmentItem["Language"] = "";
      this.commitmentItem["Field_Set"] = [];
      this.fieldSet = [];
    }
  }

  getLangFieldSet(lang) {
    this.commitmentItem["Field_Set"] = [];
    this.commitmentItem["Language"] = lang;
    this.placeHolderPairs.forEach((ph) => {
      let elmStart = false;
      let elm = "";
      let lastChar = "";
      lang.split("").forEach((ch) => {
        if (elmStart) {
          elm += ch;
          lastChar = ch;
          if (ch == ph[1]) {
            elmStart = false;
            let field = this.commitmentItem["Field_Set"].find(
              (fld) => fld.key == elm
            );
            if (elm.length > 2 && field == undefined) {
              lastChar = "";
              this.commitmentItem["Field_Set"].push({ key: elm, val: "" });
            }
          }
        } else if (ch == ph[0] && lastChar !== ph[0]) {
          lastChar = ch;
          elmStart = true;
          elm = ch;
        } else lastChar = ch;
      });
    });
    this.getFieldSet();
  }

  isEmpty(value) {
    for (let prop in value) {
      if (value.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  fieldChangeHandler(field, event) {
    if (field && field != undefined) {
      field.val = event;
      this.setLanguageText();
      this.validateForm();
    }
  }

  validateForm() {
    if (this.isRemoteLanguage !== null) {
      if (typeof this.fieldSet !== "object")
        this.fieldSet = JSON.parse(this.fieldSet);
      if (this.fieldSet.length > 0) {
        if (typeof this.fieldSet !== "object")
          this.fieldSet = JSON.parse(this.fieldSet);
        if (typeof this.fieldSet == "object") {
          let errFieldIndex = this.fieldSet.findIndex((fld) => fld.val === "");
          if (errFieldIndex > -1) {
            setTimeout(() => {
              if (!this.dataForm.controls["Language"].hasError("incorrect")) {
                this.dataForm.controls["Language"].setErrors({
                  incorrect: true,
                });
                this.dataForm.controls["Language"].markAsTouched();
                this.isValid.emit(false);
              }
            }, 0);
          } else {
            this.dataForm.controls["Language"].setErrors(null);
            this.isValid.emit(true);
          }
        }
      }
    }
  }

  isDateValue(inpDate: any) {
    return inpDate instanceof Date && !isNaN(inpDate.valueOf());
  }

  isEntitiesPresent() {
    return this.isQualiaOrder
      ? this.getQualiaEntities().length > 0
      : this.getPippinEntities().length > 0;
  }

  isCommitmentModified(fieldName) {
    if (this.commitmentItem.Derived_From && this.commitmentItem.Status)
      return this.builder.isFieldModified(
        this.searchPackage.Id,
        this.Cmtmnt_Mappings[this.commitmentItem.Item_Type_Id],
        this.commitmentItem.Record_Id,
        this.commitmentItem.Id
      );
    else return false;
  }

  setLanguageText() {
    let newLang = this.commitmentItem["Statement"];
    this.placeHolderPairs.forEach((ph) => {
      let curLang = newLang;
      newLang = "";
      let elmStart = false;
      let elm = "";
      let lastChar = "";

      curLang.split("").forEach((ch) => {
        if (elmStart) {
          elm += ch;
          lastChar = ch;
          if (ch == ph[1]) {
            elmStart = false;
            if (elm.length > 2) {
              lastChar = "";
              let field = this.commitmentItem.Field_Set.find(
                (fld) => fld.key == elm
              );
              if (field.val == "" || !field.val) {
                newLang += `${field.key}`;
              } else {
                let newVal = field.val;
                if (this.isDateValue(newVal)) {
                  newVal = moment(newVal).format("M/D/YYYY");
                }
                newLang += newVal.trim();
              }
            } else {
              newLang += elm;
            }
          }
        } else if (ch == ph[0] && lastChar !== ph[0]) {
          lastChar = ch;
          elmStart = true;
          elm = ch;
        } else {
          lastChar = ch;
          newLang += ch;
        }
      });
    });

    this.commitmentItem["Language"] = newLang;
    this.saveCommitment();
  }
}
