import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CONSTANTS } from '../../app.constants'
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ConfigService, AuthService, TagsService, UsermessageService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin as observableForkJoin, Observable } from 'rxjs';
import { DialogService } from 'ng2-bootstrap-modal';
import { SuccessComponent } from '../../dialog/success/success.component';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from "../../dialog/error-dialog/error-dialog.component";

@Component({
  selector: 'app-create-edit-tag',
  templateUrl: './create-edit-tag.component.html',
  styleUrls: ['./create-edit-tag.component.scss']
})
export class CreateEditTagComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  tagForm: any = {}
  tagModel: any = {}
  tagId: string = '';
  error: any = {}
  errorMsg: any;
  constants = CONSTANTS
  successMsg:any;
  tagColors = [];
  timer: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    private config: ConfigService,
    private route: ActivatedRoute,
    private tagService: TagsService,
    private auth: AuthService,
    private matDialog:MatDialog,
    private router: Router,
    private userMsg:UsermessageService
  ) {
    this.tagForm = fb.group({
      'Tag_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Tag_Description': [null, Validators.maxLength(65535)],
      'Tag_Color': [null],
      'Remove_On_Ord_Complete': [null],
    })
    this.tagForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
  }

  ngOnInit() {
    this.userMsg.setSuccessMsgNull();
    this.tagColors = Object.assign([], this.tagService.getTagPickerColors());
    this.tagColors.map((tag) => tag.selected = false);
    if (this.route.snapshot.params['tagId']) {
      this.tagId = this.route.snapshot.params['tagId'];
      this.tagForm = this.fb.group({
        'Tag_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
        'Tag_Description': [null, Validators.maxLength(65535)],
        'Tag_Color': [null],
        'Tag_Status': [null],
        'Remove_On_Ord_Complete': [null]
      });
      this.getTagInfo()
    }
    else {
      if (!this.tagModel.Tag_Color) this.tagModel.Tag_Color = "#e0e0e0"
      this.tagModel.Remove_On_Ord_Complete = false;
    }
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  tagColorSelect(tag){
    this.tagModel.Tag_Color = tag.value;    
    this.tagColors.map((tag) => tag.selected = false);
    tag.selected = true;
  }

  getTagInfo() {
    this.tagService.getTagInfo(this.tagId)
      .subscribe((data) => {
        if (data) {
          this.loadData(data)
        }
      }, (err) => {
        console.log(err);
      })
  }

  loadData(data) {
    this.tagModel.Tag_Name = data.Tag_Name;
    this.tagModel.Tag_Description = data.Tag_Description;
    this.tagModel.Tag_Color = data.Tag_Color;
    this.presetTagColor(data.Tag_Color);
    if (data.Tag_Status == CONSTANTS.tagStatus.inActive) {
      this.tagModel.Tag_Status = false;
    } else {
      this.tagModel.Tag_Status = true;
    }
    if (data.Remove_On_Ord_Complete) this.tagModel.Remove_On_Ord_Complete = true;
    else this.tagModel.Remove_On_Ord_Complete = false;    
  }

  presetTagColor(colorValue){
    var selectedColorIndex = this.tagColors.findIndex((color) => color.value == colorValue);
    if(selectedColorIndex > -1) this.tagColors[selectedColorIndex].selected = true; 
  }

  confirmTag() {
    this.successMsg='';
    this.errorMsg = '';
    this.tagModel.Admin_User_ID = this.auth.getUserId();
    this.tagService.createTag(this.tagModel)
      .subscribe((res) => {
        let msg = "Tag created successfully."
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/tags/list']);
      }, (err) => {
        this.errorMsg = err;
      });
  }

  updateTag() {
    this.successMsg='';
    this.errorMsg = '';
    this.tagModel.Tag_ID = this.tagId;
    this.tagModel.Admin_User_ID = this.auth.getUserId();
    this.tagService.updateTag(this.tagModel)
      .subscribe((res) => {
        let msg = "Tag updated successfully."
        this.userMsg.setSuccessMsg(msg);
        this.router.navigate(['/tags/list']);
      }, (err) => {
        this.errorMsg = err;
      });

  }

  openModal(title, msg) {
    return this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  userAction(event) {
    if (event.checked == true) {
      this.successMsg='';
      this.errorMsg='';
      this.tagService.chngStatusActiveTag({ Tag_ID: this.tagId, Admin_User_ID: this.auth.getUserId() })
      .subscribe((res) =>{
        this.successMsg = "Tag successfully activated."
      })
    } else {
      this.tagService.chngStatusSuspendTag({ Tag_ID: this.tagId, Admin_User_ID: this.auth.getUserId() })
        .subscribe((res) => {
          this.successMsg = "Tag successfully suspended."
          if(res) this.errorMsg = res; 
        })
    }
  }

  updateTagModel(event) {
    this.tagModel.Remove_On_Ord_Complete = event.checked;
  }
 
  updateRemoveOnOrdComplete(event) {
    this.successMsg='';
    this.errorMsg='';
    if (event.checked == true) {
      this.tagService.updateRemoveOnOrdCompleteStatus({ Remove_On_Ord_Complete: event.checked, Tag_ID: this.tagId, Admin_User_ID: this.auth.getUserId() })
      .subscribe((res) =>{
        this.userMsg.setSuccessMsg("Successfully updated.");
      }, (err) => {
        this.openErrorPopup("Error while updating status. Please try again.");
        this.tagModel.Remove_On_Ord_Complete = !this.tagModel.Remove_On_Ord_Complete;
      })
    } else {
      this.tagService.updateRemoveOnOrdCompleteStatus({ Remove_On_Ord_Complete: event.checked, Tag_ID: this.tagId, Admin_User_ID: this.auth.getUserId() })
        .subscribe((res) => {
          this.userMsg.setSuccessMsg("Successfully updated.");
        }, (err) => {
         this.openErrorPopup("Error while updating status. Please try again.");
          this.tagModel.Remove_On_Ord_Complete = !this.tagModel.Remove_On_Ord_Complete;
        })
    }
  }

    openErrorPopup(msg) {
      let disposable = this.matDialog.open(ErrorDialogComponent, {
        data: {
          message: msg,
        },
        ...this.config.getDialogOptions(),
      });
    }

}
