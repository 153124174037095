
export function convertToNumber(value: any): number | null {
  if (value === null || value === undefined || value === '') return null;

  let numericValue = value.toString().trim().replace(/[^0-9.]/g, "");

  const matches = numericValue.match(/\./g);
  if (matches && matches.length > 1) return null; 

  const parsedValue = numericValue ? parseFloat(numericValue) : null;

  return isNaN(parsedValue) ? null : parsedValue;
}


export function getNextSortOrder(sectionDataArray) {
  if (!sectionDataArray || sectionDataArray.length === 0) return 0;
  const currentMax = Math.max(...sectionDataArray.map(item => item.Sort_Order || 0));
  return currentMax >= 0 ? currentMax + 1 : 0;
}