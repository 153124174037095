// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quoteVertical {
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  margin-left: 0px;
}

.quoteHorizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  font-weight: 500;
}

.owner {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
  font-size: 1rem;
}

.adjustpadding {
  padding-left: 0px;
  margin-left: 0px;
}

.quote-font {
  font-weight: 700;
  color: #232323;
  font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/quote-info/quote-info.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AACE;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;AAEJ;;AACE;EACE,yBAAA;EACA,gBAAA;EACA,eAAA;AAEJ;;AAEE;EACE,iBAAA;EACA,gBAAA;AACJ;;AAEE;EACE,gBAAA;EACA,cAAA;EACA,eAAA;AACJ","sourcesContent":[".quoteVertical{\n    display:flex;\n    flex-direction: column;\n    padding-left: 0px;\n    margin-left: 0px;\n  }\n  \n  .quoteHorizontal{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin-bottom: 20px;\n  }\n  .label{\n    color: #00000099; \n    font-size: 1rem;\n    font-weight: 500;\n  }\n  \n  .owner{\n    color: #00000099; \n    font-weight: 500;\n    font-size: 1rem;\n  }\n  \n  \n  .adjustpadding{\n    padding-left: 0px;\n    margin-left: 0px;\n  }\n  \n  .quote-font {\n    font-weight: 700;\n    color: #232323;\n    font-size: 15px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
