// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rb-section-error {
  border-color: #EE4751;
  border-width: 2px;
  border-style: dashed;
  border-radius: 3px;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pai-button {
  margin-bottom: 5px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/report-builder/rb-property/rb-property.component.scss"],"names":[],"mappings":"AACA;EACE,qBAAA;EACA,iBAAA;EACA,oBAAA;EACA,kBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF;;AAGA;EACE,kBAAA;EACA,kBAAA;AAAF","sourcesContent":["\n.rb-section-error {\n  border-color: #EE4751;\n  border-width: 2px;\n  border-style: dashed;\n  border-radius: 3px;\n}\n\n.header-row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.pai-button{\n  margin-bottom: 5px;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
