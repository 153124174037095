import { Component, OnInit, ViewChild, ElementRef, Inject} from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { DragDropModule } from '@angular/cdk/drag-drop';
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';
import { CONSTANTS } from 'app/app.constants';
import { SchedulingService, ConfigService } from 'app/services';
import { ConfirmComponent } from '../confirm/confirm.component';
import { ReactiveFormsModule, FormsModule, UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-action-comment',
  templateUrl: './action-comment.component.html',
  styleUrls: ['./action-comment.component.scss']
})
export class ActionCommentComponent{
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef
  result:any;
  messageText: string = '';
  timer: any = [];
  title: any = '';
  message:any={};
  order_ETA_Time :string;
  dueDateRangeOption: Number;
  dueDateFrmObj: any;
  dueDateTimeObj: any;
  isDueRangeErr: boolean = false;
  dueDate_Range: any;
  constants = CONSTANTS;
  copyDetails: any;
  listOfHolidays: any;
  weekendHolidayName: String;
  newOrderForm: UntypedFormGroup;
  etaChangeReasonTypeId: any = null;
  etaChangeComment:any = null;
  etaChangeEmail:any = null;
  etaChangeNotify:any = null;
  etaChangeClientStatus:any = null;
  constructor(
    private matDialog:MatDialog,
  private frmBuilder: UntypedFormBuilder,
  private holidayScheduling: SchedulingService,  private config:ConfigService,
  public dialogRef: MatDialogRef<ActionCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title=this.data.title;
      this.message=this.data.message;
      this.listOfHolidays=this.data.listOfHolidays;
      
    this.newOrderForm = frmBuilder.group({
      Order_ETA: [{ disabled: false, value: '' }],
    })
  }  

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => { this.myFirstFocus.nativeElement.focus() }, 0));
    this.getAvlOrderETAnTime();
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  Close() {
    this.dialogRef.close(false);
    this.result = false;
    this.clearTime();
  }

  confirm(orderETA) {
    if(orderETA){
        if(this.checkIfETAChanged()) this.checkForSelectedETA(orderETA)
        else this.unPausedProcess(orderETA)
    } else this.unPausedProcess(orderETA)
  }
  
  unPausedProcess(orderETA){
    let dueDate_ETA;
    let finalDueDate
    if(this.dueDate_Range && this.message['Order_ETA_Range']) { 
      this.order_ETA_Time = "17:00";
      finalDueDate = this.setOrderETATime(this.dueDate_Range);
    } else {
      if(this.message['Order_ETA_Range']) this.message['Order_ETA_Range'] = null;
      finalDueDate = this.setOrderETATime(orderETA);
    }
    if(finalDueDate) {
    var estShift = momentTimeZone.tz(finalDueDate, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(finalDueDate).utcOffset(moment(finalDueDate).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
     dueDate_ETA = etaInEstTimezone;
    } else dueDate_ETA = null;

    this.result = {
      state : true,
      comment :this.messageText,
      Order_ETA: dueDate_ETA,
      Order_ETA_Range: this.message['Order_ETA_Range'],
      Order_ETA_Change_Reason_Type_ID : this.etaChangeReasonTypeId,
      Order_ETA_Comment: this.etaChangeComment,
      Email_Body: this.etaChangeEmail,
      Notify_Customer: this.etaChangeNotify,
      Client_Notif_Status: this.etaChangeClientStatus
    }

    this.dialogRef.close(this.result)
    this.clearTime();
  }  

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  setOrderETATime(orderETA) {
    if(orderETA && orderETA._d) {
    let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
    let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
    return orderETA._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    } else if(orderETA && !orderETA._d) {
    let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
    let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
    return orderETA.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    } else return null;
  }

  getAvlOrderETAnTime() {
    if(this.message['Order_ETA']) {
      let etaFullDate = new Date(momentTimeZone.tz(this.message['Order_ETA'], 'America/New_York').format('MM/DD/YYYY HH:mm'));
      let date = new Date(etaFullDate.toDateString());
      this.message['Order_ETA'] = date;
      let etaHours = etaFullDate.getHours().toString();
      let etaMinutes= etaFullDate.getMinutes().toString();
      if(etaHours.length == 1) etaHours = "0"+etaHours;
      if(etaMinutes.length == 1) etaMinutes = "0"+etaMinutes;
      this.order_ETA_Time = etaHours+ ":"+etaMinutes;
      this.copyDetails = Object.assign({}, this.message); 
    } else {
      this.message['Order_ETA'] = null;
      this.order_ETA_Time = "17:00" ;
    }
  }

  getDueDateData(event) {
    this.dueDateRangeOption = Number(event.dueDateType);
    if (Number(event.dueDateType)) {
      if (event.dueDateFrmCtrl) {
        this.isDueRangeErr = true;
        this.dueDateFrmObj = event.dueDateFrmCtrl;
        if(this.dueDateFrmObj) {
          if (this.dueDateFrmObj.value.fromDayCtrl && this.dueDateFrmObj.value.toDayCtrl) {
            let getDueDateRange = this.dueDateFrmObj.value.fromDayCtrl + "-" + this.dueDateFrmObj.value.toDayCtrl;
            let addDays: any = momentTimeZone.tz('America/New_York').add(Number(this.dueDateFrmObj.value.toDayCtrl), 'days').format()
            let getDateObj =  this.Convert_UTCDate_To_LocalDate(addDays, 'America/New_York');
            this.dueDate_Range = getDateObj; 
            this.message['Order_ETA_Range'] = getDueDateRange;
          }else {
            if(this.dueDateFrmObj.controls['fromDayCtrl'].touched && !(this.dueDateFrmObj.value.fromDayCtrl)) this.dueDateFrmObj.controls['fromDayCtrl'].setErrors({'fromDayRequired':true});
            if(this.dueDateFrmObj.controls['toDayCtrl'].touched && !(this.dueDateFrmObj.value.toDayCtrl)) this.dueDateFrmObj.controls['toDayCtrl'].setErrors({'toDayRequired':true});
          }
        }
      }
    } else {
      this.isDueRangeErr = false;
      this.dueDate_Range =null;
      this.message['Order_ETA_Range'] = null;
      if (event.dueDateTimeCtrl) {
        this.dueDateTimeObj = event.dueDateTimeCtrl;
        if (event.dueDateTimeCtrl.valid) this.checkForDueDateEST(event);
      }
      else this.checkForDueDateEST(event);
    }

    if(event.hasOwnProperty('reason')){
      this.etaChangeReasonTypeId = event.reason;
    }
    if(event.hasOwnProperty('comment')){
      this.etaChangeComment = event.comment;
    }
    if(event.hasOwnProperty('emailContent')){
      this.etaChangeEmail= event.emailContent;
    }
    if(event.hasOwnProperty('notifyCustomer')){
      this.etaChangeNotify = event.notifyCustomer;
    }
    if(event.hasOwnProperty('clientNotifStatus')){
      this.etaChangeClientStatus = event.clientNotifStatus;
    }
  }

  checkForDueDateEST(event) {
    if (event.Due_Date_EST || event.Due_TIme_EST) {
      this.message['Order_ETA'] = event.Due_Date_EST;
      this.order_ETA_Time = event.Due_TIme_EST;
    }
  }

  Convert_UTCDate_To_LocalDate(utcTime: Date, localTimeZone: string) {
    var utcMoment = momentTimeZone(utcTime);
    var localDateTime = utcMoment.tz(localTimeZone);
    return new Date(localDateTime.format('lll'));
  }

  checkForDueDateValid() {
    if(this.message['Order_Status'] >= CONSTANTS.orderStatus.confirmed) {
      if (!this.message['Order_ETA'] && !this.message['Order_ETA_Range']) return true;
      else {
        if (this.dueDateRangeOption) {
          if(this.dueDateFrmObj && this.dueDateFrmObj.valid) return false;
          else return true;
        } else {
          if (this.dueDateTimeObj) {
            if (this.dueDateTimeObj.valid) return false;
            else if (!this.dueDateTimeObj.valid) return true
          } else return false;
        }
      }
   } else return false;
  }  

  checkIfETAChanged() {
    if(this.copyDetails && this.copyDetails.Order_ETA){
      let newETADate = moment(this.message.Order_ETA).format("YYYY-MM-DDTHH:mm");
      if(moment(this.copyDetails.Order_ETA, "YYYY-MM-DDTHH:mm").isSame(newETADate)) return false;
      else return true;
    }else return true;

  }

  checkForSelectedETA(orderETA){
    // let orderETADate = moment(orderETA).format("YYYY-MM-DD");
    // let checkETAIsHoliday = this.listOfHolidays.findIndex((holiday) => {
    //   if(holiday && moment(holiday.Holiday_Date, "YYYY-MM-DD").isSame(orderETADate)) return true;
    //   else return false
    // })
    // if(checkETAIsHoliday > -1 ) this.showWarningPopup(this.listOfHolidays[checkETAIsHoliday].Holiday_Name,orderETA)
    // else if(this.checkETALieOnWeekend(orderETA)) this.showWarningPopup(this.weekendHolidayName, orderETA)
    // else this.unPausedProcess(orderETA)
    this.unPausedProcess(orderETA)
  }

  showWarningPopup(holidayName, orderETA){
    let disposable = this.matDialog.open(ConfirmComponent, {data:{
      title:'ETA Note',
      message:"The ETA on this order is a US holiday ("+holidayName+"). Would you like to continue?"
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => { 
      if(res) this.unPausedProcess(orderETA)
    });
  }

  checkETALieOnWeekend(orderETA) {
    if(moment(orderETA).isoWeekday() == 7){ // iso Weekday for sunday is 7
      this.weekendHolidayName = "Sunday";
      return true;
    }
    else if(moment(orderETA).isoWeekday() == 6){ // iso Weekday for saturday is 6
      this.weekendHolidayName = "Saturday";
      return true;
    }else return false;
  }

  checkForETAValidation(){
    if(this.newOrderForm){
      if(!this.newOrderForm.valid && !this.newOrderForm.touched) return true;
      else false;
    }else false
  }
  
}
