// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-button {
  width: 33px;
  height: 27px;
  border: none;
  margin-bottom: 5px;
  border-radius: 5px;
  color: white;
  padding: 1px;
}
.ai-button span {
  color: white !important;
}

:host ::ng-deep .mat-form-field-flex {
  align-items: flex-start !important;
}

.ai-button {
  margin-top: 8px;
}

.ai-button:active, .ai-button:focus {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.ai-indicator-autofilled {
  background-color: #4FB2D9;
}

.ai-indicator-default {
  background-color: #B2BEB5;
}

.ai-indicator-edited {
  background-color: #F16161;
}

.ai-indicator-unsure {
  background-color: #EBC03C;
}

.ai-indicator-accepted {
  background-color: #60cc70;
}

.text-bold {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ai-components/ai-input-field/ai-input-field.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AAAF;AACE;EACE,uBAAA;AACJ;;AAGA;EACE,kCAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACI,gFAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACG,yBAAA;AAAH;;AAGA;EACG,yBAAA;AAAH;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF","sourcesContent":["\n.ai-button{\n  width: 33px;\n  height: 27px;\n  border: none;\n  margin-bottom: 5px;\n  border-radius: 5px;\n  color: white;\n  padding: 1px;\n  span{\n    color: white !important;\n  }\n}\n\n:host ::ng-deep .mat-form-field-flex {\n  align-items: flex-start !important;\n}\n\n.ai-button {\n  margin-top: 8px;\n}\n\n.ai-button:active, .ai-button:focus{\n    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);\n}\n\n.ai-indicator-autofilled{\n    background-color: #4FB2D9;\n}\n\n.ai-indicator-default{\n   background-color: #B2BEB5;\n}\n\n.ai-indicator-edited{\n   background-color: #F16161;\n}\n\n.ai-indicator-unsure{\n  background-color: #EBC03C;\n}\n\n.ai-indicator-accepted{\n  background-color: #60cc70;\n}\n\n.text-bold{\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
