import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import * as momentTimeZone from "moment-timezone";
import { RptBuilderService } from "../rpt-builder.service";
import {
  debounceTime,
  distinctUntilChanged,
  take,
  takeUntil,
} from "rxjs/operators";
import { Subject } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { FileService, ConfigService, PreloaderService } from '../../services';
import { CONSTANTS } from '../../app.constants';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { MatDialog } from "@angular/material/dialog";

const remove = (arr, func) =>
  Array.isArray(arr)
    ? arr.filter(func).reduce((acc: any, val) => {
        arr.splice(arr.indexOf(val), 1);
        if (typeof acc == "number") acc = [];
        return acc.push(val);
      }, [])
    : [];

declare var $: any;
@Component({
  selector: "app-report-versions",
  templateUrl: "./report-versions.component.html",
  styleUrls: ["./report-versions.component.scss"],
})
export class RbVersionsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionObject: any = {};
  orderDetails: any = {};
  builderData: any = {};
  //   PPN_RP: "Detail Report",
  //   PRT_DT: "Property",
  //   ASS_NS: "Assessments",
  //   TAX_ES: "Taxes",
  //   VES_DD: "Vesting Deed",
  //   CHN_TL: "Chain Of Title",
  //   SCR_IN: "Security Instruments",
  //   LEN_JG: "Liens & Judgements",
  //   ERA: "Encumbrances and Restrictions",
  //   SRC_PT: "Search Parties",
  //   ADD_DC: "Additional Information",
  //   RPT_DT: "Report Data",
  //   ADD_Commit: "Additional Commitments",
  //   GEN_COM: "General Comments",
  //   Alias: "Search Parties - Variations",
  //   COM: "Commitment",
  //   ADD_DATA: "Additional Data",
  //   DOC: "Documents",
  // };
  // commentTypeMapping = {
  //   1: "Search Parties",
  //   2: "Assessments",
  //   3: "Taxes",
  //   4: "Vesting Deed",
  //   5: "Chain Of Title",
  //   6: "Security Instruments",
  //   7: "Liens & Judgements",
  //   8: "Encumbrances and Restrictions",
  //   9: "Property",
  //   10: "Pippin Report",
  // };
  // commentKeyMapping = {
  //   1: "SRC_PT",
  //   2: "ASS_NS",
  //   3: "TAX_ES",
  //   4: "VES_DD",
  //   5: "CHN_TL",
  //   6: "SCR_IN",
  //   7: "LEN_JG",
  //   8: "ERA",
  //   9: "PRT_DT",
  //   10: "PPN_RP",
  // };

  versionData: any = [];
  selectedRowIdx: any = null;
  dataTypes: any = null;
  versionIdx: any = -1;

  onNetwork = false;

  @ViewChildren("accordian") components: QueryList<ElementRef>;
  constructor(
    private builder: RptBuilderService,
    private frmBuilder: UntypedFormBuilder,
    private fileService: FileService,
    private config: ConfigService,
    private matDialog:MatDialog,
    private preLoader: PreloaderService,
    private cdr: ChangeDetectorRef

  ) {
    this.dataForm = this.frmBuilder.group({
      Publish_Changes: [false],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveToDb(this.dataForm));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.preLoader.onNetwork$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state) => {
        this.onNetwork = state;
        this.cdr.detectChanges();
      });
    this.builder.orderDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => (this.orderDetails = data));
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data && data.dbData) {
          this.sectionObject = data;
          this.builderData = data.dbData;
          this.dataForm.controls["Publish_Changes"].patchValue(
            this.builderData.Publish_Changes == 1,
            { emitEvent: false }
          );
          this.curateVersionData();
        }
      });
    this.builder.versionDetails$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        if (data) {
          this.versionData = data.map((x) => Object.assign({}, x));
          this.curateVersionData();
        }
      });
  }

  saveToDb(event) {
    if (!event.pristine) {
      this.sectionObject.curData = this.builderData;
      this.sectionObject.Sp_Id = this.builderData.Id;
      this.sectionObject.Derived_From = this.builderData.Derived_From;
      this.builder.saveBasicData(this.sectionObject).pipe(take(1)).subscribe();
    }
  }

  curateVersionData() {
    if (this.builderData.Id !== undefined && this.builderData.Derived_From) {
      this.versionData.forEach((version, idx) => {
        if (version.Sp_Id == this.builderData.Id) {
          this.versionIdx = idx;
        }
      });
    } else if (this.builderData.Id !== undefined) {
      this.versionIdx = this.versionData.findIndex(
        (version) => version.Sp_Id == this.builderData.Id
      );
    }
  }

  getElmId(idx) {
    return "vIdx" + idx;
  }

  loadVersion(event, idx) {
    if (idx !== this.versionIdx && this.versionData[idx] !== undefined) {
      this.builder
        .changeBuilderVersion(
          this.orderDetails.Order_ID,
          this.versionData[idx].Sp_Id
        )
        .subscribe();
    }
  }

  getImageForAction(action: string): string {
    switch (action) {
      case "A":
        return "../../../assets/icons/add.svg";
      case "C":
        return "../../../assets/icons/edit.svg";
      case "D":
        return "../../../assets/icons/minus.svg";
      default:
        return ""; // Return a default image path or handle other cases if needed.
    }
  }

  getActionClass(action: string): string {
    switch (action) {
      case "A":
        return "add-action";
      case "C":
        return "edit-action";
      case "D":
        return "delete-action";
      default:
        return "";
    }
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, "America/New_York").isDST()) return "EDT";
    else return "EST";
  }

  addVersion() {
    this.builder.createVersion(this.builderData.Id).subscribe();
  }

  getChangeDetails(item) {
    if (!item.Derived_From) return "Initial Report";
    let changeDetails = "";
    if (item.Additions > 0)
      changeDetails = `${item.Additions} Addition${
        item.Additions == 1 ? "" : "s"
      }`;
    if (item.Deletions > 0)
      changeDetails = `${changeDetails}${changeDetails.length ? ", " : ""}${
        item.Deletions
      } Deletion${item.Deletions == 1 ? "" : "s"}`;
    if (item.Modifications > 0)
      changeDetails = `${changeDetails}${changeDetails.length ? ", " : ""}${
        item.Modifications
      } Modification${item.Modifications == 1 ? "" : "s"}`;
    return changeDetails.length ? changeDetails : "No Changes";
  }

  refresh() {
    this.builder.getAllVersions(this.orderDetails.Order_ID).subscribe();
  }

  updatePublishChanges(data) {
    this.builderData.Publish_Changes = data ? 1 : 0;
  }

  downloadFile(filename, orderDocId, event) {
    event.stopEventPropagation();
    this.fileService.download(orderDocId)
      .subscribe(
        (userFile) => {
          if (userFile) {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.download = filename;
            link.href = userFile.Link;
            link.click();
            document.body.removeChild(link);
          }
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
        })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg,
      title: 'File download'
    }, ...this.config.getDialogOptions()});
  }
}
