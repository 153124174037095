import { Injectable } from '@angular/core';

@Injectable()
export class ScrollService {

  private scrollPosNewOrder:any=null;
  private scrollPosInprocessOrder:any=null;
  private scrollPosCancelOrder:any=null;
  private scrollPosCompletedOrder:any=null;
  private scrollPosUser:any=null;
  private scrollPosCustomPricng:any=null;
  private scrollPosProductPricng:any=null;
  private scrollPosOrganization:any=null;
  private scrollPosEULA:any=null;
  private scrollPosMessages:any=null;
  private scrollPosClients:any=null;
  private scrollPosAbstractor:any=null;
  private scrollPosAllOrder:any=null;
  private scrollPosOrderSearch:any=null;
  private scrollPosPausedOrder:any=null;
  private scrollPosNewCliOrd:any=null;
  private tags:any=null;
  private searchMaster:any = null;
  private scollPosProducts:any = null;
  private scrollPosQuoteReq:any=null;
  private scrollPosBillingOrder:any=null;
  private scrollPosEmailTemplate:any=null;
  private scrollPosPriorityOrder:any=null;
  private scrollPosOperStat:any=null;
  private scrollPosPromptTemplate:any = null;
  constructor() { }
  
  resetAll(){
    this.scrollPosNewOrder=null
    this.scrollPosInprocessOrder=null;
    this.scrollPosCancelOrder=null;
    this.scrollPosCompletedOrder=null;
    this.scrollPosUser=null;
    this.scrollPosCustomPricng=null;
    this.scrollPosProductPricng=null;
    this.scrollPosOrganization=null;
    this.scrollPosEULA=null;
    this.scrollPosMessages=null;
    this.scrollPosClients=null;
    this.scrollPosAbstractor=null;
    this.scrollPosAllOrder=null;
    this.scrollPosOrderSearch=null;
    this.scrollPosPausedOrder=null;
    this.scrollPosNewCliOrd=null;
    this.tags=null;
    this.searchMaster=null;
    this.scollPosProducts=null;
    this.scrollPosQuoteReq = null;
    this.scrollPosBillingOrder=null;
    this.scrollPosEmailTemplate=null;
    this.scrollPosPriorityOrder=null;
    this.scrollPosOperStat=null;
    this.scrollPosPromptTemplate=null;
  }

  setScrollPosNewOrd(scrollPos){
    this.scrollPosNewOrder=scrollPos;
  }

  getScrollPosNewOrd(){
    if(this.scrollPosNewOrder)
    return this.scrollPosNewOrder;
  }



  setScrollPosQuotereq(scrollQuote){
    this.scrollPosQuoteReq=scrollQuote;
  }

  getScrollPosQuotereq(){
    if(this.scrollPosQuoteReq)
    return this.scrollPosQuoteReq;
  }


  setScrollPosInprocOrd(scrollPos){
    this.scrollPosInprocessOrder=scrollPos;
  }

  getScrollPosInprocOrd(){
    if(this.scrollPosInprocessOrder)
    return this.scrollPosInprocessOrder;
  }

  setScrollPosPausedOrd(scrollPos){
    this.scrollPosPausedOrder=scrollPos;
  }

  getScrollPosPausedOrd(){
    if(this.scrollPosPausedOrder)
    return this.scrollPosPausedOrder;
  }

  setScrollPosNewCliOrd(scrollPos){
    this.scrollPosNewCliOrd=scrollPos;
  }

  getScrollPosNewCliOrd(){
    if(this.scrollPosNewCliOrd)
    return this.scrollPosNewCliOrd;
  }

  setScrollPosCompltdOrd(scrollPos){
    this.scrollPosCompletedOrder=scrollPos;
  }

  getScrollPosCompltdOrd(){
    if(this.scrollPosCompletedOrder)
    return this.scrollPosCompletedOrder;
  }

  setScrollPosCanclOrd(scrollPos){
    this.scrollPosCancelOrder=scrollPos;
  }

  getScrollPosCanclOrd(){
    if(this.scrollPosCancelOrder)
    return this.scrollPosCancelOrder;
  }

  setScrollPosUser(scrollPos){
    this.scrollPosUser=scrollPos;
  }

  getScrollPosUser(){
    if(this.scrollPosUser)
    return this.scrollPosUser;
  }

  setScrollPosCustmPricng(scrollPos){
    this.scrollPosCustomPricng=scrollPos;
  }

  getScrollPosCustmPricng(){
    if(this.scrollPosCustomPricng)
    return this.scrollPosCustomPricng;
  }

  setScrollPosProdPricng(scrollPos){
    this.scrollPosProductPricng=scrollPos;
  }

  getScrollPosProdPricng(){
    if(this.scrollPosProductPricng)
    return this.scrollPosProductPricng;
  }

  setScrollPosOrganization(scrollPos){
    this.scrollPosOrganization=scrollPos;
  }

  getScrollPosOrganization(){
    if(this.scrollPosOrganization)
    return this.scrollPosOrganization;
  }

  setScrollPosClients(scrollPos){
    this.scrollPosClients=scrollPos;
  }

  getScrollPosClients(){
    if(this.scrollPosClients)
    return this.scrollPosClients;
  }

  setScrollPosEULA(scrollPos){
    this.scrollPosEULA=scrollPos;
  }

  getScrollPosEULA(){
    if(this.scrollPosEULA)
    return this.scrollPosEULA;
  }

  setScrollPosMsg(scrollPos){
    this.scrollPosMessages=scrollPos;
  }

  getScrollPosMsg(){
    if(this.scrollPosMessages)
    return this.scrollPosMessages;
  }

  setScrollPosAbstractor(scrollPos){
    this.scrollPosAbstractor = scrollPos;
  }

  getScrollPosAbstractor(){
    if(this.scrollPosAbstractor)
    return this.scrollPosAbstractor;
  }

  getScrollPosAllOrder(){
    if(this.scrollPosAllOrder)
    return this.scrollPosAllOrder;
  }

  setScrollPosAllOrder(scrollPos){
    this.scrollPosAllOrder=scrollPos;
  }
  setScrollPosOrderSearch(scrollPos){
    this.scrollPosOrderSearch=scrollPos;
  }
  getScrollPosOrderSearch(){
    if(this.scrollPosOrderSearch)
    return this.scrollPosOrderSearch;
  }

  setScrollPosTags(tags){
    this.tags =tags;
  }

  getScrollPosTags(){
    if(this.tags) return this.tags;
  }

  setScrollPosSM(searchMaster){
    this.searchMaster = searchMaster
  }

  getScrollPosSM(){
    if(this.searchMaster) return this.searchMaster;
  }

  setScrollPosProduct(products){
    this.scollPosProducts = products
  }

  getScrollPosProduct(){
    if(this.scollPosProducts) return this.scollPosProducts;
  }

  setScrollPosBillingOrder(scrollPosBillingOrder){
    this.scrollPosBillingOrder = scrollPosBillingOrder
  }

  getScrollPosBillingOrder(){
    return this.scrollPosBillingOrder;
  }

  setScrollPosPromptTemplates(scrollPosPromptTemplate){
    this.scrollPosPromptTemplate = scrollPosPromptTemplate;
  }

  getScrollPosPromptTemplates(){
    if(this.scrollPosPromptTemplate) return this.scrollPosPromptTemplate;
  }

  setScrollPosEmailTemplates(scrollPosEmailTemplate){
    this.scrollPosEmailTemplate = scrollPosEmailTemplate;
  }

  getScrollPosEmailTemplates(){
    if(this.scrollPosEmailTemplate) return this.scrollPosEmailTemplate;
  }

  setScrollPosPriorityOrder(data){
    this.scrollPosPriorityOrder = data
  }

  getScrollPosPriorityOrder(){
    return this.scrollPosPriorityOrder;
  }

  setScrollPosOperStat(scrollPos){
    this.scrollPosOperStat=scrollPos;
  }

  getScrollPosOperStat(){
    if(this.scrollPosUser)
    return this.scrollPosOperStat;
  }
}
