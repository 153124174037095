declare var google: any;
import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ConfigService, AbstractorService, PagerService, UsermessageService, QuickbooksService, AuthService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PhonenumberPipe, SsnFieldPipe, EinFieldPipe } from '../../pipes';
import { NgModel } from '@angular/forms';
// import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';

import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { forkJoin as observableForkJoin } from 'rxjs';
import { CONSTANTS } from '../../app.constants';
import { UUID } from 'angular2-uuid';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component'
import { ButtonRendererComponent } from '../../button-renderer/button-renderer.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-abstractor-edit',
  templateUrl: './abstractor-edit.component.html',
  providers: [PhonenumberPipe, SsnFieldPipe, EinFieldPipe],
  styleUrls: ['./abstractor-edit.component.scss']
})
export class AbstractorEditComponent implements OnInit {

  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  editFrm: UntypedFormGroup;
  selectPhoneType : any;
  error: string = '';
  errorMsg: any = {};
  errorType: string = '';
  editModel: any = {};
  domain: string = '';
  latitute: any;
  longitude: any;
  states = [];
  counties = [];
  products: any = [];
  stateId = 0;
  priceList = [];
  listLength = 0;
  editCopy = {};
  currentIndex: number = -1;
  absRating: any;
  itemInput = "";
  avilableProds = [];
  selectedState = '';
  selectedCounty = '';
  selectedProduct = 0;
  filterBy: any = [];
  tableListing: boolean = false;
  filterArray: boolean = true;
  pageNum: number;
  pager: any = {};
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  scrollEnable: boolean = true;
  User_Abstractor_Attribute_EnO: boolean = true;
  Attribute_EnO: boolean = true;
  domLayout = "autoHeight"
  defaultColDef = { editable: true, sortable: true };
  modules = [ClientSideRowModelModule, InfiniteRowModelModule];
  columnDefs: any;
  rowData: any;
  gridApi;
  gridColumnApi;
  productCellArray = [];
  editType = "fullRow";
  updateCellBool: Boolean;
  newProdObj = {};
  productMappings = {};
  selectedIndex : number;
  timer: any = [];
  secondaryEmail: any = [];
  secondaryPhone: any = [];
  secEmailBackup: any = [];
  secEmailLastIndexValue = 0;
  removeSEmail : any = [];
  secPhoneBackup: any = [];
  secPhoneLastIndexValue = 0;
  removeSPhone: any = [];
  frameworkComponents: any;
  getUSDateString = new Date('01/02/1500').toLocaleString("en-US", { timeZone: "America/New_York" });
  min_Date = new Date(this.getUSDateString);
  isCellButtonclicked : boolean = false;
  constructor(
    private config: ConfigService,
    private fb: UntypedFormBuilder,
    private abstractorService: AbstractorService,
    private matDialog:MatDialog,
    private userMsg: UsermessageService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private quickbooks: QuickbooksService,
    public auth: AuthService,
    private pagerService: PagerService) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
    this.editFrm = fb.group({
      'User_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'Contact_Num': [null],
      'Contact_Type': [null],
      'User_Fax_Contact_Number': [null],
      'User_SSN': [null, Validators.maxLength(11)],
      'User_EIN': [null, Validators.maxLength(10)],
      'Address_1': [null, Validators.maxLength(127)],// address line 1
      'Address_ZipCode': [null, Validators.maxLength(11)],
      'Address_State_Abbr': [null, Validators.maxLength(2)],
      'User_Abstractor_Attribute_EnO': [null, Validators.maxLength(5)],
      'User_Abstractor_Attribute_EnO_Expiry':[{disabled: false, value: ''}],
      'Offers_Commercial': [null],
      'Address_City': [null, Validators.maxLength(38)],
      'notes': [null],
      'userActive': [false],
      'secondaryEmailsArr': this.fb.array([]), 
      'secondaryPhoneArr': this.fb.array([]),
      'selectPhoneType':[null],
      'selectSecPhoneTypeArr':this.fb.array([])
    })
    this.editFrm.valueChanges.subscribe((data) => {
      this.error = '';
    });
    this.pager.pages = [1];
    if (this.pageNum == undefined)
      this.pageNum = 1;
  }

  get secondaryEmailsArr() {
    return this.editFrm.controls["secondaryEmailsArr"] as UntypedFormArray;
  }

  get secondaryPhoneArr() {
    return this.editFrm.controls["secondaryPhoneArr"] as UntypedFormArray;
  }

  createColumnDefs() {
    return [
        {
          headerName: 'State Name',
          field: 'State_Short_Name',
          valueGetter: function(params) {
            return params.data.State_Short_Name.toUpperCase();
        },
          valueSetter: function(params) {
            params.data.State_Short_Name = params.newValue.toUpperCase();
            return true;
        }
        },
        {
          headerName: 'County Name',
          field: 'County_Name',
          valueGetter: function(params) {
            return params.data.County_Name.toUpperCase();
        },
          valueSetter: function(params) {
            params.data.County_Name = params.newValue.toUpperCase();
            return true;
        }
        },
        {
          headerName: 'Product Name',
          field: 'Product_Description',
          cellEditor: "agSelectCellEditor",
            cellEditorParams: {
              values: extractValues(this.productMappings),
            },
          valueParser: function (params) {
            // convert value to code
            return lookupKey(this.productMappings, params.newValue);
          },
        },
        {
          headerName: 'Price',
          field: 'Abstrator_Cost',
          valueFormatter: this.numberFormatter,
          suppressKeyboardEvent: this.getOnlyNumber,
          precision:2,
        },
        {
          headerName: 'Action',
          editable:false,
          cellRenderer: ButtonRendererComponent,
          cellRendererParams: {
            clicked: this.onBtnClick.bind(this),
          }
        },
      ]
  }

  onBtnClick(absData) {    
    this.openConfirm('Delete Pricing', 'Would you like to delete this pricing for abstractor ' +absData.Abstrator_Full_Name+'?').afterClosed()
    .subscribe((res) => {
      if (res == true) {
      this.abstractorService.deletePriceFromList(absData)
        .subscribe((result) => {
          this.getPricingListingWithFilter();
          let msg = "Pricing deleted successfully"
          this.userMsg.setSuccessMsg(msg);
        }, (err) => {
          this.openErrorPopup('Error while deleting the abstractor pricing')
        })
      }
    });
  }  

  ngOnInit() {
    this.editModel.Contact_Num = '';
    this.editModel.User_Fax_Contact_Number = '';
    this.checkQuickBookSession();
    this.getScreenDetails();
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
  numberFormatter(params) {
    if(params.value) return "\x24" +  Number.parseFloat(params.value).toFixed(params.colDef.precision);
    else return "\x24" + Number(0).toFixed(params.colDef.precision);
  }

  getOnlyNumber(params){
    let e = params.event;
    let numberValue = e.target.value;
    if ([8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
    (e.keyCode == 65 && e.ctrlKey === true) ||
    (e.keyCode == 67 && e.ctrlKey === true) ||
    (e.keyCode == 88 && e.ctrlKey === true) ||
    (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)))  {
      if(e.keyCode == 46 && numberValue.indexOf('.') < 0) return 
      else if((e.keyCode < 96 || e.keyCode > 105)) e.preventDefault();
    }
  }

  getScreenDetails() {
    // this.getAvailableStateLocal();
    this.abstractorService.getAvailableStateList().subscribe((states) => { this.states = states });
    this.abstractorService.getProductList().subscribe((products) => {
        this.products = products;
        this.products.forEach((product) => {
          this.productMappings[product.Product_Description] = product.Product_ID;
        })
        this.columnDefs = this.createColumnDefs();
        this.avilableProds = Object.assign([], this.products);
      });
    this.abstractorService.getAbstractorDetails(this.route.snapshot.params['id'])
      .subscribe((res) => {
        // console.log(res,"abs details=======")
        this.editModel = res;
        if (res && res.User_Status == CONSTANTS.userStatus.active) {
          this.editModel.userState = true;
        } else {
          this.editModel.userState = false;
        }
        if(!this.editModel.Contact_Type) this.editModel.Contact_Type ="M";
        if (this.editModel.User_Abstractor_Attribute_EnO == "No") this.Attribute_EnO = true
        else return false;
      }, (err) => {
        this.errorMsg = err;
      });
    this.abstractorService.getAbstractorRatingById(this.route.snapshot.params['id'])
      .subscribe((res) => {
        this.absRating = res;
      }, (err) => {
        this.errorMsg = err;
      });
    this.abstractorService.getAbstractorSecondaryEmails(this.route.snapshot.params['id'])
    .subscribe((res)=>{
      if(!res) res = [];
      this.editFrm.setControl('secondaryEmailsArr', this.getSecondaryEmails(res))
      this.secEmailBackup = Object.assign({}, this.getSecondaryEmails(res)); 
      this.secEmailBackup.value.forEach((email) => {
        this.secEmailLastIndexValue = email.Email_index
      });
      
    },(err) =>{
      this.errorMsg = err;
    })
    this.abstractorService.getAbstractorSecondaryPhones(this.route.snapshot.params['id'])
    .subscribe((res)=>{
      if(!res) res = [];
      this.editFrm.setControl('secondaryPhoneArr', this.getSecondaryPhones(res))
      this.secPhoneBackup = Object.assign({},this.getSecondaryPhones(res))
      this.secPhoneBackup.value.forEach((phone) => {
        this.secPhoneLastIndexValue = phone.Phone_Index
      });
    })
  }

//   getAvailableStateLocal(){
//     let Allstates = JSON.parse(sessionStorage.getItem('Absstates'));
//     if(Allstates) this.setState(Allstates)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.abstractorService.getAvailableStateList().subscribe((states) => { 
//       if(states){
//         this.setState(states)         
//         sessionStorage.setItem('Absstates',JSON.stringify(states)); 
//        setTimeout(function(){sessionStorage.removeItem("Absstates");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });
//   }

  
//   setState(AllstatesData){
//     this.states = AllstatesData
// }

  getPricingListing() {
    if (this.pageNum == 1) {
      this.abstractorService.getPriceList(this.route.snapshot.params['id'], this.pageNum, this.filterBy)
        .subscribe((priceList) => {
          this.config.resetShowFlag()
          this.config.setClearTimeout();
          this.priceList =  priceList.rows;
          this.rowData = priceList.rows;
          this.listLength = priceList.count;
          this.pager = this.pagerService.getPager(this.listLength, this.pageNum);
          this.config.setDelayRecords();
          // if (this.listLength) {
          //   this.tableListing = true;
          //   // document.getElementById("absEditBtnShowAll").focus();
          // }
          this.lockAll();
        });
    }else this.retainPricingData()
  }

  getPricingListingWithFilter() {
    if (this.pageNum == 1) {
      this.abstractorService.getPriceListWithLoader(this.route.snapshot.params['id'], this.pageNum, this.filterBy)
        .subscribe((priceList) => {
          this.config.resetShowFlag()
          this.config.setClearTimeout();
          this.priceList =  priceList.rows;
          this.rowData = priceList.rows;
          this.listLength = priceList.count;
          this.pager = this.pagerService.getPager(this.listLength, this.pageNum);
          this.config.setDelayRecords();
          this.lockAll();
        });
    }else this.retainPricingDataWithLoader()
  }

  retainPricingDataWithLoader() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.abstractorService.getPriceListWithLoader(this.route.snapshot.params['id'], i, this.filterBy))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainPricingData) => {
        if (retainPricingData) {
          this.config.resetShowFlag()
          this.config.setClearTimeout();
          var totalOrders = [];
          for (var i = 0; i < retainPricingData.length; i++) {
            totalOrders= totalOrders.concat(retainPricingData[i]['rows']);
          }
          this.rowData = totalOrders;
          this.pager = this.pagerService.getPager(this.listLength, this.pageNum);
          this.config.setDelayRecords();
          this.lockAll();
        }
      })
  }
  
  retainPricingData() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.abstractorService.getPriceList(this.route.snapshot.params['id'], i, this.filterBy))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainPricingData) => {
        if (retainPricingData) {
          this.config.resetShowFlag()
          this.config.setClearTimeout();
          var totalOrders = [];
          for (var i = 0; i < retainPricingData.length; i++) {
            totalOrders= totalOrders.concat(retainPricingData[i]['rows']);
          }
          this.rowData = totalOrders;
          this.pager = this.pagerService.getPager(this.listLength, this.pageNum);
          this.config.setDelayRecords();
          this.lockAll();
        }
      })
  }

  clearError() {
    this.errorMsg.msg = '';
  }

  deletePrice(i) {
    var data = this.priceList[i];
    if (data.isNew) {
      this.deleteFirstItem();
    }
    else {
      this.abstractorService.deletePriceFromList(data).subscribe((result) => {
        this.getPricingListing()
        this.filterArray = true
      });
    }
  }

  deleteFirstItem() {
    this.priceList.splice(0, 1);
    this.listLength--;
  }

  userAction(event) {
    if (event.checked == false) {
      this.openConfirm('Disable Abstractor', 'Would you like to disable this abstractor ?').afterClosed()
        .subscribe((res) => {
          if (res == true) {
            this.updateAbsStatus(CONSTANTS.userStatus.inActive);
          }
          else {
            this.editModel.userState = !this.editModel.userState
          }
        });
    } else if (event.checked == true) {
      this.openConfirm('Enable Abstractor', 'Would you like to enable this abstractor ?').afterClosed()
        .subscribe((res) => {
          if (res == true) {
            this.updateAbsStatus(CONSTANTS.userStatus.active);
          }
          else {
            this.editModel.userState = !this.editModel.userState
          }
        });
    }
  }

  updateAbsStatus(userStatus) {
    var data = {
      Admin_ID: this.auth.getUserId(),
      Abs_User_ID: this.route.snapshot.params['id'],
      Abs_User_Status : userStatus
    }
    this.abstractorService.updateAbsStatus(data)
      .subscribe((data) => {
        let msg = "Updated successfully."
        this.userMsg.setSuccessMsg(msg);
      }, (err) => {
        this.editModel.userState = !this.editModel.userState
        this.openErrorPopup('Error while updating the abstractor status')
      })
  }

  openConfirm(title, msg) {
    return this.matDialog.open(ConfirmComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }


  getCountyList(event, index) {
    if (index != null) {
      if(event.length != 0 && event != "all"){
        this.abstractorService.getAvailableCountyList(this.newProdObj['State_Short_Name']).subscribe((counties) => { this.counties = counties });
      }
    }
    else {
      this.selectedCounty = '';
      this.selectedProduct = 0;
      this.abstractorService.getAvailableCountyList(this.selectedState).subscribe((counties) => { this.counties = counties });
      this.applyFilter();
    }
  }

  ngAfterViewInit() {
    this.timer.push(setTimeout(() => {
      let searchBox: any = document.getElementById('Address_search_box');
      let options = {
        types: [
          'geocode',
        ]
      };
      var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
      autocomplete.addListener('place_changed', () => {
        this.getPlaceDetails(autocomplete.getPlace());
        this.ref.detectChanges();
      });
    }, 1000));
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedIndex = tabChangeEvent.index; 
    if(tabChangeEvent.index == 1) {
      this.gridApi.sizeColumnsToFit();
    }
  }
  getPlaceDetails(place: any) {
    this.editModel.Address_1 = '';
    this.editModel.Address_City = '';
    this.editModel.Address_State_Abbr = '';
    this.editModel.Address_ZipCode = '';
    this.latitute = '';
    this.longitude = '';
    if (place.geometry == undefined) {
      return;
    } else {

    }
    this.latitute = place.geometry.location.lat();
    this.longitude = place.geometry.location.lng();
    if (place.address_components.length) {
      for (let i = 0; i < place.address_components.length; i++) {
        console.log("Places==>", place.address_components[i].types[0]);
        switch (place.address_components[i].types[0]) {
          case 'street_number': this.editModel.Address_1 = place.address_components[i].long_name + ' ';
            break;
          case 'route': this.editModel.Address_1 = this.editModel.Address_1 + place.address_components[i].long_name;
            break;
          case 'locality': {
            this.editModel.Address_City = place.address_components[i].long_name;
            break;
          }
          case 'administrative_area_level_2': {
            break;
          }
          case 'administrative_area_level_1': this.editModel.Address_State_Abbr = place.address_components[i].short_name;
            break;
          case 'postal_code': this.editModel.Address_ZipCode = place.address_components[i].short_name;
            break;
          default: break;
        }
      }
    } else {
      this.editModel.Address_1 = '';
    }
  }


  addOrEditAbstractorPricing() {
    this.addPrice(this.newProdObj);
  }

  addPrice(item) {
    this.abstractorService.addPriceToAbstractor(item)
      .subscribe((res) => {
        this.editCopy = {};
        this.currentIndex = -1;
        this.deleteFirstItem();
        this.products.map((product) => delete product.Abstrator_Cost);
        item = {};
        this.tableListing = false;
        this.filterArray = true;
        let msg = "Product added successfully"
        this.userMsg.setSuccessMsg(msg);
        this.getPricingListing();
        this.newProdObj = {};
        //sessionStorage.removeItem('AllAbstractors')
      }, (err) => {
        this.errorMsg = err;
      });
  }

  editPrice(item) {
    this.abstractorService.editPriceToAbstractor(item)
      .subscribe((res) => {
        this.editCopy = {};
        this.currentIndex = -1;
        this.filterArray = true;
        this.pageNum = 1;
        this.getPricingListing();
        let msg = "Product updated successfully"
        this.userMsg.setSuccessMsg(msg);
        this.itemInput = '';
      }, (err) => {
        this.errorMsg = err;
      });
  }

  areFieldsSelected() {
    if(Object.keys(this.newProdObj).length > 0) {
      if((this.newProdObj['State_Short_Name'] == "" ) || (this.newProdObj['State_ID'] == "") ||
      (this.newProdObj['selectedProducts'].length == 0) || (!this.checkAllProductDescAndprice(this.newProdObj))){
        return true
      }else return false;
    }
    // if (this.priceList[index].isNew) {
    //   if ((this.priceList[index].State_Short_Name == '') || (this.priceList[index].State_ID == '')
    //     || (this.priceList[index].selectedProducts.length == 0)
    //     || (!this.checkAllProductDescAndprice(this.priceList[index]))) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } else {
    //   if (this.priceList[index].State_Short_Name == '' || this.priceList[index].State_ID == '' || this.priceList[index].Product_ID == ''
    //     || !this.priceList[index].Abstrator_Cost) {
    //     return true;
    //   }
    //   else return false;
    // }
  }

  checkAllProductDescAndprice(item) {
    var flag = 0;
    item.selectedProducts.forEach((product) => {
      if (product.Abstrator_Cost == '' || product.Abstrator_Cost == undefined || product.Abstrator_Cost == null) flag++;
    })
    if (flag == 0) return true;
    else return false;
  }

  editAbstractor() {
    
    this.clearError();
    this.editModel.User_ID = this.route.snapshot.params['id'];
    if (!this.editModel.User_SSN) this.editModel.User_SSN = null;
    if (!this.editModel.User_EIN) this.editModel.User_EIN = null;
    if (!this.editModel.User_Abstractor_Attribute_EnO) this.editModel.User_Abstractor_Attribute_EnO = false;
    if (!this.editModel.User_Abstractor_Attribute_EnO_Expiry) this.editModel.User_Abstractor_Attribute_EnO_Expiry = null;
    if(this.secondaryEmailsArr.value && this.secondaryEmailsArr.value.length > 0){
      this.secondaryEmailsArr.value.forEach((email, i) =>{
        if(email.hasOwnProperty('Email_index')){
          this.secondaryEmail.push(email)
        }else {
          if(!(this.secEmailLastIndexValue > 0)) email['Email_index']=i+1
          else email['Email_index']= this.secEmailLastIndexValue+= 1
          this.secondaryEmail.push(email)
        }
      })
       this.editModel.Secondary_Emails= this.secondaryEmail;
     }else this.editModel.Secondary_Emails= [];

     if(this.secondaryPhoneArr && this.secondaryPhoneArr.value.length > 0){
      this.secondaryPhoneArr.value.forEach((phone,i) => {
        if(phone.hasOwnProperty('Phone_Index')){
          this.secondaryPhone.push(phone)
        }else{
          if(!(this.secPhoneLastIndexValue > 0)) phone['Phone_Index']=i+1
          else phone['Phone_Index']=this.secPhoneLastIndexValue+=1
          this.secondaryPhone.push(phone)
        }
      })
      this.editModel.Secondary_Phone= this.secondaryPhone;
     }else this.editModel.Secondary_Phone= [];
     if(this.removeSEmail && this.removeSEmail.length > 0) this.editModel['remove_Sec_Email'] = this.removeSEmail;
     if(this.removeSPhone && this.removeSPhone.length > 0) this.editModel['remove_Sec_Phone'] = this.removeSPhone;
    this.makeEditRequest(this.route.snapshot.params['id'], this.editModel);
  }

  makeEditRequest(id, model) {
    this.abstractorService.editAbstractor(id, model)
      .subscribe((res) => {
        if (res) {
          //sessionStorage.removeItem('AllAbstractors');
          let msg = "Abstractor updated successfully."
          this.userMsg.setSuccessMsg(msg);
          this.router.navigate(['/abstractor/list']);
        }
      }, (err) => {
        this.secondaryEmail =[];
        this.secondaryPhone=[];
        this.errorMsg = err;
      });
  }

  openModal() {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: "Success",
      message: "Abstractor updated successfully."
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      this.router.navigate(['/abstractor/list']);
    });
  }

  goBack() {
    this.router.navigate(['/abstractor/list']);
  }

  switchEditStatus(index) {
    this.priceList[index].isEditable = !this.priceList[index].isEditable;
  }

  assignPricing(i) {
    this.priceList[i].Abstrator_Cost = this.priceList[i].Abstrator_Cost;
  }

  savepricing(i) {
    // this.addOrEditAbstractorPricing(this.priceList[i]);
    // this.switchEditStatus(i);
  }

  makeEditable(i) {
    if (this.priceList[0].isNew) {
      this.deleteFirstItem();
      this.getEditableIndex(i - 1);
    } else {
      this.getEditableIndex(i);
    }
  }

  getEditableIndex(i) {
    this.filterArray = false;
    this.abstractorService.getAvailableCountyList(this.priceList[i].State_Short_Name).subscribe((counties) => {
      this.counties = counties;
    });
    if (this.currentIndex != -1) this.priceList[this.currentIndex] = this.editCopy;
    this.editCopy = Object.assign({}, this.priceList[i]);
    this.currentIndex = i;
    this.lockAll()
    this.assignPricing(i);
    this.switchEditStatus(i);
  }

  lockAll() {
    this.priceList = this.priceList.map((item) => {
      item.isEditable = false;
      return item;
    });
  }

  hideNewProd() {
    this.tableListing = false;
    this.newProdObj['selectedProducts'].forEach((prodDet) => prodDet.Abstrator_Cost = null)
    this.newProdObj = {};
  }

  cancelEdit(i) {
    this.filterArray = true;
    if (!this.rowData[i].isNew) {
      this.tableListing = true;
      this.priceList[i] = this.editCopy;
      this.priceList[i].isEditable = false
      this.itemInput = '';
    }
    else {
      this.products.map((product) => delete product.Abstrator_Cost);
      this.tableListing = true;
      this.deleteFirstItem();
    }
    this.editCopy = {};
    this.currentIndex = -1;
  }

  addToList() {
    this.tableListing = true;
    this.filterArray = false;
    this.avilableProds.map((prod) => prod.currInput = '');
    this.lockAll();
    var data = {};
    data['isNew'] = true;
    data['isEditable'] = true;
    data["Abstrator_User_ID"] = this.route.snapshot.params['id']
    //data["Product_ID"] = 0;
    data["State_Short_Name"] = ""
    //data["Abstrator_Cost"] = 0;
    data["State_ID"] = 0;
    data['selectedProducts'] = [];
    this.priceList.unshift(data);
    this.listLength++;
  }

  addNewProductToList() {
    this.tableListing = true;
    this.avilableProds.map((prod) => prod.currInput = '');
    this.lockAll()
    this.newProdObj['isNew'] = true;
    this.newProdObj['isEditable'] = true;
    this.newProdObj["Abstrator_User_ID"] = this.route.snapshot.params['id']
    //data["Product_ID"] = 0;
    this.newProdObj["State_Short_Name"] = ""
    //data["Abstrator_Cost"] = 0;
    this.newProdObj["State_ID"] = 0;
    this.newProdObj['selectedProducts'] = [];
  }

  isAddAllowed() {
    if (!this.listLength) return true;
    else {
      if (this.newProdObj['isNew']) return false;
      else return true;
    }
  }


  equals(objOne, objTwo) {
    if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
      return objOne.Product_ID === objTwo.Product_ID;
    }
  }

  selectAll(select: NgModel, values) {
    select.update.emit(values);

  }

  deselectAll(select: NgModel) {
    select.update.emit([]);
  }

  checkAction(event, select, values) {
    if (event) {
      if (event.checked) this.selectAll(select, values);
      else this.deselectAll(select);
    }
  }

  seeChange(event, item) {
    // item.Product_ID = '';
    // item.Abstrator_Cost = null;
    var countyIndex = this.counties.findIndex((county) => county.State_ID == item.State_ID);
    if (this.counties && this.counties['County_Name'])
      item.County_Name = this.counties[countyIndex].County_Name;
  }

  onChange(item) {
    var productIndex = this.products.findIndex((product) => product.Product_ID == item.Product_ID);
    item.Product_Description = this.products[productIndex].Product_Description;
    item.Product_Name = this.products[productIndex].Product_Name;
    // item.Abstrator_Cost = null;
  }

  getAbstractorFormat(e, item) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.itemInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.itemInput.length > 0 || this.itemInput.length < this.config.getLimitPriceMaxLength())) {
        this.itemInput = this.itemInput.slice(0, this.itemInput.length - 1);
        e.target.value = this.numberFormat(this.itemInput);
        item.Abstrator_Cost = e.target.value;
      }
      else if (this.itemInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.itemInput = this.itemInput + key;
          e.target.value = this.numberFormat(this.itemInput);
          item.Abstrator_Cost = e.target.value;
        }
      }
      return false;
    }
  }

  getSelectedProdFormat(e, selProd) {
    if (selProd) {
      if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) {
        return true;
      }
      // if ((selProd.currInput.length == 0 && e.key == "0")) {
      //   e.preventDefault();
      // }
      // else {
        if ((e.keyCode == 8 || e.keyCode == 46) && (selProd.currInput.length > 0 || selProd.currInput.length < this.config.getLimitPriceMaxLength())) {
          selProd.currInput = selProd.currInput.slice(0, selProd.currInput.length - 1);
          e.target.value = this.numberFormat(selProd.currInput);
          selProd.Abstrator_Cost = e.target.value;
        }
        else if (selProd.currInput.length < this.config.getLimitPriceMaxLength()) {
          var key = this.keyValue(e.keyCode);
          if (key) {
            selProd.currInput = selProd.currInput + key;//1

            e.target.value = this.numberFormat(selProd.currInput);
            selProd.Abstrator_Cost = e.target.value;
          }
        }
        return false;
      // }
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return "";
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  onScrollDown() {
    if(this.selectedIndex == 1){
      this.config.setClearTimeout();
      this.config.setDelayRecords();
      if (this.pageNum < this.pager.totalPages) {
        this.pageNum++;
        this.abstractorService.getPriceListWithLoader(this.route.snapshot.params['id'], this.pageNum, this.filterBy)
          .subscribe((prodPrice) => {
            this.config.resetShowFlag();
            this.config.setClearTimeout();
            // this.priceList = this.priceList.concat(prodPrice.rows)
            this.rowData = this.rowData.concat(prodPrice.rows)
            this.listLength = prodPrice.count;
            this.pager = this.pagerService.getPager(this.listLength, this.pageNum);
            this.config.setDelayRecords();
          })
      }
    }
  }

  applyFilter() {
    this.pageNum = 1;
    this.filterBy = [];
    if (this.selectedState && this.selectedState != 'all') this.filterBy.push({ State_Short_Name: this.selectedState })
    if (this.selectedCounty && this.selectedCounty != 'all') this.filterBy.push({ County_Name: this.selectedCounty })
    if (this.selectedProduct && this.selectedProduct != -1) this.filterBy.push({ Product_ID: this.selectedProduct })
    this.getPricingListingWithFilter();
  }

  // showAll() {
  //   this.tableListing = true;
  //   this.filterBy = [];
  //   if (this.selectedState) this.selectedState = '';
  //   if (this.selectedCounty) this.selectedCounty = ''
  //   if (this.selectedProduct) this.selectedProduct = 0;
  //   this.pageNum = 1;
  //   this.getPricingListing()
  // }


  successDialog() {
    let disposable = this.matDialog.open(SuccessComponent,{data:{
      title: "Success",
      message: "Product added successfully"
    }, ...this.config.getDialogOptions()})
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleEvent(event) {
    if (event == false) {
      if (this.listLength > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  clearZipcode() {
    if (this.editFrm.controls['Address_ZipCode'].value)
      this.editFrm.controls['Address_ZipCode'].reset();
  }

  checkQuickBookSession() {
    if (this.quickbooks.getSharedSessionData()) {
      if (this.quickbooks.getSharedSessionData().url == '/abstractor/edit/' + this.route.snapshot.params['id']) {
        switch (this.quickbooks.getSharedSessionData().continue) {
          case 'update_abstractor': this.makeEditRequest(this.route.snapshot.params['id'], this.quickbooks.getSharedSessionData().data); break;
          default: break;
        }
      }
    }
  }

  resetAllValue(event, index) {
    this.priceList[index].State_ID = '';
    this.priceList[index].Product_ID = '';
    this.priceList[index].Abstrator_Cost = null;
  }

  resetProductValues(event, index) {
    this.priceList[index].Product_ID = '';
    this.priceList[index].Abstrator_Cost = null;
  }

  resetCostValue(event, index) {
    this.priceList[index].Abstrator_Cost = null;
  }
  onCellClicked(event) {
    if(event.column.colId == "0" || event.column.colId == 0) {
       this.gridApi.stopEditing(true);
       this.gridApi.undoCellEditing();
       this.isCellButtonclicked = true;
    } else {
      this.isCellButtonclicked = false;
    }
  }

  cellValueChanged(event) {
    if(event.column.colId == "Abstrator_Cost" && event.newValue == ""){
      event.newValue = "0";
      event.value = "0";
    }
    if (event.newValue !== event.oldValue.toString()) {
      if (!event.node.changedValues) event.node.changedValues = [];
      if (!event.node.updatedCellValues) event.node.updatedCellValues = [];
      let nodeObj = {};
      nodeObj[event['column']['colId']] = event.newValue;
      // nodeObj['Column_ID'] = event['column']['colId']
      event.node.changedValues.push(nodeObj);
      event.node.updatedCellValues.push(event['column']['colId']);
    }
  }

  rowValueChanged(event) {
    let cellChangeObj = event;
    // if (event.node.updatedCellValues && event.node.updatedCellValues.length >= 1){
    if (!this.isCellButtonclicked){
      if(cellChangeObj.data.State_Short_Name == ""){
        let errMsg = "State is required."
        this.userMsg.setErrorMsg(errMsg);
        this.gridApi.undoCellEditing();
      }
      else if(cellChangeObj.data.County_Name == ""){
        let errMsg = "County is required."
        this.userMsg.setErrorMsg(errMsg);
        this.gridApi.undoCellEditing();
      }
      else this.checkForStateNCounty(cellChangeObj.data.State_Short_Name, cellChangeObj.data.County_Name, cellChangeObj, event)
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.getPricingListing();
  }

  checkForStateNCounty(stateName, countyName, cellChangeObj, event) {
    if(cellChangeObj.data && cellChangeObj.data.Abstrator_Cost == "") cellChangeObj.data.Abstrator_Cost = 0;
      if(cellChangeObj.data && !isNaN(cellChangeObj.data.Abstrator_Cost)){
        this.abstractorService.checForStateAndCounty(stateName, countyName)
        .subscribe((data) => {
          if (!data.State_ID) {
            // let errMsg = "Select state/county is incorrect."
            // this.openErrorPopup(errMsg);
            // // this.getPricingListing();
            let errMsg = "Given state/county is incorrect."
            this.userMsg.setErrorMsg(errMsg);
            this.gridApi.undoCellEditing();
          } else {
            // let prodFindIndex = this.products.findIndex((product) => event.node.changedValues.some((data) => data.Product_Description === product.Product_Description))
            //Mapping the changed product description with the product description of products list and then getting its product id
            let prodFindIndex = this.products.findIndex((product) => (product.Product_Description == cellChangeObj.data['Product_Description']) );
            if (prodFindIndex > -1) cellChangeObj.data['Product_ID'] = this.products[prodFindIndex].Product_ID;
            cellChangeObj.data['isEditable'] = true;
            cellChangeObj.data['State_ID'] = data.State_ID;
            this.editPrice(cellChangeObj.data);
          }
        })
      }else {
        let errMsg = "Please enter only digit and one decimal. "
        this.userMsg.setErrorMsg(errMsg);
        this.gridApi.undoCellEditing();
      }
    }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  resetFilter() {
    this.selectedState = '';
    this.selectedCounty = '';
    this.selectedProduct = 0;
    this.filterBy = [];
    this.getPricingListingWithFilter();
  }

  addemails() {
    const emailObj = this.fb.group({
      Email_ID: new UntypedFormControl(UUID.UUID()),
      User_ID:new UntypedFormControl(this.route.snapshot.params['id']),
      Email_Priority:new UntypedFormControl(6),
      Email_Address: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.maxLength(255)])),
    });
    this.secondaryEmailsArr.push(emailObj)
  }

  deleteSecondaryEmails(emailIndex: number) {
    if(this.secondaryEmailsArr.value[emailIndex].Email_Address) this.removeSEmail.push(this.secondaryEmailsArr.value[emailIndex])
    this.secondaryEmailsArr.removeAt(emailIndex);
  }

  addphonenmbrs() {
    const emailObj = this.fb.group({
      Contact_ID: new UntypedFormControl(UUID.UUID()),
      Contact_Priority:new UntypedFormControl(6),
      Contact_Num: new UntypedFormControl(null, Validators.required),
      Contact_Type :new UntypedFormControl('M')
    });
    this.secondaryPhoneArr.push(emailObj)
  }

  deleteSecondaryPhones(phoneIndex: number) {
    if(this.secondaryPhoneArr.value[phoneIndex].Contact_Num) this.removeSPhone.push(this.secondaryPhoneArr.value[phoneIndex])
    this.secondaryPhoneArr.removeAt(phoneIndex);
  }

  getSecondaryEmails(data): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    if(data){
    data.forEach((result) => {
      if(result.Email_Sorting){
        formArray.push(
          this.fb.group({
            Email_ID: result.Email_ID,
            User_ID:result.User_ID,
            Email_Priority:result.Email_Priority,
            Email_Address: result.Email_Address,
            Email_index: result.Email_Sorting,
          }))
      }else {
      formArray.push(
      this.fb.group({
        Email_ID: result.Email_ID,
        User_ID:result.User_ID,
        Email_Priority:result.Email_Priority,
        Email_Address: result.Email_Address,
      }))
      }
    })
    }
    return formArray;
  }

  getSecondaryPhones(data): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    if(data){
    data.forEach((result) => {
        if(result && result.Contact_Sorting){
          formArray.push(
          this.fb.group({
            Contact_ID: result.Contact_ID,
            Contact_Priority:result.Contact_Priority,
            Contact_Num: result.Contact_Num,
            Contact_Type : result.Contact_Type,
            Phone_Index: result.Contact_Sorting
            }))
        }else{
      formArray.push(
      this.fb.group({
        Contact_ID: result.Contact_ID,
        Contact_Priority:result.Contact_Priority,
        Contact_Num: result.Contact_Num,
        Contact_Type : result.Contact_Type
      }))
      }
    })
  }
    return formArray;
  }

getPippinDate(event) {
    if(event && event.Pippin_Date ) {
      this.editModel['User_Abstractor_Attribute_EnO_Expiry'] = event.Pippin_Date;
    }else this.editModel['User_Abstractor_Attribute_EnO_Expiry'] = null;
  }

// Method to adjust date to 5 PM EST
adjustDateTo5PMEST(date: Date): Date {
  const adjustedDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  adjustedDate.setHours(17, 0, 0, 0); // Set time to 5 PM EST
  return adjustedDate;
}

// Method to calculate expiration text based on conditions
expirationText() {
  if (!this.editModel.User_Abstractor_Attribute_EnO_Expiry) {
    return 'Exp Known';
  }

  const expirationDate = this.adjustDateTo5PMEST(this.editModel.User_Abstractor_Attribute_EnO_Expiry);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return 'Valid';
  } else if (daysDifference > 0) {
    if (daysDifference === 1) {
      return `Expires in ${daysDifference} day`;
    } else {
      return `Expires in ${daysDifference} days`;
    }
  } else if (daysDifference === 0) {
    return 'Expires today';
  } else {
    return 'Expired';
  }
}

// isCostExpired(cost_modification_date) {
//   const inputDate = new Date(cost_modification_date);
//   const currentDate = new Date();

//   // Calculate the date 12 months ago from today
//   const twelveMonthsAgo = new Date();
//   twelveMonthsAgo.setMonth(currentDate.getMinutes()-CONSTANTS.AbstractorPricingExpiryDuration);
//   return inputDate < twelveMonthsAgo;
// }
// Method to calculate expiration style based on conditions
expirationStyle() {
  if (!this.editModel.User_Abstractor_Attribute_EnO_Expiry) {
    return { color: 'gray', 'font-weight': 'bold' };
  }

  const expirationDate = this.adjustDateTo5PMEST(this.editModel.User_Abstractor_Attribute_EnO_Expiry);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return { color: '#8fd14f', 'font-weight': 'bold' };
  } else if (daysDifference >= 0) {
    return { color: '#fac710', 'font-weight': 'bold' };
  } else {
    return { color: '#f24726', 'font-weight': 'bold' };
  }
}


}
function extractValues(mappings) {
  return Object.keys(mappings);
}

function lookupKey(mappings, name) {
  for (var key in mappings) {
    if (mappings.hasOwnProperty(key)) {
      if (name === mappings[key]) {
        return key;
      }
    }
  }
}


