import { Component, OnInit, ViewChild, ViewEncapsulation,ChangeDetectorRef, NgModule, AfterViewInit} from '@angular/core';
import { AuthService, PagerService, ConfigService, UsersService, OrganizationService, OrdersService, AbstractorService, StateService, ScrollService, TagsService, UsermessageService, PricingService, SchedulingService } from '../../services';
import { CONSTANTS } from '../../app.constants'
import { Router, ActivatedRoute } from '@angular/router';
import { debounce } from "lodash";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { NewTagComponent } from '../../dialog/new-tag/new-tag.component';
import { forkJoin as observableForkJoin} from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { SearchPipe } from '../../pipes/search/search.pipe';
import { DatePipe } from '@angular/common';
import * as momentTimeZone from 'moment-timezone';
import { AssignComponent } from '../../dialog/assign/assign.component';
import { ScreenOrderComponent } from 'app/dialog/screen-order/screen-order.component';
import { EtaChangeReasonComponent } from '../../dialog/eta-change-reason/eta-change-reason.component';
import { environment } from 'environments/environment';
import { SuccessComponent } from '../../dialog/success/success.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchDropdownsService } from './search-dropdowns.service';



@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss'],
  providers: [SearchPipe],
  
})


export class OrderSearchComponent implements OnInit{
  orderSources : any = [];
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  constants = CONSTANTS;
  orders: any = [];
  filterOrderList:any=[];
  orderStatus: any = {};
  orderStatusList = [];
  orderTypes = [];
  priorityTypes = []
  adminList: any = [];
  searchMastersList: any = [];
  abstractorsList: any = [];
  allActiveTags = [];
  ordersToDisplay: any;
  totalCount: number;
  pager: any = {};
  filterModel
  : any = {};
  errors: any = {};
  compDateErrors: any = {};
  etaDateErrors: any = {};
  clients = [];
  states = [];
  counties = [];
  orgs = [];
  maxFromDate: any;
  maxToDate: any;
  maxCompFromDate: any;
  maxCompToDate: any;
  maxETAFromDate: any;
  maxETAToDate: any;
  result = '';
  searchFields = {};
  focusField = false;
  showActiveTags: boolean = false;
  scrollEnable: boolean = true;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  orderTags = [];
  searchTag: string = '';
  associatedTags = [];
  filterDataToService = {};
  loadingClientInfo: boolean = true;
  loadingOrgInfo: boolean = true;
  loadingAdminInfo: boolean = true;
  loadingSMInfo: boolean = true;
  loadingAbsInfo: boolean = true;
  loadingProdInfo: boolean = true;
  loadingStatesInfo: boolean = true;
  loadingCountyInfo: boolean = true;
  timer: any = [];
  compFromTime :string = "00:00" ; 
  compToTime :string = "23:59" ; 
  createdFromTime :string = "00:00" ; 
  createdToTime :string = "23:59" ; 
  ordETAFromTime :string = "00:00" ; 
  ordETAToTime :string = "23:59" ; 
  compDateFromInvalid: boolean = false;
  compDateToInvalid: boolean = false;
  createdDateFromInvalid: boolean = false;
  createdDateToInvalid: boolean = false;
  ordETADateFromInvalid: boolean = false;
  ordETADateToInvalid: boolean = false;
  products: any = [];
  newClientTagId = '';
  priorityClientTagId = '';
  firstOrdTagId = '';
  prePaidTagId= '';
  absDoubleAssignTagId= '';
  holidayList: any;
  orderStatusName:string = "Order ";
  CreatedDateFrom: boolean = false;
  CreatedDateTo: boolean = false;
  CompletedDateFrom: boolean = false;
  CompletedDateTo: boolean = false;
  OrderETADateFrom: boolean = false;
  OrderETADateTo: boolean = false;
  searchManagerTeam = [];

  selectedClientData:any[]=[];
  filteredClientList=[];

  selectedCompanyData:any[]=[];
  filteredCompanyList=[];

  debouncedFilterClients: (e: any) => void;
  debouncedFilterOrgs: (e: any) => void;


  currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
  constructor(
    private auth: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    private matDialog:MatDialog,
    private userService: UsersService,
    private orgService: OrganizationService,
    private holidayScheduling: SchedulingService,
    public orderService: OrdersService,
    private abstractorService: AbstractorService,
    private stateService: StateService,
    private scrollService: ScrollService,
    private tagService: TagsService,
    private searchPipe : SearchPipe,
    private _datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private userMsg: UsermessageService,
    private pricingService: PricingService,
    private ref: ChangeDetectorRef,
    private dropdownService: SearchDropdownsService,

  ) {
    this.pager.pages = [];
    this.filterModel.pageNum = 1;
    this.filterModel.orderBy = 'DESC';
    this.filterModel.sortField = 'Order_Creation_Date';
    this.filterModel.searchString = '';
    this.filterModel.selectedTags = [];
    this.debouncedFilterClients = debounce(this.filterClients.bind(this), 500);
    this.debouncedFilterOrgs=debounce(this.filterOrgs.bind(this),500);
  }

 

  ngOnInit() {
    this.filterModel.Order_Status = [];
    this.filterModel.Is_Manager = this.auth.isSearchManager();
    this.orderStatusList = [
      { status: 'Processing', value: CONSTANTS.orderStatus.processing.toString() },
      { status: 'Completed', value: CONSTANTS.orderStatus.completed.toString() },
      { status: 'Received', value: CONSTANTS.orderStatus.received.toString() },
      { status: 'Assigned', value: CONSTANTS.orderStatus.assigned.toString() },
      { status: 'Confirmed', value: CONSTANTS.orderStatus.confirmed.toString() }, 
    ]
    if(!this.checkIfSearchMaster()){
      this.orderStatusList.push(
        { status: 'Cancelled', value: CONSTANTS.orderStatus.cancelled.toString() },
        { status: 'Quote', value: CONSTANTS.orderStatus.quotecompleted.toString()}
      )
    }
    
    if (this.activatedRoute.snapshot.queryParams['User_ID']) this.searchMasterParams()
    else if(this.activatedRoute.snapshot.queryParams['tagID'])this.tagsParams()
    else if(this.activatedRoute.snapshot.queryParams['Admin_User_ID'])this.adminParams();
    else if(this.activatedRoute.snapshot.queryParams['Abs_User_ID'])this.abstractorParams();
    else if(this.activatedRoute.snapshot.queryParams['Client_User_ID'])this.newClientParams();
    else {
      if (this.stateService.getSearchOrders()) {
        this.initializeComponentData(this.stateService.getSearchOrders());
      }
      else {
        this.filterModel.actionType = 'all';
        this.filterModel.priorityType = 'all';
        this.filterModel.errorType = 'all';
        this.filterModel.orderType = 'all';
        this.filterModel.Customer_ID = ['all'];
        this.filterModel.states =['all'];
        this.filterModel.counties = ['all'];
        this.filterModel.Organization_ID = '';
        this.filterModel.Sel_Admin_User_ID = '';
        this.filterModel.Sel_Searcher_User_ID = '';
        this.filterModel.Sel_Abs_User_ID = '';
        this.filterModel.isTestOrders = false;
        this.filterModel.Product_Description = [];
        this.filterModel.Source_Type_ID = '';
        this.selectAllStatus();
        this.filterModel.Admin_ID = this.auth.getUserId();
        this.filterModel.Sel_Order_Admin_ID = '';
      }
    }
    this.orderStatus = this.orderService.getOrderStatus();
    this.orderTypes = [
      { name: 'Individual', value: 'individual' },
      { name: 'Company', value: 'org' },
    ]
    this.priorityTypes = [
      {name: 'Priority order', value: 'priority'},
    ]
    this.getOrderSourceTypes()
    if (!this.checkIfSearchMaster()) { 
      this.orderStatusName = "Order/Quote ";
      this.getClients('');
      this.getOrgs('');
      this.getAdminList();
      this.getSearchMasterList();
      this.getAbstractorsList();
    }
    this.getStates();
    if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin || 
      Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) this.getAllActiveTags();
    else this.getAllActiveGroupTags();
    this.getAllProductList();
    if(this.config.getNewClientTagId()) this.newClientTagId = this.config.getNewClientTagId();
    if(this.config.getPriorityClientTagId()) this.priorityClientTagId = this.config.getPriorityClientTagId();
    if(this.config.getFirstOrdTagId()) this.firstOrdTagId = this.config.getFirstOrdTagId();
    if(this.config.getPrePaidTagId()) this.prePaidTagId = this.config.getPrePaidTagId();
    if(this.config.getAbsDoubleAssignTagId()) this.absDoubleAssignTagId = this.config.getAbsDoubleAssignTagId();
    this.getholidaylistFromLocal();
    this.getSearchManagerAndItsTeam();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(16), 0));

  }

   ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.filterModel = data.filterModel;
    this.filterModel.pageNum = data.filterModel.pageNum;
    this.filterModel.searchString = data.filterModel.searchString;
    this.filterModel.orderBy = data.filterModel.orderBy;
    this.filterModel.sortField = data.filterModel.sortField;
    this.totalCount = data.totalCount;
    this.filterModel.actionType = data.filterModel.actionType;
    this.filterModel.priorityType = data.filterModel.priorityType;
    this.filterModel.errorType = data.filterModel.errorType
    this.filterModel.orderType = data.filterModel.orderType;
    this.filterModel.Product_Description = data.filterModel.Product_Description;
    this.filterModel.Customer_ID = data.filterModel.Customer_ID;
    this.filterModel.states = data.filterModel.states;
    this.filterModel.counties = data.filterModel.counties;
    this.filterModel.Organization_ID = data.filterModel.Organization_ID;
    this.filterModel.Sel_Admin_User_ID = data.filterModel.Sel_Admin_User_ID;
    this.filterModel.Sel_Order_Admin_ID = data.filterModel.Sel_Order_Admin_ID;
    this.filterModel.Sel_Searcher_User_ID = data.filterModel.Sel_Searcher_User_ID;
    this.filterModel.Sel_Abs_User_ID = data.filterModel.Sel_Abs_User_ID;
    this.filterModel.Order_Status = data.filterModel.Order_Status;
    this.filterModel.Admin_ID = data.filterModel.Admin_ID;
    this.filterModel.selectedTags = data.filterModel.selectedTags;
    this.filterModel.Source_Type_ID = data.filterModel.Source_Type_ID;
    this.showActiveTags = data.showActiveTags;
    this.filterModel.isTestOrders = data.filterModel.isTestOrders;
    this.compFromTime = data.compFromTime; 
    this.compToTime = data.compToTime;
    this.selectedClientData=data.selectedClients;
    this.setSelectedClientsOnTop(this.selectedClientData);
    this.selectedCompanyData=data.seletedCompany;
    this.setSelectedCompanyOnTop(this.selectedCompanyData);
    this.searchOrder();
  }

  searchMasterParams(){
    window.history.replaceState({}, "Hide", '/orders/search');
    this.resetFields();
      this.activatedRoute.queryParams.subscribe((params) => {
      this.filterModel.Sel_Searcher_User_ID = JSON.parse(params.User_ID)
    })
    this.filterModel.Admin_ID = this.auth.getUserId();
    this.filterModel.Order_Status = [
      this.constants.orderStatus.received.toString(), 
      this.constants.orderStatus.assigned.toString(), 
      this.constants.orderStatus.confirmed.toString(), 
      this.constants.orderStatus.processing.toString(),
      this.constants.orderStatus.cancelled.toString(),
      this.constants.orderStatus.completed.toString(),
      this.constants.orderStatus.quotecompleted.toString()
    ];
    this.searchOrdersOnClick()
  }

  newClientParams(){
    window.history.replaceState({}, "Hide", '/orders/search');
    this.resetFields();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.filterModel.Customer_ID = [params.Client_User_ID]
    })
    this.filterModel.Admin_ID = this.auth.getUserId();
    this.filterModel.Order_Status = [
      this.constants.orderStatus.received.toString(), 
      this.constants.orderStatus.assigned.toString(), 
      this.constants.orderStatus.confirmed.toString(), 
      this.constants.orderStatus.processing.toString(),
      this.constants.orderStatus.cancelled.toString(),
      this.constants.orderStatus.completed.toString(),
    ];
    this.searchOrdersOnClick()
  }

  tagsParams(){
    window.history.replaceState({}, "Hide", '/orders/search');
    this.resetFields();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.filterModel.selectedTags = [JSON.parse(params.tagID)]
    })
    this.filterModel.Admin_ID = this.auth.getUserId();
    this.filterModel.Order_Status = [
      this.constants.orderStatus.received.toString(), 
      this.constants.orderStatus.assigned.toString(),
      this.constants.orderStatus.completed.toString(),
      this.constants.orderStatus.confirmed.toString(), 
      this.constants.orderStatus.processing.toString(),
      this.constants.orderStatus.quotecompleted.toString(),
      this.constants.orderStatus.cancelled.toString()
    ];
    this.searchOrdersOnClick();
  }

  getAdminList() {
    var data = {};
    if (this.filterModel.Organization_ID) data['Organization_ID'] = this.filterModel.Organization_ID;
    this.dropdownService.getAdminOptions()
      .subscribe((adminData) => {
        this.loadingAdminInfo = false;
        this.adminList = adminData;
      },
        (err) => {
          this.loadingAdminInfo = false;
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        });
  }

  getSearchMasterList() {
    var data = {};
    if (this.filterModel.Organization_ID) data['Organization_ID'] = this.filterModel.Organization_ID;
    this.dropdownService.getSearcherOptions(data)
      .subscribe((searchMastersData) => {
        this.loadingSMInfo = false;
        this.searchMastersList = searchMastersData; 
      },
        (err) => {
          this.loadingSMInfo = false;
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        });
  }

  
  getAbstractorsList() {
    this.dropdownService.getAbstractorOptions()
      .subscribe((abslist) => {
        this.loadingAbsInfo = false;
        this.abstractorsList = abslist;
      },
        (err) => {
         this.loadingAbsInfo = false;
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        });
  }

getAllActiveGroupTags(){
  let data = {
    Admin_ID : this.auth.getUserId()
  }
    this.tagService.getAllActiveGroupTags(data)
      .subscribe((activeTags) => {
        if (activeTags) {
          this.allActiveTags = activeTags.rows;
          if(this.filterModel.selectedTags.length > 0) {
            this.allActiveTags.map((chip)=> {
              var index = this.filterModel.selectedTags.indexOf(chip.Tag_ID);
              if(index > -1) chip.selected = true ;
            })
          }
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  getAllActiveTags(){
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        if (activeTags) {
          this.allActiveTags = activeTags.rows;
          if(this.filterModel.selectedTags.length > 0) {
            this.allActiveTags.map((chip)=> {
              var index = this.filterModel.selectedTags.indexOf(chip.Tag_ID);
              if(index > -1) chip.selected = true ;
            })
          }
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }


  onChipSelect(chip){
    chip.selected = !chip.selected;
    this.onSelectionChange(chip);
  }

  onSelectionChange(chip) {
    var index = this.filterModel.selectedTags.indexOf(chip.Tag_ID);
    if(index > -1) {
      this.filterModel.selectedTags.splice(index, 1);
    } else this.filterModel.selectedTags.push(chip.Tag_ID);
    this.filterModel.pageNum = 1;
    this.searchOrder();
  }

  onFocus() {
    this.focusField = true;
    console.log("onFocus", this.focusField)
  }

  setSort(field: string) {
    this.filterModel.pageNum = 1;
    if (field == this.filterModel.sortField) {
      if (this.filterModel.orderBy == 'ASC') this.filterModel.orderBy = 'DESC';
      else this.filterModel.orderBy = 'ASC';
    } else {
      this.filterModel.sortField = field;
      this.filterModel.orderBy = 'ASC';
    }
    this.searchOrder();
  }

  setSortDefaultAsDec(field: string) {
    this.filterModel.pageNum = 1;
    if (field == this.filterModel.sortField) {
      if (this.filterModel.orderBy == 'ASC') this.filterModel.orderBy = 'DESC';
      else this.filterModel.orderBy = 'ASC';
    } else {
      this.filterModel.sortField = field;
      this.filterModel.orderBy = 'DESC';
    }
    this.searchOrder();
  }


  resetFields() {
    if(this.filterModel.selectedTags > 0) this.deselectAllChips();
    this.stateService.setSearchOrders(null);
    this.filterModel.isTestOrders = false;
    this.filterModel.actionType = 'all';
    this.filterModel.errorType = 'all';
    this.filterModel.orderType = 'all';
    this.filterModel.Customer_ID = ['all'];
    this.filterModel.Organization_ID = '';
    this.filterModel.Sel_Admin_User_ID = '';
    this.filterModel.Source_Type_ID = '';
    this.filterModel.Sel_Searcher_User_ID = '';
    this.filterModel.Sel_Abs_User_ID = '';
    this.filterModel.states =[];
    this.filterModel.counties = [];
    this.filterModel.searchString = '';
    this.filterModel.Sel_Order_Admin_ID = '';
    this.selectAllStatus();
    this.filterModel['fromDate'] = null
    this.filterModel['toDate'] = null;
    this.filterModel['fromCompletedDate'] = null;
    this.filterModel['fromCompleted_DateTime'] = null;
    this.filterModel['toCompletedDate'] = null;
    this.filterModel['toCompleted_DateTime'] = null; 
    this.filterModel['fromETADate'] = null;
    this.filterModel['toETADate'] = null;
    this.filterModel['fromCreated_DateTime'] = null;
    this.filterModel['toCreated_DateTime'] = null; 
    this.filterModel['fromOrdETA_DateTime'] = null;
    this.filterModel['toOrdETA_DateTime'] = null; 
    this.createdFromTime = "00:00" ; 
    this.createdToTime = "23:59" ; 
    this.ordETAFromTime = "00:00" ; 
    this.ordETAToTime = "23:59" ; 
    this.compFromTime = "00:00" ; 
    this.compToTime = "23:59" ; 
    this.orders = [];
    this.pager.pages = [];
    this.result = '';
    this.filterModel.Product_Description = [];
    this.deselectAllChips();
    if (this.errors['Date_Range'])
      delete this.errors['Date_Range'];
    if (this.compDateErrors['Date_Range']) delete this.compDateErrors['Date_Range'];
    if (this.etaDateErrors['Date_Range']) delete this.etaDateErrors['Date_Range'];
    if(this.showActiveTags) this.showActiveTags  = false;
    this.filterModel.priorityType='all';
    this.CreatedDateFrom= false;
    this.CreatedDateTo = false;
    this.CompletedDateFrom = false;
    this.CompletedDateTo = false;
    this.OrderETADateFrom = false;
    this.OrderETADateTo = false;
   
    this.ref.detectChanges();
  }

  deselectAllChips() {
    this.filterModel.selectedTags = [];
    this.allActiveTags.map((chip) => chip.selected = false);
  }

  setDisplayOrders() {
    let start = ((this.filterModel.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.ordersToDisplay = this.orders.slice(start, this.totalCount);
    if (this.ordersToDisplay.length > this.config.getNumRecordsPerPage()) this.ordersToDisplay = this.ordersToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  validateDateRange() {
    if (this.filterModel['fromDate'] && this.filterModel['toDate'] && (this.filterModel['fromDate'] > this.filterModel['toDate'])) {
      this.errors['Date_Range'] = true;
    } else {
      delete this.errors['Date_Range'];
    }
  }

  validateDateFn(event) {
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors['date'] = true;
    else {
      delete this.errors['date'];
      this.validateDateRange();
    }
  }

  validateCompDateFilter(event) {
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.compDateErrors['date'] = true;
    else {
      delete this.compDateErrors['date'];
      this.validateCompDateRangeFilter();
    }
  }

  validateCompDateRangeFilter() {
    if (this.filterModel['fromCompletedDate'] && this.filterModel['toCompletedDate'] && (this.filterModel['fromCompletedDate'] > this.filterModel['toCompletedDate'])) {
      this.compDateErrors['Date_Range'] = true;
    } else {
      delete this.compDateErrors['Date_Range'];
    }
  }

  validateETADateFilter(event) {
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.etaDateErrors['date'] = true;
    else {
      delete this.etaDateErrors['date'];
      this.validateETADateRangeFilter();
    }
  }

  validateETADateRangeFilter() {
    if (this.filterModel['fromETADate'] && this.filterModel['toETADate'] && (this.filterModel['fromETADate'] > this.filterModel['toETADate'])) {
      this.etaDateErrors['Date_Range'] = true;
    } else {
      delete this.etaDateErrors['Date_Range'];
    }
  }

  ifInvalidDateRange() {
   if(this.errors['Date_Range'] ||  this.compDateErrors['Date_Range'] || this.etaDateErrors['Date_Range']) return true;
   else return false;
  }

  ifInvalidTime() {
    if(this.compDateFromInvalid || this.compDateToInvalid) return true;
    else return false;
   }


   

   removeDuplicateClients(selectedList:any[]){
  
    return this.clients.filter(client => {
      return !selectedList.some(selected => selected.User_ID === client.User_ID);
    });
   }

   removeDuplicateCompanies(selectedList:any[]){
    return this.orgs.filter(org => {
      return !selectedList.some(selected => selected.Organization_ID === org.Organization_ID);
    });
   }

   

 
  resetPageNum() {
    this.filterModel.pageNum = 1;
  }

  setSelectedClientsOnTop(selectedList:any[]){
    if(selectedList.length>0){
      this.selectedClientData=[];
      this.selectedClientData=selectedList;
      this.filteredClientList=[...selectedList,...this.removeDuplicateClients(selectedList)]
    }else{
      this.selectedClientData=[];
      this.filteredClientList=this.clients;
    }
  }

  setSelectedCompanyOnTop(selectedList:any[]){
    if(selectedList.length>0){
      this.selectedCompanyData=[];
      this.selectedCompanyData=selectedList;
      this.filteredCompanyList=[...this.selectedCompanyData,...this.removeDuplicateCompanies(selectedList)];
    }else{
      this.selectedCompanyData=[];
      this.filteredCompanyList=this.orgs;
    }
  }

  loadCounties() {
    this.filterModel.pageNum = 1;
    this.getCounties();
  }

  resetSearcherFilter() {
    this.filterModel.Sel_Searcher_User_ID = ''; 
  }
  resetAdminFilter() {
    this.filterModel.Sel_Admin_User_ID = '';
    }
  getAdminAndSearchersForSelOrg() {
    if (!this.checkIfSearchMaster()) {
      this.filterModel.Sel_Searcher_User_ID = '';
      this.filterModel.Sel_Admin_User_ID = '';
      this.getAdminList();
      this.getSearchMasterList();
    }
  }

  searchOrdersOnClick() {
    this.showActiveTags = true;
    this.filterModel.pageNum = 1;
    this.scrollService.setScrollPosOrderSearch(0);
    this.searchOrder();
  }

  setTimeToDate(date, time) {
    if(date && date._d){
      let hours = time.split(":")[0];
      let minutes = time.split(":")[1];
      return date._d.setHours(Number(hours), Number(minutes));
    }else if(date && !date._d){
    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];
    return date.setHours(Number(hours), Number(minutes));
    }else return null;
  }

  getCompletedDateFrom(event) {
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.compDateFromInvalid = true;
    else this.compDateFromInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
      this.filterModel['fromCompletedDate'] = event.Date_EST;
      this.compFromTime = event.Time_EST
      this.filterModel['fromCompleted_DateTime'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
      this.CompletedDateFrom=false;
    }else this.CompletedDateFrom=true;
    }else this.CompletedDateFrom=true;
    this.validateCompDateRangeFilter();
  }

  getCompletedDateTo(event) {
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.compDateToInvalid = true;    
    else this.compDateToInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
      this.filterModel['toCompletedDate'] = event.Date_EST;
      this.compToTime = event.Time_EST;
      this.filterModel['toCompleted_DateTime'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
      this.CompletedDateTo=false;
      }else this.CompletedDateTo=true;
    }else this.CompletedDateTo=true;
    this.validateCompDateRangeFilter();    
  }



searchOrder(){
  
    if (this.filterModel.pageNum == 1) {
      
      this.searchFields = Object.assign({}, this.filterModel);
      this.pager.pages = [];
      this.result = '';
      this.filterDataToService = JSON.parse(JSON.stringify(this.backUpData()));
      this.stateService.setSearchOrders(this.filterDataToService); // Instead of 'this' as a whole, create a object with only necessary properties(ref initializeComponentData()) and pass it to service.
      let promiseCollection = [];
      promiseCollection.push(this.orderService.searchOrder(this.filterModel))
      promiseCollection.push(this.orderService.searchOrderCount(this.filterModel))
      observableForkJoin(promiseCollection)
      .subscribe((orders: any) => {
          if (orders[1] && orders[1].count) {
            this.currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
            this.orders = orders[0].rows;
            this.totalCount = orders[1].count;
            this.getOrdersRecordsData(this.totalCount);
            this.config.setClearTimeout();
            this.setDisplayOrders();
            this.pager = this.pagerService.getPageOrderSearchandComplete(this.totalCount, this.filterModel.pageNum);
            this.config.setDelayRecords();
          

          } else {
            this.orders = [];
            this.result = 'No orders found';
          }
        }, (err) => {
          console.log(err);
        })
    } else {
      this.getRetainData();
    }
  }

  getRetainData() {
    var ObsCollection = [];
    for (var i = 1; i <= this.filterModel.pageNum; i++) {
      var filterModelCopy = { ...this.filterModel };
      filterModelCopy.pageNum = i;
      ObsCollection.push(this.orderService.searchOrder(filterModelCopy))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedNewOrd) => {
        this.getRetainedNewOrd(retainedNewOrd);
        this.getOrderSearchCount()
      })

  }

  getOrderSearchCount(){
    this.orderService.searchOrderCount(this.filterModel)
    .subscribe((orderSearchCount) => {
      this.totalCount = orderSearchCount.count;
      this.getOrdersRecordsData(this.totalCount);
    })
  }

  getRetainedNewOrd(retainedNewOrd) {
    var totalOrders = [];
    for (var i = 0; i < retainedNewOrd.length; i++) {
      this.filterDataToService = JSON.parse(JSON.stringify(this.backUpData())); 
      this.stateService.setSearchOrders(this.filterDataToService);
      totalOrders = totalOrders.concat(retainedNewOrd[i]['rows']);
    }
    this.orders = totalOrders;
    this.getScrollPosData();
  }

  getScrollPosData() {
    
    if (this.scrollService.getScrollPosOrderSearch()) {
      var x = this.scrollService.getScrollPosOrderSearch()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }))
    }
  }

  backUpData(){
    return {
      pager : this.pager,
      filterModel: this.filterModel,
      totalCount : this.totalCount,
      showActiveTags : this.showActiveTags,
      compFromTime : this.compFromTime,
      compToTime : this.compToTime,
      selectedClients:this.selectedClientData,
      seletedCompany:this.selectedCompanyData
    }
  }

  getOrdersRecordsData(result) {
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPageOrderSearchandComplete(result, this.filterModel.pageNum);
    
    this.config.setDelayRecords();
  }

  catchEnterPress(event) {
    if (event.keyCode == 13) this.searchOrder();
  }

  createExcel() {
     if (this.searchFields['Customer_ID'] == 'all') this.searchFields['Customer_ID'] = "";
     if (this.searchFields['states'] == 'all') this.searchFields['states'] = [];
     if (this.searchFields['counties'] == 'all') this.searchFields['counties'] = [];
    this.orderService.createExcel(this.searchFields)
      .subscribe((excel) => {
        if (excel) this.composeAndDwnld(excel);
      }, (err) => {
        // console.log("error is :",err);
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  composeAndDwnld(blobData) {
    let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileUrl = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", "Report.xlsx");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  display(event) {
    console.log("event", event);
  }

  getUserRole() {
    return Number(this.auth.getUserRole())
  }

  filterClients(value:string){
    this.getClients(value);
  }

  onClientChange(e){
    this.debouncedFilterClients(e);
  }

  getClients(inputString) {
    this.userService.getAllavailableClients(inputString)
      .subscribe((clients) => {
        if (clients) {
          this.loadingClientInfo = false;
          this.clients = clients;
          if(this.clients.length>0){    
            this.filteredClientList=[...this.selectedClientData,...this.removeDuplicateClients(this.selectedClientData)];
          }else{
            this.filteredClientList=[];
          }
        }
      }, (err) => {
       this.loadingClientInfo = false;
        console.log(err);
      })
  }

  getStates() {
    this.dropdownService.getStateOptions()
    .subscribe((states) => { 
      this.loadingStatesInfo = false;
      this.states = states 
    }, (err) => {
      this.loadingStatesInfo = false;
    });
  }

  getCounties() {
    this.dropdownService.getCountyOptions(this.filterModel.states)
    .subscribe((counties) => { 
      this.loadingCountyInfo = false;
      this.counties = counties 
    }, (err) => {
      this.loadingCountyInfo = false;
    });
  }

  filterOrgs(value:string){
    this.getOrgs(value);
  }

  onOrgsChange(e){
    this.debouncedFilterOrgs(e);
  }

  getOrgs(inputString) {
    this.orgService.getAvailableOrganizationList(inputString)
      .subscribe((orgs) => {
        if (orgs) {
          this.loadingOrgInfo = false;
          this.orgs = orgs;
          if(this.orgs.length>0){
            this.filteredCompanyList=[...this.selectedCompanyData,...this.removeDuplicateCompanies(this.selectedCompanyData)];
          }else{
              this.filteredCompanyList=[];
          }
           }
      }, (err) => {
        this.loadingOrgInfo = false;
        console.log(err);
      })
  }

 
  goToOrderDetails(orderId, customerId) {
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop
      this.scrollService.setScrollPosOrderSearch(getPos);
      this.router.navigate(['/orders/' + orderId + '/view'])
  }
  }

  setScrollResetPos() {
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosOrderSearch(x);
  }


  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  handleEvent(event) {
    if (this.orders != 0) {
      if (event == false) {
        if (this.totalCount > this.config.getNumRecordsPerPage()) {
          if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
          else this.scrollEnable = true;
        }
      } else
        this.scrollEnable = true;
    }
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  onScrollData() {
    if (this.orders != 0) {
      if (this.filterModel.pageNum < this.pager.totalPages) {
        this.filterModel.pageNum++;
        let promiseCollection = [];
        promiseCollection.push(this.orderService.searchOrder(this.filterModel))
        promiseCollection.push(this.orderService.searchOrderCount(this.filterModel))
        observableForkJoin(promiseCollection)
          .subscribe((orders: any) => {
            if (orders[1] && orders[1].count) {
              this.totalCount = orders[1].count;
              console.log("totalcount:",this.totalCount);
              if (this.totalCount > this.orders.length) {
                this.orders = this.orders.concat(orders[0].rows);
                this.pager = this.pagerService.getPageOrderSearchandComplete(this.totalCount, this.filterModel.pageNum);

              }
            }
          })
      }
    }
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }

  getAdminOrganizations() {
    this.orgService.addedOrganizationList(this.auth.getUserId())
      .subscribe((orgs) => {
        if (orgs) { this.orgs = orgs; }
      }, (err) => {
        console.log("err", err);
      })
  }

  getOrganizationalClients() {
    this.userService.getAllCustomers()
      .subscribe((clients) => {
        if (clients) {
          this.clients = clients;
        }
      }, (err) => {
        console.log(err);
      })
  }

  openTagMenu(event, order){
    event.stopPropagation();
    this.assignPreselectedTags(order);
  }

  openNewTagModal(orderId){
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID : orderId }
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if(res){
        this.searchOrder();
        this.getAllActiveTags();
        // this.getAvailableTagLocal();
      }
    });
  }

  assignPreselectedTags(order){
    if(order.Tag_Names) this.associatedTags = order.Tag_Names
  }

  getAvailableTags(order){
    let preselectedTags = [];
    let availableTags = [];
    if(order.Tag_Names) preselectedTags = order.Tag_Names;
    for (var i = 0, len = this.allActiveTags.length; i < len; i++) {
      if(preselectedTags.findIndex((select => this.allActiveTags[i].Tag_ID == select.Tag_ID)) == -1) {
        if(this.allActiveTags[i].Tag_ID != this.newClientTagId && this.allActiveTags[i].Tag_ID != this.priorityClientTagId 
          && this.allActiveTags[i].Tag_ID != this.firstOrdTagId && this.allActiveTags[i].Tag_ID != this.prePaidTagId
          && this.allActiveTags[i].Tag_ID != this.config.getAwaitDlvryTagId() && this.allActiveTags[i].Tag_ID != this.config.getAwaitPartDlvryTagId()) 
          availableTags.push(this.allActiveTags[i]);
      }
    }
    return availableTags;
  }

  menuClosed(){    
    this.searchTag ='';
  }

  menuOpened(order){   
    this.searchTag ='';
    this.associatedTags = [];    
    this.orderTags = [];
    this.getAvailableTags(order).map((tag) => tag.on = false)
  }

  updateOrderTags(order, associatedTags){
    this.setScrollResetPos();
    this.orderService.updateOrderTags({ Order_ID: order.Order_ID, Order_Tags: this.orderTags.concat(associatedTags)}).subscribe(() => {    
      this.matMenuTrigger.closeMenu();
      this.menuClosed();
      this.searchOrder();
    })
  }

  checkIfAlreadyCompleted(orderStatusDet){
    let completedOrdIndex = orderStatusDet.findIndex((data) => data.Status_New === CONSTANTS.orderStatus.completed)
    if(completedOrdIndex > -1) return true;
    else return false;
  }

  modifyOrderTags(event, tag, order) {
    if(tag && tag.Tag_ID ==  this.config.getPartiallyDeliveredTagId()){      
      this.orderService.getAllOrderStatus(order.Order_ID)
      .subscribe((orderStatusDet) => {
        if(this.checkIfAlreadyCompleted(orderStatusDet)) this.modifyOrderTagsRequest(event, tag, order);
        else {
          this.openErrorPopup('This tag can only be assigned after an order has been completed at least once.');
          event.source.checked = false;
          this.matMenuTrigger.closeMenu();
        }
      })      
    }
    else this.modifyOrderTagsRequest(event, tag, order);    
  }

  modifyOrderTagsRequest(event, tag, order){
     var isTagToAdd:boolean;
     if(event.checked) isTagToAdd = true;
     else isTagToAdd = false;
      // this.setScrollResetPos();
      this.orderService.modifyOrderTags({ Order_ID: order.Order_ID, Order_Tag: tag, isTagToAdd: isTagToAdd})
      .subscribe((orderTagdata) => {
        order.Tag_Names=orderTagdata.Tag_Names;
        order.Order_Tags=orderTagdata.Order_Tags;
        this.menuClosed();
        this.searchOrder();
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderTagging);
        this.matMenuTrigger.closeMenu();
    })
   }

  clearSearchTag(){
    this.searchTag = '';
  }

  onSelectedOptionsChange(order) {
    this.timer.push(setTimeout(() => {
      this.orderTags = this.getAvailableTags(order).filter(tag => tag.on);
    }))
  }

  csvExport() {

    const fields = [
      {
        label: 'Sl No',
        value: 'serialNumber'
      },
      {
        label: 'Pippin Order Number',
        value: 'Order_ID'
      },
      {
        label: 'Client Reference',
        value: 'File_ID'
      },
      {
        label: 'Client',
        value: (row, User_First_Name) => {
            if (row.Organization_Name) return row.User_First_Name + ' ' + row.User_Last_Name + ' - ' + row.Organization_Name;
            else return row.User_First_Name + ' ' + row.User_Last_Name;   
        }
      },
      {
        label: 'Qualia Order',
        value: 'qualiaOrder'
      },
      {
        label : 'Quote',
        value : (row, quote) =>{
          if(row.Order_Is_Quote == '0') return 'No'
          else return 'Yes'
        }
      },
      {
        label: 'Owner/Seller',
        value: 'Property_First_Name'
      },
      {
        label: 'Property Address',
        value: (row, Property_Address_1) => {
          var completeAddress = '';
          if (row.Property_Address_1) completeAddress += row.Property_Address_1
          else return completeAddress;
          if (row.Property_Address_2) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_Address_2
          }
          if (row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_City + ', ' + row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          if (!row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          return completeAddress;
        }
      },
      {
        label: 'State',
        value: 'Property_State_Abbr'
      },
      {
        label: 'County',
        value: 'Property_County'
      },
      {
        label: 'Status',
        value: (row, Order_Status) => {
          if (row.Order_Status == CONSTANTS.orderStatus.received) {
            return 'Received';
          } else if (row.Order_Status == CONSTANTS.orderStatus.confirmed) {
            return 'Confirmed';
          } else if (row.Order_Status == CONSTANTS.orderStatus.assigned) {
            return 'Assigned';
          } else if (row.Order_Status == CONSTANTS.orderStatus.cancelled) {
            return 'Cancelled';
          } else if (row.Order_Status == CONSTANTS.orderStatus.processing) {
            return 'Processing';
          } else if (row.Order_Status == CONSTANTS.orderStatus.completed) {
            return 'Completed';
          } else if (row.Order_Status == CONSTANTS.orderStatus.quotecompleted) {
          return 'Completed';
        }
        }
      },
      {
        label: 'Product Type',
        value: 'Product_Description'
      },
      {
        label: 'Due Date',
        value: (row, Order_ETA) => {
          if(row.Order_ETA) return this._datePipe.transform(row.Order_ETA_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Due Time (ET)',
        value: (row, Order_ETA) => {
          if(row.Order_ETA) return this._datePipe.transform(row.Order_ETA_EST, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Submission Date',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'MMM d, yyyy');
        }
      },
      {
        label: 'Submission Time (ET)',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'h:mm a');
        }
      },
      {
        label: 'Original ETA Date',
        value: (row, Order_Original_ETA) => {
         if(row.Order_Original_ETA) return this._datePipe.transform(row.Order_Original_ETA_EST, 'MMM d, yyyy');
         else return '';
        }
      },
      {
        label: 'Original ETA Time (ET)',
        value: (row, Order_Original_ETA) => {
         if(row.Order_Original_ETA) return this._datePipe.transform(row.Order_Original_ETA_EST, 'h:mm a');
         else return '';
        }
      },
      {
        label: 'Completed Date',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Status == CONSTANTS.orderStatus.quotecompleted) return this._datePipe.transform(row.Order_Completion_Date_EST, 'MMM d, yyyy');
          if (row.Order_Status == CONSTANTS.orderStatus.completed) return this._datePipe.transform(row.Order_Completion_Date_EST, 'MMM d, yyyy');
          else if (row.Order_Status == CONSTANTS.orderStatus.cancelled) return this._datePipe.transform(row.Order_Cancellation_Date, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Completed Time (ET)',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Status == CONSTANTS.orderStatus.quotecompleted) return this._datePipe.transform(row.Order_Completion_Date_EST, 'h:mm a');
          if (row.Order_Status == CONSTANTS.orderStatus.completed) return this._datePipe.transform(row.Order_Completion_Date_EST, 'h:mm a');
          else if (row.Order_Status == CONSTANTS.orderStatus.cancelled) return this._datePipe.transform(row.Order_Cancellation_Date, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Handled By',
        value: (row, Order_Admin_ID) => {
          if(row.Order_Admin_ID) return `${row.Admin_User_First_Name} ${row.Admin_User_Last_Name}`;
          else return '';
        }
      },
      {
        label: 'Assigned Duration',
        value: (row, Order_Admin_ID) => {
            const duration = this.orderService.getAssignedDuration(row);
            return duration.replace(/[\(\)]/g, ''); // Remove parentheses
        }
      },
      {
        label: 'Searcher',
        value: (row, Order_Admin_ID) => {
          if (row.Order_Admin_ID && row.Abstrator_ID) return 'Abstractor';
          else if (row.Order_Admin_ID) return 'Pippin';
          else return '';
        }
      },
      {
        label: 'Search Master Type',
        value: (row, Order_Admin_ID) => {
          if(row.Order_Admin_ID) {
            if(row.Admin_User_Role == CONSTANTS.userRole.searchMaster){
              if(row.Admin_User_Type == CONSTANTS.userType.internalSM || row.Admin_User_Type == null) return "Internal";
              else if(row.Admin_User_Type == CONSTANTS.userType.externalSM) return "External"
            }
          } else return "";
        }
      },
      {
        label: 'Assigned Abstractor',
        value: (row, Abstrator_ID) => {
          if(row.Order_Admin_ID && row.Abstrator_ID) return row.Abstractor_User_First_Name;
          else return '';
        }
      },
      {
        label: 'Priority Order',
        value: (row, Order_Creation_Date) => {
          if(row.Order_Priority == CONSTANTS.orderPriority.level2) return 'Yes';
          else return '';
        }
      },
      {
        label: 'Current Task Due Date',
        value: (row, Order_Internal_Due_Time) => {
          if (row.Order_Internal_Due_Time) return this._datePipe.transform(row.Order_Internal_Due_Time_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Current Task Time (ET)',
        value: (row, Order_Internal_Due_Time) => {
          if (row.Order_Internal_Due_Time) return this._datePipe.transform(row.Order_Internal_Due_Time_EST, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Tags',
        value: 'Order_Tag_Name'
      },
      {
        label : 'Latest Note',
        value: (row, Latest_Note) => {
          if (row.Latest_Note) return row.Latest_Note;
          else return '';
        },
      }
    ];
    if(this.checkForSuperRole()){
      fields.push({
        label: 'Price',
        value: (row, Order_Custom_Price) => {
          if(row.Order_Custom_Price) return row.Order_Custom_Price;
          else return '';
        }
      })
    }
    if(this.checkIfSearchMaster()){
      var costIndex = fields.findIndex(x => x.label ==="Price");
      if(costIndex > -1) fields.splice(costIndex, 1);
    }

    this.orderService.downloadCSVfile(fields, this.orders, this.allActiveTags)
  }

  checkDaylight(date){
    if(momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  trackOrders(order){
    return order.Order_ID;
  }
  
  adminParams(){
    window.history.replaceState({}, "Hide", '/orders/search');
    this.getParamsForFlag(this.activatedRoute.snapshot.queryParams['Completed_Type'], 'admin')
    this.searchOrdersOnClick()
  }

  abstractorParams(){
    window.history.replaceState({}, "Hide", '/orders/search');
    this.getParamsForFlag(this.activatedRoute.snapshot.queryParams['Completed_Type'], 'abstractor')
    this.searchOrdersOnClick()
  }

  getFontColor(hexColor){
    return this.tagService.getFontColor(hexColor)  
  }

  getParamsForFlag(flag, userRoleType){
    let numFlag = Number(flag)
    this.resetFields();
    if(userRoleType == 'admin') {
      this.activatedRoute.queryParams.subscribe((params) => {
        if(this.auth.isSearchManager()) {
          let adminIdArray = JSON.parse(params.Admin_User_ID);  
          let adminId = adminIdArray[0]; 
          this.filterModel.Sel_Order_Admin_ID = adminId;  
        } else  {
        this.filterModel.Sel_Admin_User_ID = JSON.parse(params.Admin_User_ID)
        }
        })
    }else {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.filterModel.Sel_Abs_User_ID = JSON.parse(params.Abs_User_ID)
        })
    }
    if(numFlag == 65) this.filterModel.Order_Status = [ 
      this.constants.orderStatus.received.toString(),
      this.constants.orderStatus.assigned.toString(), 
      this.constants.orderStatus.confirmed.toString(), 
      this.constants.orderStatus.processing.toString(),
      this.constants.orderStatus.quotecompleted.toString()
    ];
    else if(numFlag) this.filterModel.Order_Status = [this.constants.orderStatus.completed.toString()];
    else{
      this.filterModel.Order_Status = [
        this.constants.orderStatus.received.toString(), 
        this.constants.orderStatus.assigned.toString(), 
        this.constants.orderStatus.confirmed.toString(), 
        this.constants.orderStatus.processing.toString(),
        this.constants.orderStatus.cancelled.toString(),
        this.constants.orderStatus.quotecompleted.toString()
      ];
    }
    this.filterModel.Admin_ID = this.auth.getUserId();
  }

  checkIfNotExternalSM(){
    if(Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.externalSM) return false;
    else return true;
  }

  orderAssignee(ordDetails) {
    var data = {};
    if (ordDetails.Organization_ID) data['Organization_ID'] = ordDetails.Organization_ID;
    this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
           var dataToDialog = {
          Order_ETA: ordDetails.Order_ETA,
          admins: adminData['admins'],
          Search_Masters: adminData['searchers'],
          Order_ID: ordDetails.Order_ID,
          Customer_ID: ordDetails.Customer_ID,
          Order_Status: ordDetails.Order_Status
        };
        if (ordDetails.Order_Admin_ID) dataToDialog['Current_Admin'] = ordDetails.Order_Admin_ID;
        let disposable = this.matDialog.open(AssignComponent, {data:{
          title: 'Assign order',
          message: dataToDialog,
          listOfHolidays:this.holidayList
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }
              if(res.admin && res.getTimeToCompl){
                if (ordDetails.Order_Admin_ID) {
                  // if(this.isETAChanged(res.Order_ETA, ordDetails) && ordDetails.Order_Status >= CONSTANTS.orderStatus.confirmed) {
                  //   this.openETAChangeMsgPopup(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range)
                  // } else {
                  //   this.changeOrderAssignee(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range, '', '')
                  // }
                   this.changeOrderAssignee(
                    ordDetails.Order_ID, 
                    res.admin, res.Order_ETA, 
                    res.msgText, msgType, 
                    res.getTimeToCompl, 
                    res.Order_ETA_Range, 
                    res.Order_ETA_Comment ? res.Order_ETA_Comment : '',
                    res.Order_ETA_Change_Reason_Type_ID ? res.Order_ETA_Change_Reason_Type_ID : '',
                    res.hasOwnProperty('Notify_Customer') ? res.Notify_Customer : null,
                    res.Client_Notif_Status ? res.Client_Notif_Status : null,
                    res.Email_Body ? res.Email_Body : null,
                  )
                } else {
                  // if(this.isETAChanged(res.Order_ETA, ordDetails) && ordDetails.Order_Status >= CONSTANTS.orderStatus.confirmed) {
                  //   this.openETAChangeMsgInterface(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range)
                  // } else {
                  //   this.assignOrder(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range, '', '')
                  // }
                  this.assignOrder(
                    ordDetails.Order_ID, 
                    res.admin, 
                    res.Order_ETA, 
                    res.msgText, 
                    msgType, 
                    res.getTimeToCompl, 
                    res.Order_ETA_Range, 
                    res.Order_ETA_Comment ? res.Order_ETA_Comment : '',
                    res.Order_ETA_Change_Reason_Type_ID ? res.Order_ETA_Change_Reason_Type_ID : '',
                    res.hasOwnProperty('Notify_Customer') ? res.Notify_Customer : null,
                    res.Client_Notif_Status ? res.Client_Notif_Status : null,
                    res.Email_Body ? res.Email_Body : null,
                  )
                }
              }
              else{
                  this.orderService.unassignOrder(ordDetails.Order_ID,res.msgText,msgType,ordDetails.Order_Status).subscribe((data)=>{
                    this.searchOrder();
                  }, (err) => {
                    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
              }
            }
          });
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })
  }

  isETAChanged(eta, ordDetails) {   
    var oldETA;
    var newETA;
    if(ordDetails.Order_ETA) { 
      let estShift = momentTimeZone.tz(ordDetails.Order_ETA, 'America/New_York').isDST() ? 4 : 5;
      oldETA = momentTimeZone.tz(ordDetails.Order_ETA,'America/New_York').add(estShift, 'hours').format('MM-DD-YYYYTHH:mm');
    } else oldETA = null;
    if(eta) newETA = momentTimeZone.tz(eta, 'America/New_York').format('MM-DD-YYYYTHH:mm');
    else newETA = null;
    if(oldETA != newETA) return true;
    else return false;
  }

  // openETAChangeMsgPopup(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange) {
  //   let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
  //   }, ...{ closeByClickingOutside: false }}).afterClosed()
  //     .subscribe((res) => {
  //       if(res.state) { 
  //         this.changeOrderAssignee(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, res.Message_Text, res.Order_ETA_Change_Reason_Type_ID);
  //       }
  //     });
  // }

  changeOrderAssignee(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID, notifCust,
    clinNotifSts,
    emailbody) {
    let additionalInfo = {
      Notify_Customer: notifCust,
      Client_Notif_Status: clinNotifSts,
      Email_Body: emailbody,
    }
    this.orderService.changeOrderAssign(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, additionalInfo, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID)
    .subscribe((data) => {
      this.searchOrder();
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
    })
  }

  // openETAChangeMsgInterface(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange) {
  //   let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
  //   }, ...{ closeByClickingOutside: false }}).afterClosed()
  //     .subscribe((res) => {
  //       if(res.state) { 
  //         this.assignOrder(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, res.Message_Text, res.Order_ETA_Change_Reason_Type_ID);
  //       }
  //     });
  // }

  assignOrder(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID,notifCust,
    clinNotifSts,
    emailbody) {
    let additionalInfo = {
      Notify_Customer: notifCust,
      Client_Notif_Status: clinNotifSts,
      Email_Body: emailbody,
    }
    this.orderService.initOrderProcess(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID, additionalInfo)
      .subscribe((data) => {
        this.searchOrder();
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })
  }

  changeScreener(ordDetails){
    var data = {};
    if (ordDetails.Organization_ID) data['Organization_ID'] = ordDetails.Organization_ID;
      this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
        var dataToDialog = {
          Order_ETA: ordDetails.Order_ETA,
          admins: adminData['admins'],
          Search_Masters: adminData['searchers'],
        };
        if (ordDetails.Order_Admin_ID) dataToDialog['Current_Admin'] = ordDetails.Order_Admin_ID;
        let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
          title: 'Screen order',
          message: dataToDialog
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }
              if(res.admin && res.getTimeToCompl){
                if (ordDetails.Order_Admin_ID) {
                  this.orderService.changeScreener(ordDetails.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
                    .subscribe((data) => {
                      this.searchOrder();
                    }, (err) => {
                      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                    })
                } else {               
                    this.orderService.screenOrder(ordDetails.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
                    .subscribe((data) => {
                      this.searchOrder();
                    }, (err) => {
                      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                    })
                }
              }
              else{
                  this.orderService.unassignOrder(ordDetails.Order_ID,res.msgText,msgType,ordDetails.Order_Status).subscribe((data)=>{
                    this.searchOrder();
                  }, (err) => {
                    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
              }
            }
          });
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })    
  }

  modifyAssignee(ordDetails){
    if(ordDetails.Order_Status < this.constants.orderStatus.confirmed) this.changeScreener(ordDetails);
    else  this.orderAssignee(ordDetails);
  }


quoteAssignee(order){
  var data = {};
  if(order.Organization_ID) data['Organization_ID'] = order.Organization_ID;
    this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
      let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
        title: 'Assign Quote',
        message: {
          admins: adminData['admins'],
          Search_Masters: adminData['searchers'],
          Current_Admin: order.Order_Admin_ID
        }
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          if (res) {
            let msgType;
            if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
            else {
              res.msgText = ""
              msgType = CONSTANTS.messageType.internalNotes;
            }
            if(res.admin && res.getTimeToCompl){
              this.orderService.changeQuoteAssignee(order.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
              .subscribe((data) => {
                this.searchOrder();
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
            }
            else {
              this.orderService.unassignOrder(order.Order_ID ,res.msgText,msgType, order.Order_Status).subscribe((data)=>{  
                this.searchOrder();              
              }, (err) => {
                this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
            })
            }
          }          
    }, (err) => { console.log(err); })
  })
}


  selectAllStatus(){    
    this.filterModel.Order_Status = [];
    this.orderStatusList.forEach((statusItem) => { this.filterModel.Order_Status.push(statusItem.value) });    
  } 
  resetDataFilter(userType) {
    if(userType == 'admin') {
      if(this.filterModel.Sel_Searcher_User_ID ) this.filterModel.Sel_Searcher_User_ID = '';
    }else if(this.filterModel.Sel_Admin_User_ID) this.filterModel.Sel_Admin_User_ID = '';
  }

  cleardate(id){
   if(id == 'fromDate') {
     this.filterModel['fromDate'] = null;
      if (this.errors['Date_Range']) delete this.errors['Date_Range'];
    }
    if(id == 'toDate') {
      this.filterModel['toDate'] = null;
      if (this.errors['Date_Range']) delete this.errors['Date_Range'];
    }
    if(id == 'fromETADate') {
      this.filterModel['fromETADate'] = null;
      if (this.etaDateErrors['Date_Range']) delete this.etaDateErrors['Date_Range'];      
    }
    if(id == 'toETADate') {
      this.filterModel['toETADate'] = null;
      if (this.etaDateErrors['Date_Range']) delete this.etaDateErrors['Date_Range'];
    }
  }

  clearFilter(id) {
    if(id == 'fromCompletedDate') {
      this.filterModel['fromCompletedDate'] = null;
      this.compFromTime = "00:00" ; 
      this.filterModel['fromCompleted_DateTime'] = null;
      this.CompletedDateFrom = false;
    }
    if(id == 'toCompletedDate') { 
      this.filterModel['toCompletedDate'] = null;
      this.compToTime = "23:59" ; 
      this.filterModel['toCompleted_DateTime'] = null; 
      this.CompletedDateTo = false;
    }
    if (this.compDateErrors['Date_Range']) delete this.compDateErrors['Date_Range'];   
    if(id == 'fromDate') {
      this.filterModel['fromDate'] = null;
      this.createdFromTime = "00:00" ; 
      this.filterModel['fromCreated_DateTime'] = null;
      this.CreatedDateFrom = false;
    }
    if(id == 'toDate') { 
      this.filterModel['toDate'] = null;
      this.createdToTime = "23:59" ; 
      this.filterModel['toCreated_DateTime'] = null; 
      this.CreatedDateTo = false;
    }
    if(id == 'fromETADate') {
      this.filterModel['fromETADate'] = null;
      this.ordETAFromTime = "00:00" ; 
      this.filterModel['fromOrdETA_DateTime'] = null;
      this.OrderETADateFrom = false;
    }
    if(id == 'toETADate') { 
      this.filterModel['toETADate'] = null;
      this.ordETAToTime = "23:59" ; 
      this.filterModel['toOrdETA_DateTime'] = null;
      this.OrderETADateTo = false; 
    }
    if (this.etaDateErrors['Date_Range']) delete this.etaDateErrors['Date_Range']; 
    if (this.compDateErrors['Date_Range']) delete this.compDateErrors['Date_Range']; 
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }

    getFullAddress(order){
    let completeAddress = '';
    if (order.Property_Address_1) completeAddress += order.Property_Address_1
    if (order.Property_Address_2) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_Address_2
    }
    if (order.Property_City) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += order.Property_City
    }
    if (order.Property_State_Abbr) {
      if (completeAddress) completeAddress += ', '
      completeAddress += order.Property_State_Abbr
    }
    if (order.Property_ZipCode) {
      if (completeAddress) completeAddress += ' '
      return completeAddress += order.Property_ZipCode
    }
  }
  
  getClientFullName(order) {
    if(order.Organization_Name) return order.User_First_Name +" "+ order.User_Last_Name + " - "  +order.Organization_Name;
    else return order.User_First_Name +" "+ order.User_Last_Name 
  }
 
  getAbstractorFullName(order) {
  return order.Abstractor_User_First_Name;
  }

  getAdminFullName(order) {
    return this.firstLetterCaps(order.Admin_User_First_Name) + " "+ this.firstLetterCaps(order.Admin_User_Last_Name)
  }

  firstLetterCaps(data){
    if (typeof data !== 'string') return ''
    return data.charAt(0).toUpperCase() + data.slice(1)
  }

  getAllProductList(){
    this.dropdownService.getProductOptions()
    .subscribe((data) => {
      this.loadingProdInfo = false;  
      this.products = data;
    }, (err) => {
    this.loadingProdInfo = false;
    this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
    })
  }

  getholidaylistFromLocal(){
    let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
    if(allHolidays) this.setHoliday(allHolidays)
    else this.getholidaylist();
  }

  setHoliday(holiday){
    this.holidayList = holiday
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      if(holidays){
        this.setHoliday(holidays)
        sessionStorage.setItem('holidays', JSON.stringify(holidays));
     setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);
      }
      // this.holidayList = holidays;
    }, (err) =>{
      console.log("Error",err)
    })
  }

  //** Below code if for quote filter in order_status dropdown */

  goToQuoteDetails(quoteId) {
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop
      this.scrollService.setScrollPosQuotereq(getPos);
      this.router.navigate(['/quotes/'+ quoteId + '/view'])
    }
  }



  checkForQuote(order){
      if(!Number(order.Order_Is_Quote)) return true;
      else return false
  } 

   navigateToDetails(order){
      if( this.checkForQuote(order)) this.goToOrderDetails(order.Order_ID,order.Customer_ID)
      else this.goToQuoteDetails(order.Order_ID)    
  }

  navigateToOrderPage(order) {
    if(this.isCompAdmin() || !order.Organization_ID) this.navigateToDetails(order)
    else {
      this.orderService.checkOrderTags(order.Order_ID, this.auth.getUserId())
        .subscribe((status) => {
          if (!status.approved) {
            let entity = 'quote';
            if( this.checkForQuote(order)) entity = 'order';
            this.openDenyPermission(entity);
          }
          else this.navigateToDetails(order)
        },
          (err) => {
            this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          });
      }
  }

  isCompAdmin() {
    let role = Number(this.auth.getUserRole());
    if(role == CONSTANTS.userRole.superAdmin || role == CONSTANTS.userRole.compAdmin) return true;
    else return false;
  }

  openDenyPermission(entity) {
    this.matDialog.open(
      SuccessComponent,
      {data:{
        message: "You do not have sufficient permissions to view this "+entity+". Please get in touch with your team lead to obtain access to this "+entity+".",
        title: "Access denied"
      },
      ...this.config.getDialogOptions()}
    )
   
  }

  openModal(title, msg) {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  getCreatedDateFrom(event) {
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.createdDateFromInvalid = true;
    else this.createdDateFromInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
        this.filterModel['fromDate'] = event.Date_EST;
        this.createdFromTime = event.Time_EST
        this.filterModel['fromCreated_DateTime'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
        this.CreatedDateFrom=false;
      }else this.CreatedDateFrom=true;
    }else this.CreatedDateFrom=true;
    this.validateCreateDateRangeFilter();
  }

  getCreatedDateTo(event) {
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.createdDateToInvalid = true;    
    else this.createdDateToInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
      this.filterModel['toDate'] = event.Date_EST;
      this.createdToTime = event.Time_EST;
      this.filterModel['toCreated_DateTime'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
      this.CreatedDateTo=false;
    }else this.CreatedDateTo=true;
    }else this.CreatedDateTo=true;
    this.validateCreateDateRangeFilter();    
  }

  getOrderETADateFrom(event){
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.ordETADateFromInvalid = true;    
    else this.ordETADateFromInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
        this.filterModel['fromETADate'] = event.Date_EST;
        this.ordETAFromTime = event.Time_EST;
        this.filterModel['fromOrdETA_DateTime'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
        this.OrderETADateFrom=false;
      }else this.OrderETADateFrom=true;
    }else this.OrderETADateFrom=true;
    this.validateOrdETADateRangeFilter();    
  }

  getOrderETADateTo(event){
    if(event && event.timeCtrlForm && !event.timeCtrlForm.valid) this.ordETADateToInvalid = true;    
    else this.ordETADateToInvalid = false;
    if(event && event.Date_EST && event.Time_EST) {
      if(event.Date_EST._d.getFullYear() >= '1500'){
      this.filterModel['toETADate'] = event.Date_EST;
      this.ordETAToTime = event.Time_EST;
      this.filterModel['toOrdETA_DateTime'] = this.setTimeToDate(event.Date_EST, event.Time_EST)
      this.OrderETADateTo=false;
      }else this.OrderETADateTo=true;
    }else this.OrderETADateTo=true;
    this.validateOrdETADateRangeFilter();    
  }

  validateCreateDateRangeFilter() {
    if (this.filterModel['fromDate'] && this.filterModel['toDate'] && (this.filterModel['fromDate'] > this.filterModel['toDate'])) {
      this.errors['Date_Range'] = true;
    } else {
      delete this.errors['Date_Range'];
    }
  }

  validateOrdETADateRangeFilter() {
    if (this.filterModel['fromETADate'] && this.filterModel['toETADate'] && (this.filterModel['fromETADate'] > this.filterModel['toETADate'])) {
      this.compDateErrors['Date_Range'] = true;
    } else {
      delete this.compDateErrors['Date_Range'];
    }
  }

  isSuperAdmin(){
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }

  validateDate(){
    if(this.CreatedDateFrom || this.CreatedDateTo || this.CompletedDateFrom || this.CompletedDateTo || this.OrderETADateFrom || this.OrderETADateTo) return true
    else return false;
  }

  getOrderSourceTypes(){
    this.orderService.getOrderSourceList()
    .subscribe((list) => {
      this.orderSources = list;
    }, (err) => {
      this.openErrorPopup(err.msg);
    })
  }
  
  checkForSuperRole(){
    if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) return true;
    else return false;
  }
  
  isSeaRchManager() {
    if(this.auth.isSearchManager()) return true
    else return false;
  }

  getSearchManagerAndItsTeam() {
    if(this.auth.isSearchManager()) {
    this.userService.getSearchManagerAndItsTeam(this.auth.getUserId())
      .subscribe((result) => {
        if (result) {
          this.searchManagerTeam = result;
        }
      }, (err) => {
        console.log("err", err);
      })
    }
  }


}
