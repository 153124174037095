import { ChangeDetectorRef, Component, forwardRef, Input, isSignal, Output, SimpleChanges } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-ai-dropdown-field",
  templateUrl: "./ai-dropdown-field.component.html",
  styleUrl: "./ai-dropdown-field.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AiDropdownFieldComponent),
      multi: true,
    },
  ],
})
export class AiDropdownFieldComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() status: string;
  @Input() formControl: FormControl;
  @Input() options: any[];
  @Input() field: string;
  @Input() modified: boolean = false;
  @Input() maxLength: string = null;
  @Input() errorMessages: { [key: string]: string } = {};
  @Input() onValueChange!: (newValue: string, field: string, status:string) => Promise<void>;
  @Input() onStatusChange!: (field: string) => Promise<void>;
  @Input() value: any;

  private valueChangesSub: Subscription;
  private onChangeCallback: (value: any) => void = () => {};
  private onTouchedCallback: () => void = () => {};
  private skipNextValueChange = false;
  private lastValue: any = undefined;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.formControl) {
      this.formControl = new FormControl();
    }

    this.valueChangesSub = this.formControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(0)
      )
      .subscribe(async (newValue) => {
        if (this.skipNextValueChange) {
          this.skipNextValueChange = false;
          return;
        }

        if (newValue !== this.lastValue) {
          this.lastValue = newValue;
          this.onChangeCallback(newValue);
          
          if (this.formControl.dirty && this.onValueChange) {
            try {
              await this.onValueChange(newValue, this.field, this.status);
            } catch (error) {
              console.error('Error in value change:', error);
            }
          }
        }
      });
    this.updateValueFromInput(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.updateValueFromInput(changes['value'].currentValue);
    }
  }

  private updateValueFromInput(inputValue: any): void {
    if (inputValue === undefined || inputValue === null) return;

    const value = isSignal(inputValue) ? inputValue() : inputValue;
    
    if (value !== this.lastValue) {
      this.lastValue = value;
      this.setValueSafely(value);
    }
  }

  private setValueSafely(value: any): void {
    if (!this.formControl || value === undefined) {
      return;
    }

    try {
      this.skipNextValueChange = true;
      this.formControl.setValue(value, { emitEvent: false });
    
      Promise.resolve().then(() => {
        this.cdr.detectChanges();
        this.skipNextValueChange = false;
      });
    } catch (error) {
      console.error('Error setting value:', error);
      this.skipNextValueChange = false;
    }
  }

  ngOnDestroy(): void {
    if (this.valueChangesSub) {
      this.valueChangesSub.unsubscribe();
    }
  }

  async onButtonClick(): Promise<void> {
    if (!this.onStatusChange) {
      console.error("Status change callback is not provided");
      return;
    }

    try {
      await this.onStatusChange(this.field);
    } catch (error) {
      console.error('Error in status change:', error);
    }
  }

  onDropdownInteraction(): void {
    this.status = "edited";
    this.onTouchedCallback();
  }

  writeValue(value: any): void {
    this.updateValueFromInput(value);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.formControl) {
      isDisabled ? this.formControl.disable() : this.formControl.enable();
    }
  }}
