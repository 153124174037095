import { Component, Inject, ViewChild } from '@angular/core';
import { OrdersService, ConfigService } from '../../services';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';


@Component({
  selector: 'app-review-complete-quote',
  templateUrl: './review-complete-quote.component.html',
  styleUrl: './review-complete-quote.component.scss'
})
export class ReviewCompleteQuoteComponent {
  title = '';
  message: any;
  notifyEmailToClient: boolean = true;
  client_mail_text: SafeHtml = "";
  errorMsg: string = "";
  timer: any = [];
  tinyOptions: any = {
    auto_focus: false,
    branding:false,
    promotion :false,
    skin:'oxide',
    menubar:false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"],
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
  };
  @ViewChild('tinymce') tinymce;


  constructor(
    private ordersService: OrdersService,
       private config: ConfigService,
       private matDialog:MatDialog,
       private sanitizer: DomSanitizer,
       public dialogRef: MatDialogRef<ReviewCompleteQuoteComponent>,
       @Inject(MAT_DIALOG_DATA) public data: any) {
    
  }

  ngOnInit() {
    this.initDialog();
    this.timer.push(setTimeout(() => {  
      this.constructMessage(this.data.quoteDetails,this.data.orderInstructions);
     }, 0))    

  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  constructMessage(data, notes) {
    let quoteDetails = this.constructQuoteDetails(data, notes)
    this.client_mail_text = '<b style="color:#45B25D;">Your request for a quote from Pippin Title has been completed.</b>'
    + '<br><br>'
    + '<b>The price to process this request is $' +this.data.Order_Price+ ', and can be delivered in full within '+this.data.Order_Estimated_Time+'.</b>'
    + '<br><br>'
    + 'Comments:<br>'
    +  this.data.comments
    + '<br><br>'
    + 'Your quote details are:'
    + '<br><br>'
    + ''+quoteDetails+''
    + '<br>'
    + 'If you would like to view your quote, make any changes, or communicate with us about the quote, please click the link below:'
    + '<br><br>'
    + '<a href="' + ''+this.config.getClientHomePath()+'' + '/quote/'+this.data.quoteDetails.Order_ID+'/view">Click here to view your quote on our portal.</a>'
    + '<br><br>'    
    + 'Thank you for using Pippin Title.'
    + '<br><br>';
  }

  constructQuoteDetails(data, notes=null) {
    var propertyAdd = this.buildAddress(data);
    var compOrderDetails = ''
    if (data.Order_ID) compOrderDetails += 'QUOTE ID: ' + data.Order_ID + '<br>'
    if (data.File_ID) compOrderDetails += 'Client Reference: ' + data.File_ID + '<br>'
    // if (data.Product_Description) compOrderDetails += 'Product Type: ' + data.Product_Description + '<br>'
    if (data.Order_Customer_File_Number) compOrderDetails += 'Customer File Number: ' + data.Order_Customer_File_Number + '<br>'
    if (data.Order_Purpose) compOrderDetails += 'Purpose: ' + data.Order_Purpose + '<br>'
    if (data.Order_Requested_Date) {
      let reqDate = momentTimeZone.tz(data.Order_Requested_Date, 'America/New_York').format('MMMM DD, YYYY');
      compOrderDetails += 'Requested Date: ' + reqDate + '<br>'
    }
  
    if(propertyAdd || data.Order_Subdivision) compOrderDetails += '<br>'
    if (propertyAdd) compOrderDetails += 'Property Address: ' + propertyAdd + '<br>'
    if (data.Order_Subdivision) compOrderDetails += 'Subdivision: ' + data.Order_Subdivision + '<br>'
  
    if(data.Order_Buyer || data.Order_Co_Buyer) compOrderDetails += '<br>'
    if (data.Order_Buyer) compOrderDetails += 'BUYER: ' + data.Order_Buyer + '<br>'
    if (data.Order_Co_Buyer) compOrderDetails += 'CO-BUYER: ' + data.Order_Co_Buyer + '<br>'
  
    if(data.Order_Mortgage_Amount || data.Order_Mortgage_Date) compOrderDetails += '<br>'
    if (data.Order_Mortgage_Amount) compOrderDetails += 'Mortgage Amount: $ ' + data.Order_Mortgage_Amount + '<br>'
    if (data.Order_Mortgage_Date) compOrderDetails += 'Mortgage Date: ' + moment(data.Order_Mortgage_Date).format('MMMM DD, YYYY') + '<br>'
  
    if(notes) {
      let searchIns='';
      let specialIns='';
      if (notes && notes.Search_Instructions) searchIns = notes.Search_Instructions.Instruction_Text ? notes.Search_Instructions.Instruction_Text:'';
      if (notes && notes.Special_Instructions) specialIns = notes.Special_Instructions.Instruction_Text ? notes.Special_Instructions.Instruction_Text:'';
      if(searchIns || specialIns) compOrderDetails += '<br>'
      if (searchIns) compOrderDetails += 'Search Instructions: ' + searchIns + '<br>'
      if(searchIns && specialIns) compOrderDetails += '<br>'
      if (specialIns) compOrderDetails += 'Special Instructions: ' + specialIns + '<br>'
    }
  
    return compOrderDetails
  }

  buildAddress(model) {
    var completeAddress = ''
    if (model.Property_Address_1) completeAddress += model.Property_Address_1
    if (model.Property_Address_2) {
      if (completeAddress) completeAddress += ' '
      completeAddress += model.Property_Address_2
    }
    if (model.Property_City) {
      if (completeAddress) completeAddress += ', '
      completeAddress += model.Property_City
    }
    if (model.Property_State_Abbr) {
      if (completeAddress) completeAddress += ', '
      completeAddress += model.Property_State_Abbr
    }
    if (model.Property_ZipCode) {
      if (completeAddress) completeAddress += ' '
      completeAddress += model.Property_ZipCode
    }
    return completeAddress
  }
  

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  cancel() {
    this.dialogRef.close({
      state: false
    })
    this.clearTime();
  }

  Close() {
    this.dialogRef.close(false);
    this.clearTime();
  }

  completeQuote() {
    this.errorMsg = "";
    this.dialogRef.close({
      state: true,
      mailBody: this.client_mail_text,
      notifyEmailToClient: this.notifyEmailToClient
    });

    this.clearTime();
  }


}
