import { forkJoin as observableForkJoin, Observable } from "rxjs";
import { Component, OnInit , OnDestroy} from "@angular/core";
import { Router } from "@angular/router";
import { CONSTANTS } from "app/app.constants";
import { ErrorDialogComponent } from "app/dialog/error-dialog/error-dialog.component";
import {
  PaiConfigService,
  AuthService,
  PagerService,
  ConfigService,
  ScrollService,
  StateService,
} from "app/services";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, UntypedFormBuilder } from "@angular/forms";

@Component({
  selector: 'app-pai-config-listing',
  templateUrl: './pai-config-listing.component.html',
  styleUrl: './pai-config-listing.component.scss'
})
export class PaiConfigListingComponent {
  constants = CONSTANTS;
  promptTemplates: any = [];
  promptTemplatesToDisplay: any;
  totalCount: number;
  pager: any = {};
  pageNum: number;
  scrollEnable: boolean = true;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  timer: any = [];
  defaultTemplate : any = {
    Template_Name : "Default",
    Template_Type: 2,
    Template_Content: "",
    Template_Status: 1
  }
  error: string = '';
  defaultTemplateForm: any;
  constructor(
    private auth: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    private pAIConfigService: PaiConfigService,
    private matDialog: MatDialog,
    private stateService: StateService,
    private scrollService: ScrollService,
    private frmBuilder: FormBuilder,
  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined) this.pageNum = 1;
    this.defaultTemplateForm = frmBuilder.group({
      Template_Content: [null],
    });
  }

  ngOnInit() {
    if (this.stateService.getPromptTemplates())
      this.initializeComponentData(this.stateService.getPromptTemplates());
    else this.getInitialPromptTemplates();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(38), 0));
    this.pAIConfigService.getDefaultpAIConfigTemplate()
    .subscribe((template) => {
      if(template) this.defaultTemplate = template;
    })
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  setDisplayPromptTemplates() {
    let start = (this.pageNum - 1) * this.config.getNumRecordsPerPage();
    this.promptTemplatesToDisplay = this.promptTemplates.slice(
      start,
      this.totalCount
    );
    if (
      this.promptTemplatesToDisplay.length > this.config.getNumRecordsPerPage()
    )
      this.promptTemplates = this.promptTemplates.slice(
        0,
        this.config.getNumRecordsPerPage()
      );
  }

  getPromptTemplates() {
    this.pageNum = 1;
    this.pAIConfigService.getpAIConfigTemplates(this.pageNum).subscribe(
      (result) => {
        if (result) {
          this.promptTemplates = result.rows;
          this.totalCount = result.count;
          this.setDisplayPromptTemplates();
          this.pager = this.pagerService.getPager(
            this.totalCount,
            this.pageNum
          );
        }
      },
      (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      }
    );
  }

  getInitialPromptTemplates() {
    if (this.pageNum == 1) {
      this.stateService.setPromptTemplates(this);
      this.pAIConfigService.getpAIConfigTemplates(this.pageNum).subscribe(
        (result1) => {
          if (result1.count > this.config.getNumRecordsPerPage()) {
            this.pageNum++;
            this.pAIConfigService
              .getpAIConfigTemplates(this.pageNum)
              .subscribe((result2) => {
                var result = {
                  rows: result1.rows.concat(result2.rows),
                  count: result1.count,
                };
                this.getPromptTemplateData(result);
              });
          } else {
            this.getPromptTemplateData(result1);
          }
        },
        (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        }
      );
    } else {
      this.getRetainData();
    }
  }

  getRetainData() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.pAIConfigService.getpAIConfigTemplates(i));
    }

    observableForkJoin(ObsCollection).subscribe((retainPromptTemplate) => {
      if (retainPromptTemplate) {
        var totalOrders = [];
        for (var i = 0; i < retainPromptTemplate.length; i++) {
          this.stateService.setPromptTemplates(this);
          totalOrders = totalOrders.concat(retainPromptTemplate[i]["rows"]);
        }
        this.promptTemplates = totalOrders;
        this.totalCount = retainPromptTemplate[0]["count"];
        this.getPromptTemplateRecordsData(this.totalCount);
        this.getScrollPosData();
      }
    });
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.getInitialPromptTemplates();
  }

  goToPromptTemplateDetails(templateId) {
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosPromptTemplates(getPos);
    this.router.navigate(["/pAI-config/" + templateId]);
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {
      data: {
        message: msg,
      },
      ...this.config.getDialogOptions(),
    });
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold())
          this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.pAIConfigService
        .getpAIConfigTemplates(this.pageNum)
        .subscribe((result) => {
          if (result) {
            this.totalCount = result.count;
            this.config.resetShowFlag();
            if (this.totalCount > this.promptTemplates.length) {
              this.promptTemplates = this.promptTemplates.concat(result.rows);
              this.pager = this.pagerService.getPager(
                this.totalCount,
                this.pageNum
              );
            }
          }
        });
    }
  }
  getPromptTemplateRecordsData(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayPromptTemplates();
    this.pager = this.pagerService.getPager(
      result,
      this.pageNum,
      this.config.getNumRecordsPerPage()
    );
    this.config.setDelayRecords();
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosPromptTemplates()) {
      var x = this.scrollService.getScrollPosPromptTemplates();
      this.timer.push(
        setTimeout(function () {
          window.scrollTo(0, x);
        })
      );
    }
  }

  getPromptTemplateData(result) {
    this.promptTemplates = result.rows;
    this.totalCount = result.count;
    this.getPromptTemplateRecordsData(this.totalCount);
    this.getScrollPosData();
  }

  confirmDefault(){
    this.error = '';
    //call manage defaultTemplate API
    this.pAIConfigService.manageDefaultpAIConfigTemplate(this.defaultTemplate)
    .subscribe(() => {
      this.pAIConfigService.getDefaultpAIConfigTemplate()
      .subscribe((template) => {
        if(template) this.defaultTemplate = template;
      })
    }, (err) => {
      this.error = 'Failed to update!'
    })

  }
}
