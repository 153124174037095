import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { AbstractorService } from '../../services/abstractor/abstractor.service'
import { SearchPropsPipe } from '../../pipes';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CONSTANTS} from '../../app.constants'

@Component({
  selector: 'app-abstractor-quote',
  templateUrl: './abstractor-quote.component.html',
  styleUrls: ['./abstractor-quote.component.scss']
})
export class AbstractorQuoteComponent{
  constants = CONSTANTS;
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef
  message = {};
  title = '';
  error = '';
  searchQuery: string = '';
  selectedAbstractors: any = [];
  Order_ID: number;
  listingType: string;
  timer: any = [];
  filterBy: any = [];
  offersCommercial: boolean = false;
  valid_EnO: boolean = true;


  constructor(public dialogRef: MatDialogRef<AbstractorQuoteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
   private abstractorService: AbstractorService) {
    this.title=this.data.title;
    this.message=this.data.message;
  }

  ngOnInit() {
    this.initDialog();
    this.message['Abstractors'].forEach(element => {
      element.checked = false;
    });
    this.listingType = 'cost';
    this.Order_ID = this.message['Order']['Order_ID'];
    this.timer.push(setTimeout(() => {
      this.myFirstFocus.nativeElement.focus()
    }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  createNew() {
    this.dialogRef.close(-1);
    // this.result = -1;
    this.clearTime();
  }

  confirm() {
    this.dialogRef.close({ selectedAbstractors: this.selectedAbstractors });
    // this.result = { selectedAbstractors: this.selectedAbstractors };
    this.clearTime();
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime();
  }

  clearTime() {
    // this.dialogRef.close();
    // this.close();
    this.timer.forEach((time) => {
      clearTimeout(time)
    });
  }

  detectTypeChange(event) {
    var order = [];
    if (event.value == 'cost') {
      order = [['Abstrator_Cost', 'ASC'], ['Abstrator_Rating', 'DESC']];
    }
    if (event.value == 'rating') {
      order = [['Abstrator_Rating', 'DESC'], ['Abstrator_Cost', 'ASC']];
    }

    let filterBy = [];
    filterBy.push({ offersCommercial: this.offersCommercial })
    filterBy.push({ valid_EnO: this.valid_EnO })

    this.abstractorService.getAbstractorList(this.Order_ID, order, filterBy)
      .subscribe((abstractors) => {
        this.message['Abstractors'] = abstractors;
      })

  }

  applyFilter() {
    let filterBy = [];
    filterBy.push({ offersCommercial: this.offersCommercial })
    filterBy.push({ valid_EnO: this.valid_EnO })

    var order = [];
    if (this.listingType == 'cost') {
      order = [['Abstrator_Cost', 'ASC'], ['Abstrator_Rating', 'DESC']];
    }
    if (this.listingType == 'rating') {
      order = [['Abstrator_Rating', 'DESC'], ['Abstrator_Cost', 'ASC']];
    }

    this.abstractorService.getAbstractorList(this.Order_ID, order, filterBy)
    .subscribe((abstractors) => {
      this.message['Abstractors'] = abstractors;
    })
  }
  removeSpecialChar(num) {
    if (num) return num.replace(/[^\d]/g, '');
    else return;
  }

  clearFilter() {
    this.searchQuery = '';
  }

  onAbsSelect(ev, abs) {
    if (ev.target.checked) {
      this.selectedAbstractors.push(abs);
    } else {
      var index = this.selectedAbstractors.findIndex((filterItem) => filterItem.Abstrator_User_ID === abs.Abstrator_User_ID);
      if (index > -1) {
        this.selectedAbstractors.splice(index, 1);
      }
    }
  }

// Method to adjust date to 5 PM EST
adjustDateTo5PMEST(date: Date): Date {
  const adjustedDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  adjustedDate.setHours(17, 0, 0, 0); // Set time to 5 PM EST
  return adjustedDate;
}

// Method to calculate expiration text based on conditions
expirationText(obj) {
  if (!obj.User_Abstractor_Attribute_EnO) {
    return 'None';
  }
  if (!obj.User_Abstractor_Attribute_EnO_Expiry) {
    return 'Exp Known';
  }

  const expirationDate = this.adjustDateTo5PMEST(obj.User_Abstractor_Attribute_EnO_Expiry);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return 'Valid';
  } else if (daysDifference > 0) {
    if (daysDifference === 1) {
      return `Expires in ${daysDifference} day`;
    } else {
      return `Expires in ${daysDifference} days`;
    }
  } else if (daysDifference === 0) {
    return 'Expires today';
  } else {
    return 'Expired';
  }
}

// Method to calculate expiration style based on conditions
expirationStyle(obj) {
  if (!obj.User_Abstractor_Attribute_EnO) {
    return { color: 'gray' }; 
  }

  if (!obj.User_Abstractor_Attribute_EnO_Expiry) {
    return { color: 'gray', 'font-weight': 'bold' }; 
  }

  const expirationDate = this.adjustDateTo5PMEST(obj.User_Abstractor_Attribute_EnO_Expiry);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return { color: '#8fd14f', 'font-weight': 'bold' };
  } else if (daysDifference >= 0) {
    return { color: '#fac710', 'font-weight': 'bold' };
  } else {
    return { color: '#f24726', 'font-weight': 'bold' };
  }
}


// isCostExpired(cost_modification_date) {
//   const inputDate = new Date(cost_modification_date);
//   const currentDate = new Date();

//   // Calculate the date 12 months ago from today
//   const twelveMonthsAgo = new Date();
//   twelveMonthsAgo.setMonth(currentDate.getMinutes()-this.constants.AbstractorPricingExpiryDuration);
//   return inputDate < twelveMonthsAgo;
// }
}
