import {throwError as observableThrowError,  Observable } from 'rxjs';

import { catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class PaiConfigService {

  constructor(
      private auth: AuthService,
      private config: ConfigService,
      private http: HttpClient,
    ) { }

    getpAIConfigTemplates(pageNum) {
      let httpOptions = {
        headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
      }
      let url = this.config.getBasePath() + '/pai-config/';
      url = url + '/page/' + pageNum + '/size/' + this.config.getNumRecordsPerPage();
      return this.http.get(url, httpOptions).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => observableThrowError(error.error)),);
    }
  
    getpAIConfigTemplateById(templateId) {
      let httpOptions = {
        headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
      }
      let url = this.config.getBasePath() + '/pai-config/' + templateId;
      return this.http.get(url, httpOptions).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => observableThrowError(error.error)),);
    }
  
    createpAIConfigTemplate(model) {
      let httpOptions = {
        headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
      }
      let url = this.config.getBasePath() + '/pai-config/';
      return this.http.post(url, model, httpOptions).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => observableThrowError(error.error)),);
    }
  
    updatepAIConfigTemplate(model) {
      let httpOptions = {
        headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
      }
      let url = this.config.getBasePath() + '/pai-config/' + model.Id;
      return this.http.put(url, { Template : model, Modified_By: this.auth.getUserId()}, httpOptions).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => observableThrowError(error.error)),);
    }

    getDefaultpAIConfigTemplate() {
      let httpOptions = {
        headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
      }
      let url = this.config.getBasePath() + '/pai-config/default/template';
      return this.http.get(url, httpOptions).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => observableThrowError(error.error)),);
    }
  
    manageDefaultpAIConfigTemplate(model) {
      let data ={
        Template: model,
        Modified_By : this.auth.getUserId()
      }
      let httpOptions = {
        headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
      }
      let url = this.config.getBasePath() + '/pai-config/default/manage';
      return this.http.post(url, data, httpOptions).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => observableThrowError(error.error)),);
    }
}
