
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
//Created by Raja


import { Component, OnInit } from '@angular/core';
import { AuthService, PagerService, ConfigService, AbstractorService, StateService, ScrollService } from '../../services';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { CONSTANTS } from '../../app.constants';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { Router, NavigationExtras } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-abstractor-list',
  templateUrl: './abstractor-list.component.html',
  styleUrls: ['./abstractor-list.component.scss']
})
export class AbstractorListComponent implements OnInit {
  constants = CONSTANTS
  abstractors = [];
  filterBy: any = [];
  pager: any = {};
  sortField: string;
  orderBy: string;
  pageNum: number;
  onlyActiveUsers: boolean = true;
  offersCommercial: boolean = false;
  searchString: string;
  abstractorToDisplay: any;
  totalCount: number;
  searchInProgress: boolean = false;
  scrollEnable: boolean = true;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  selectedState = '';
  selectedCounty = '';
  selectedProduct = 0;
  isErrorModal: boolean = true;
  states = [];
  counties = [];
  products = [];
  searchQuery = new Subject<string>();
  timer: any = [];
  loadRecord : Boolean = false;
  absToolTipText = "Filter abstractors who have pricing in system,\n\r Uncheck to see all abstractors.";
  commercialTipText = "If the flag is off, it returns both those who do and do not offer commercial.";
  listingType = 'enabled';
  selectedEnOStatus:string;
  enoStatus = [
    { absValue: 'All',value: 'All'},
    { absValue: 'None' ,value: 'None'},
    { absValue: 'Valid',value: 'Valid' },
    { absValue: 'Exp Unknown',value: 'Exp Unknown' },
    { absValue: 'Expires Within 30 Days',value: 'Expires Within 30 Days' },
    { absValue: 'Expired',value: 'Expired' },
  ];
  constructor(
    private auth: AuthService,
    private pagerService: PagerService,
    private config: ConfigService,
    private preloaderService: PreloaderService,
    private matDialog:MatDialog,
    private abstractorService: AbstractorService,
    private router: Router,
    private stateService: StateService,
    private scrollService: ScrollService) {
    this.pager.pages = [];
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.searchString = '';
    this.orderBy = 'ASC';
    this.sortField = 'User_Abstractor_Rating';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    
    this.filterBy.push({ onlyActive: this.onlyActiveUsers })
    this.filterBy.push({ offersCommercial: this.offersCommercial })
    this.filterBy.push({ listType: this.listingType })
    if (this.stateService.getAbstractor())
      this.initializeComponentData(this.stateService.getAbstractor())
    else this.abstractorList();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(17), 0));
    // this.getAvailableStateLocal();
  this.abstractorService.getAvailableStateList().subscribe((states) => { this.states = states });
    this.abstractorService.getProductList().subscribe((products) => { this.products = products });
  }
 
  
  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }
 
//   getAvailableStateLocal(){
//     let Allstates = JSON.parse(sessionStorage.getItem('Absstates'));
//     if(Allstates) this.setState(Allstates)         
//     else this.getAvailableStateList();
// }
  
//   getAvailableStateList(){
//     this.abstractorService.getAvailableStateList().subscribe((states) => { 
//       if(states){
//         this.setState(states)         
//         sessionStorage.setItem('Absstates',JSON.stringify(states)); 
//        setTimeout(function(){sessionStorage.removeItem("Absstates");}, CONSTANTS.sessiontimeOut.timeOut);
//       }
//     });

//   }

//   setState(AllstatesData){
//       this.states = AllstatesData
//   }
  

  abstractorList() {
    if (this.pageNum == 1) {
      this.stateService.setAbstractor(this);
      this.abstractorService.getAbstractors(this.getSearchString(), this.pageNum, this.sortField, this.orderBy, this.filterBy)
        .subscribe((result1) => {
          if(result1) {
            this.getAbstractorData(result1);
          }
          // if (result1.count > this.config.getNumRecordsPerPage()) {
          //   this.pageNum++;
          //   this.abstractorService.getAbstractors(this.getSearchString(), this.pageNum, this.sortField, this.orderBy, this.filterBy)
          //     .subscribe((result2) => {
          //       var result = {
          //         rows: result1.rows.concat(result2.rows),
          //         count: result1.count
          //       }
          //       this.getAbstractorData(result);
          //     })
          // } else this.getAbstractorData(result1);
        }
          , () => {
            this.searchInProgress = false;
            this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          })
    } else {
      this.getRetainData();
    }
  }

  getAbstractorData(data) {
    this.abstractors = data.rows;
    this.totalCount = data.count;
    this.loadRecord = true;
    this.getAbstractorListData(this.totalCount)
    this.getScrollPosData()
  }

  // isCostExpired(cost_modification_date) {
  //   const inputDate = new Date(cost_modification_date);
  //   const currentDate = new Date();

  //   // Calculate the date 12 months ago from today
  //   const twelveMonthsAgo = new Date();
  //   twelveMonthsAgo.setMonth(currentDate.getMinutes()-this.constants.AbstractorPricingExpiryDuration);
  //   return inputDate < twelveMonthsAgo;
  // }

  getRetainData() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.abstractorService.getAbstractors(this.getSearchString(), i, this.sortField, this.orderBy, this.filterBy))
    }
    observableForkJoin(ObsCollection)
      .subscribe((retainedOrganization) => {
        if (retainedOrganization) {
          var totalOrders = [];
          for (var i = 0; i < retainedOrganization.length; i++) {
            this.stateService.setAbstractor(this)
            totalOrders = totalOrders.concat(retainedOrganization[i]['rows']);
          }
          this.abstractors = totalOrders;
          this.totalCount = retainedOrganization[0]['count'];
          this.getAbstractorListData(this.totalCount);
          this.getScrollPosData()
        }
      })
  }


  getSearchString() {
    var format = /[!#$%^*\=\[\]{};:\\|<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.abstractorList();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.abstractorList();
  }

  setAbstractorList() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.abstractorToDisplay = this.abstractors.slice(start, this.totalCount);
    if (this.abstractorToDisplay.length > this.config.getNumRecordsPerPage()) {
      this.abstractorToDisplay = this.abstractorToDisplay.slice(0, this.config.getNumRecordsPerPage());
    }
  }

  searchField(search) {
    this.scrollService.setScrollPosAbstractor(0);
    var format = /[!#$%^*\=\[\]{};:\\|<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if (this.searchString) {
      let dotIndex = this.searchString.indexOf(".")
      if (dotIndex == 0 && this.isErrorModal) {
        this.isErrorModal = false;
        this.openErrorPopup("First character cannot be dot(.) in search field.");
      } else if (dotIndex != 0) {
        if (!format.test(this.searchString) && this.searchString==" " ){
          this.searchString='';
          this.abstractorList();
        }else this.findAbstractor();
        this.isErrorModal = true;
      }
    } else {
      this.findAbstractor();
      this.isErrorModal = true;
    }
  }



  findAbstractor() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.abstractorList();
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => { });
  }

  buildAddress(model) {
    var address = '-';
    if (model.Address_1) address = model.Address_1
    if (model.Address_City) {
      if (address) address += ', '
      address += model.Address_City
    }
    if (model.Address_State_Abbr) {
      if (address) address += ', '
      address += model.Address_State_Abbr
    }
    if (model.Address_ZipCode) {
      if (address) address += ' '
      address += model.Address_ZipCode
    }
    return address;
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.abstractorService.getAbstractors(this.getSearchString(), this.pageNum, this.sortField, this.orderBy, this.filterBy)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag()
            this.totalCount = result.count;
            if (this.totalCount > this.abstractors.length) {
              this.abstractors = this.abstractors.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        }, () => {
          this.searchInProgress = false;
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    }
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.selectedState = data.selectedState;
    this.selectedCounty = data.selectedCounty;
    this.selectedProduct = data.selectedProduct;
    this.filterBy = data.filterBy;
    this.counties = data.counties;
    this.products = data.products;
    this.totalCount = data.totalCount;
    this.abstractorToDisplay = data.abstractorToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.onlyActiveUsers = data.onlyActiveUsers;
    this.offersCommercial = data.offersCommercial;
    this.selectedEnOStatus = data.selectedEnOStatus;
    this.listingType = data.listingType;
    this.abstractorList();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  getFilterData(pageNo) {
    this.filterBy = [];
    this.filterBy.push({ page: pageNo });
    this.filterBy.push({ sort: this.sortField });
    this.filterBy.push({ order: this.orderBy });
    this.filterBy.push({ value: this.getSearchString() });
  }

  getAbstractorListData(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setAbstractorList();
    this.pager = this.pagerService.getPager(result, this.pageNum, this.config.getNumRecordsPerPage());
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosAbstractor()) {
      var x = this.scrollService.getScrollPosAbstractor()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  goToAbstractorDetails(userId) {
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop;
      this.scrollService.setScrollPosAbstractor(getPos);
      this.router.navigate(['/abstractor/edit/' + userId])
    }
  }

  getRating(user) {
    if (user.User_Abstractor_Rating_Count)
      return { rating: user.User_Abstractor_Rating, orders: user.User_Abstractor_Rating_Count };
    else return { rating: 0, orders: 0 };
  }

  getCountyList(event) {
    if (event == 'all') {
      this.selectedCounty = 'all';
      this.applyFilter();
    }
    if (event.length != 0 && event != 'all') {
    // this.selectedCounty = '';
      this.abstractorService.getAvailableCountyList(this.selectedState).subscribe((counties) => { 
        this.counties = counties ;
       this.applyFilter();
      });
    }
  }

  applyFilter() {
    this.pageNum = 1;
    this.filterBy = [];
    if (this.selectedState && this.selectedState != 'all') this.filterBy.push({ Property_State_Abbr: this.selectedState })
    if (this.selectedCounty && this.selectedCounty != 'all') this.filterBy.push({ Property_County: this.selectedCounty })
    if (this.selectedProduct && this.selectedProduct != -1) this.filterBy.push({ Product_ID: this.selectedProduct })
    if (this.selectedEnOStatus && this.selectedEnOStatus != 'All') this.filterBy.push({ EnO_Status: this.selectedEnOStatus })
    if (this.listingType) this.filterBy.push({ listType: this.listingType })
    this.filterBy.push({ onlyActive: this.onlyActiveUsers })
    this.filterBy.push({ offersCommercial: this.offersCommercial })
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosAbstractor(x);
    this.abstractorList();    
  }
  
  showOrderSearch(event,abstractorDetails, flag){
    event.stopPropagation();
    let navigationExtras: NavigationExtras = {
      queryParams: {
          "Abs_User_ID": JSON.stringify([abstractorDetails.User_ID]),
          "Completed_Type":flag
      },
    };
    this.router.navigate(['/orders/search'],navigationExtras);
  }  

  resetAllValue(event){
    this.selectedCounty = '';   
  }  

    checkIfProdSeleted() {
      if(this.selectedState && this.selectedCounty && this.selectedProduct && this.selectedState !='all' && this.selectedCounty !='all' && this.selectedProduct != -1 ) return true;
      else return false;
    }

    checkAdminType() {
      if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
      else return true;
    }

    checkForAbsRecords(){
      if(this.loadRecord){
        if(this.totalCount) return '';
        if(this.abstractors.length === 0 && this.onlyActiveUsers) return 'No Results Found. Please try deselecting the "Pricing Present" toggle on the top right.';
        else return 'No Results Found.';
      }
    }

// Method to adjust date to 5 PM EST
adjustDateTo5PMEST(date: Date): Date {
  const adjustedDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  adjustedDate.setHours(17, 0, 0, 0); // Set time to 5 PM EST
  return adjustedDate;
}

// Method to calculate expiration text based on conditions
expirationText(obj) {
  if (!obj.User_Abstractor_Attribute_EnO) {
    return 'None';
  }
  if (!obj.User_Abstractor_Attribute_EnO_Expiry) {
    return 'Exp Known';
  }

  const expirationDate = this.adjustDateTo5PMEST(obj.User_Abstractor_Attribute_EnO_Expiry);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return 'Valid';
  } else if (daysDifference > 0) {
    if (daysDifference === 1) {
      return `Expires in ${daysDifference} day`;
    } else {
      return `Expires in ${daysDifference} days`;
    }
  } else if (daysDifference === 0) {
    return 'Expires today';
  } else {
    return 'Expired';
  }
}

// Method to calculate expiration style based on conditions
expirationStyle(obj) {
  if (!obj.User_Abstractor_Attribute_EnO) {
    return { color: 'gray' }; //none
  }

  if (!obj.User_Abstractor_Attribute_EnO_Expiry) {
    return { color: 'gray', 'font-weight': 'bold' }; //'Exp Known'
  }

  const expirationDate = this.adjustDateTo5PMEST(obj.User_Abstractor_Attribute_EnO_Expiry);
  const currentEST = this.adjustDateTo5PMEST(new Date());

  const daysDifference = Math.floor((expirationDate.getTime() - currentEST.getTime()) / (1000 * 3600 * 24));

  if (daysDifference > 30) {
    return { color: '#8fd14f', 'font-weight': 'bold' };
  } else if (daysDifference >= 0) {
    return { color: '#fac710', 'font-weight': 'bold' };
  } else {
    return { color: '#f24726', 'font-weight': 'bold' };
  }
}


}