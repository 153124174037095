import { Injectable } from "@angular/core";
import {
  BaseAiSectionsService,
  SectionInfo,
} from "../base-ai-sections.service";
import { Observable } from "rxjs";

export class PartySectionService extends BaseAiSectionsService {
  attributes = {};

  getPartySectionInfoFromAI(
    sectionInfo: SectionInfo,
    itemData: any
  ): Observable<any> {
    //corporate owner and buyer
    if (itemData.Entity_ID == 3 || itemData.Entity_ID == 4) {
      this.attributes = {
        First_Business_Name: "",
      };
      return this.getSectionInfoFromAI(sectionInfo);
    }
    //individual owner, buyer
    this.attributes = {
      Last_Name: "",
      Middle_Name: "",
      AKA: "",
      First_Business_Name: "",
    };

    return this.getSectionInfoFromAI(sectionInfo);
  }

  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    return this.reportBuilder.getSectionDataFromAI(
      this.attributes,
      sectionInfo
    );
  }
}
