// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  overflow-y: auto;
  max-height: 400px;
}

.modal-dialog {
  margin-top: 4rem;
  max-width: 1500px;
  width: 52rem;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  opacity: 0.03 !important;
  background-color: #4db8ff !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #4db8ff;
}

.centerwithLeftAlign {
  display: flex;
  align-items: center;
  justify-content: left;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/review-complete-quote/review-complete-quote.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AACA,+HAAA;AACA;EACI,wBAAA;EACA,oCAAA;AAEJ;;AACA,+HAAA;AACA;EACI,yBAAA;AAEJ;;AACE;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;AAEJ","sourcesContent":[".modal-body{\n    overflow-y: auto;\n    max-height: 400px;\n}\n\n.modal-dialog {\n    margin-top: 4rem;\n    max-width: 1500px;\n    width: 52rem;\n}\n/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */\n::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {\n    opacity: 0.03 !important;\n    background-color: #4db8ff!important;\n  }\n\n/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */\n::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {\n    background-color: #4db8ff;\n  }\n\n  .centerwithLeftAlign {\n    display: flex;\n    align-items: center;\n    justify-content: left;\n}\n\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
