// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader-small {
  z-index: 99999;
  position: absolute;
  top: 1rem;
  right: 2.25rem;
  width: 0.25rem;
  height: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/app/services/search-loader/search-loader.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,kBAAA;EACA,SAAA;EACA,cAAA;EACA,cAAA;EACA,eAAA;AADF","sourcesContent":["@import '../../../scss/variables';\n\n.preloader-small {\n  z-index: 99999;\n  position: absolute;\n  top: 1rem;\n  right: 2.25rem;\n  width: 0.25rem;\n  height: 0.25rem;\n}\n\n// .preloader-small__spinner {\n//   color: $primary-color;\n//   position: absolute;\n//   animation: preloaderFullRotate 1s infinite linear;\n// }\n\n// @keyframes preloaderSmallRotate {\n//   0% {\n//     transform: rotate(0deg);\n//   }\n//   100% {\n//     transform: rotate(360deg);\n//   }\n// }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
