import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import {throwError as observableThrowError,  Observable, Observer } from 'rxjs';
import {mergeMap, catchError, map} from 'rxjs/operators';
import { AuthService } from '../authentication/auth.service';
@Injectable({
  providedIn: 'root'
})
export class TagsService {
  direct: HttpClient;
  constructor(
    private http:HttpClient,
    private handler: HttpBackend,
    private config:ConfigService,
    private auth:AuthService
  ) { 
    this.direct = new HttpClient(this.handler);    
  }

  
  getAllActiveTag(pageNo, searchStr, filterBy, orderBy, sortBy){
    var data = {
      filterBy:filterBy,
      sort:sortBy,
      order:orderBy
    }
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/tags/tag/page/'+ pageNo +'/size/' + this.config.getNumRecordsPerPage();;
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data,  httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getTagInfo(tagId){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/tags/'+ tagId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createTag(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/tags/create';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateTag(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/tags/update';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateRemoveOnOrdCompleteStatus(data) {
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/tags/update/remove/on/complete/status';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  chngStatusActiveTag(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/tags/changestatus/active';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  chngStatusSuspendTag(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/tags/changestatus/suspend';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllActiveTagsForListing(){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/tags/active';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        // if(response && response.rows){
        //   response.rows = response.rows.filter((orderTag) => orderTag.Tag_ID != this.config.getAwaitDlvryTagId());
        //   response.rows = response.rows.filter((orderTag) => orderTag.Tag_ID != this.config.getAwaitPartDlvryTagId());
        // }
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getAllActiveGroupTags(data){
    let httpOptions = {
      headers:new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/tags/activeGroupTags';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)),);
  }

  getFontColor(hexColor){
    if(this.hexToRgb(hexColor) != null){      
      var o = Math.round(((this.hexToRgb(hexColor).r * 299) + (this.hexToRgb(hexColor).g * 587) + (this.hexToRgb(hexColor).b * 114)) /1000);
      if(o > 125) return 'black';
      else return 'white';
    }   
    else return 'black'
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  getTagPickerColors(){
    return [
      { value: '#4285F4'},
      { value: '#DB4437'},
      { value: '#F4B400'},
      { value: '#0F9D58'},
      { value: '#800080'}, //===================================================
      { value: '#8db5f8'},
      { value: '#e98e87'},
      { value: '#f8d266'},
      { value: '#6fc49a'},
      { value: '#b266b2'},//===================================================
      // {'value: #274f92'},
      // {'value: #832821'},
      // {'value: #926c00'},
      // {'value: #095e34'}',
      // {'value: #4c004c'}
    ]
  }
  
}
