import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-log-dialog",
  templateUrl: "./log-dialogue.component.html",
  styleUrls: ["./log-dialogue.component.scss"],
})
export class LogDialogComponent implements OnInit {
  logData: any[] = [];
  dataSource!: MatTableDataSource<any>;
  isLoading = true;
  displayedColumns: string[] = ["ai_generated_text", "template_text", "created_at", "response"];
  expandedRow: any = null; 
  constructor(
    public dialogRef: MatDialogRef<LogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.fetchLogs();
  }

  async fetchLogs() {
    try {
      this.isLoading = true
      this.logData = await this.data.fetchLogs();
      this.dataSource = new MatTableDataSource(this.logData);
    } catch (error) {
      console.error("Error fetching logs:", error);
      this.logData = null;
    } finally {
      this.isLoading = false;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
  
  toggleResponse(row: any) {
    this.expandedRow = this.expandedRow === row ? null : row;
  }
}
