// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ai-button {
  width: 40px;
  height: 27px;
  border: none;
  margin-bottom: 2px;
  margin-left: 5px;
  border-radius: 5px;
  color: white;
  padding: 2.5px !important;
  padding-bottom: 5px;
}

.pai-image {
  margin-bottom: 5px !important;
}

.ai-button:active, .ai-button:focus {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.ai-indicator-autofilled {
  background-color: #4FB2D9;
}

.ai-indicator-default {
  background-color: #B2BEB5;
}

.ai-indicator-edited {
  background-color: #F16161;
}

.ai-indicator-unsure {
  background-color: #EBC03C;
}

.ai-indicator-accepted {
  background-color: #60cc70;
}

.text-bold {
  font-weight: bold;
}

.ai-dropdown {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ai-components/ai-dropdown-field/ai-dropdown-field.component.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;AAAJ;;AAGE;EACE,6BAAA;AAAJ;;AAGE;EACI,gFAAA;AAAN;;AAGE;EACI,yBAAA;AAAN;;AAGE;EACG,yBAAA;AAAL;;AAGE;EACG,yBAAA;AAAL;;AAGE;EACE,yBAAA;AAAJ;;AAGE;EACE,yBAAA;AAAJ;;AAGE;EACE,iBAAA;AAAJ;;AAGE;EACE,aAAA;EACA,mBAAA;AAAJ","sourcesContent":["\n.ai-button{\n    width: 40px;\n    height: 27px;\n    border: none;       \n    margin-bottom: 2px;\n    margin-left: 5px;\n    border-radius: 5px;\n    color: white;\n    padding: 2.5px !important;\n    padding-bottom: 5px;\n  }\n\n  .pai-image{\n    margin-bottom: 5px !important;\n  }\n  \n  .ai-button:active, .ai-button:focus{\n      box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);\n  }\n  \n  .ai-indicator-autofilled{\n      background-color: #4FB2D9;\n  }\n  \n  .ai-indicator-default{\n     background-color: #B2BEB5;\n  }\n  \n  .ai-indicator-edited{\n     background-color: #F16161;\n  }\n\n  .ai-indicator-unsure{\n    background-color: #EBC03C;\n  }\n  \n  .ai-indicator-accepted{\n    background-color: #60cc70;\n  }\n  \n  .text-bold{\n    font-weight: bold;\n  }\n\n  .ai-dropdown{\n    display: flex;\n    align-items: center;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
