import { Injectable } from "@angular/core";
import {
  BaseAiSectionsService,
  SectionInfo,
} from "../base-ai-sections.service";
import { Observable } from "rxjs";

@Injectable()
export class TaxSectionStateService extends BaseAiSectionsService {
  attributes = {};

  getSectionInfoFromAI(sectionInfo: SectionInfo): any {
    return this.reportBuilder.getSectionDataFromAI(this.attributes, sectionInfo);
  }

  async getTaxSectionInfoFromAI(sectionInfo: SectionInfo, entityId: number, Taxing_Authority_Type_Id: any[], Prior_Delinquencies_Type_Id: any[], tax_Source_Type_Id: any[], Tax_Paid_Status_Type_Id: any[]): Promise<Observable<any>> {
    this.attributes = this.getTaxAttributes(entityId, Taxing_Authority_Type_Id, Prior_Delinquencies_Type_Id, tax_Source_Type_Id, Tax_Paid_Status_Type_Id);
    const response = await this.getSectionInfoFromAI(sectionInfo).toPromise();
    
    const selectedTaxTypeId = Taxing_Authority_Type_Id.find(
      (e) => e.Name.toLowerCase() === response.Tax_Type_ID.toLowerCase()
    );

    const selectedSourceTypeId = tax_Source_Type_Id.find(
      (e) => e.Source_Type.toLowerCase() === response.Source_Type_ID.toLowerCase()
    );

    const selectedPriorityDel = Prior_Delinquencies_Type_Id.find(
      (e) => e.Name.toLowerCase() === response.Prior_Delinquencies.toLowerCase())

    if(entityId == 1 || entityId == 4){  
      var selectedAnnualPaidStatusTypeID = Tax_Paid_Status_Type_Id.find(
          (e) => e.Status_Type.toLowerCase() === response.Annual_Paid_Status_Type_ID.toLowerCase()
      )
    }else if(entityId == 2){
      var selectedFstSemiAnnualPaidStatusTypeID = Tax_Paid_Status_Type_Id.find(
        (e) => e.Status_Type.toLowerCase() === response.Fst_Semi_Annual_Paid_Status_Type_ID.toLowerCase()
      )
  
      var selectedSndSemiAnnualPaidStatusTypeID = Tax_Paid_Status_Type_Id.find(
        (e) => e.Status_Type.toLowerCase() === response.Snd_Semi_Annual_Paid_Status_Type_ID.toLowerCase()
      )
    }else if(entityId == 3){
        var selectedFstQuarterPaidStatusTypeID = Tax_Paid_Status_Type_Id.find(
          (e) => e.Status_Type.toLowerCase() === response.Fst_Quarter_Paid_Status_Type_ID.toLowerCase()
        )

        var selectedSndQuarterPaidStatusTypeID = Tax_Paid_Status_Type_Id.find(
          (e) => e.Status_Type.toLowerCase() === response.Snd_Quarter_Paid_Status_Type_ID.toLowerCase()
        )

        var selectedThrdQuarterPaidStatusTypeID = Tax_Paid_Status_Type_Id.find(
          (e) => e.Status_Type.toLowerCase() === response.Thrd_Quarter_Paid_Status_Type_ID.toLowerCase()
        )

        var selectedFrthQuarterPaidStatusTypeID = Tax_Paid_Status_Type_Id.find(
          (e) => e.Status_Type.toLowerCase() === response.Frth_Quarter_Paid_Status_Type_ID.toLowerCase()
        )
    }
    

    return { ...response, Tax_Type_ID: selectedTaxTypeId?.Id ?? 1, 
              Source_Type_ID: selectedSourceTypeId?.Id ?? null, 
              Prior_Delinquencies: selectedPriorityDel?.Id ?? null, 
              Annual_Paid_Status_Type_ID: selectedAnnualPaidStatusTypeID?.Id ?? null, 
              Fst_Semi_Annual_Paid_Status_Type_ID: selectedFstSemiAnnualPaidStatusTypeID?.Id ?? null, 
              Snd_Semi_Annual_Paid_Status_Type_ID: selectedSndSemiAnnualPaidStatusTypeID?.Id ?? null,
              Fst_Quarter_Paid_Status_Type_ID: selectedFstQuarterPaidStatusTypeID?.Id ?? null,
              Snd_Quarter_Paid_Status_Type_ID: selectedSndQuarterPaidStatusTypeID?.Id ?? null,
              Thrd_Quarter_Paid_Status_Type_ID: selectedThrdQuarterPaidStatusTypeID?.Id ?? null,
              Frth_Quarter_Paid_Status_Type_ID: selectedFrthQuarterPaidStatusTypeID?.Id ?? null}
  }

  getTaxAttributes(entityId, Taxing_Authority_Type_Id, Prior_Delinquencies_Type_Id, tax_Source_Type_Id, Tax_Paid_Status_Type_Id) {
    const paidTypeList = Tax_Paid_Status_Type_Id.map(e => e.Status_Type)
    const statusPrompt = `select any one value from this list :- ${JSON.stringify(paidTypeList)}`;
    switch (entityId) {
      case 1:
        {
          return this.getCommonAttributes({
            Annual_Amount: "",
            Annual_Paid_Date: "",
            Annual_Paid_Status_Type_ID: statusPrompt,
          }, Taxing_Authority_Type_Id, Prior_Delinquencies_Type_Id, tax_Source_Type_Id);
        }

      case 2:
        {
          return this.getCommonAttributes({
            Fst_Semi_Annual_Amount: "",
            Snd_Semi_Annual_Amount: "",
            Fst_Semi_Annual_Paid_Status_Type_ID: statusPrompt,
            Snd_Semi_Annual_Paid_Status_Type_ID: statusPrompt,
            Fst_Semi_Annual_Paid_Date: "",
            Snd_Semi_Annual_Paid_Date: ""
          }, Taxing_Authority_Type_Id, Prior_Delinquencies_Type_Id, tax_Source_Type_Id);
        }

      case 3:
        {
          return this.getCommonAttributes({
            Fst_Quarter_Amount: "",
            Snd_Quarter_Amount: "",
            Thrd_Quarter_Amount: "",
            Frth_Quarter_Amount: "",
            Fst_Quarter_Paid_Status_Type_ID: statusPrompt,
            Snd_Quarter_Paid_Status_Type_ID: statusPrompt,
            Thrd_Quarter_Paid_Status_Type_ID: statusPrompt,
            Frth_Quarter_Paid_Status_Type_ID: statusPrompt,
            Fst_Quarter_Paid_Date: "",
            Snd_Quarter_Paid_Date: "",
            Thrd_Quarter_Paid_Date: "",
            Frth_Quarter_Paid_Date: "",
            Status: statusPrompt
          }, Taxing_Authority_Type_Id, Prior_Delinquencies_Type_Id, tax_Source_Type_Id);
        }

      case 4:
        {
          return this.getCommonAttributes({
            Annual_Amount: "",
            Annual_Paid_Date: "",
            Annual_Paid_Status_Type_ID: statusPrompt,
          }, Taxing_Authority_Type_Id, Prior_Delinquencies_Type_Id, tax_Source_Type_Id);
        }

      default:
        {
          return {}
        }
    }

  }


  getCommonAttributes(additionalAttributes, Taxing_Authority_Type_Id, Prior_Delinquencies_Type_Id, tax_Source_Type_Id) {
    const taxTypeList = Taxing_Authority_Type_Id.map(e => e.Name)
    const priorDelinquenciesList = Prior_Delinquencies_Type_Id.map(e => e.Name)
    const taxsourceList = tax_Source_Type_Id.map(e => e.Source_Type)
    return {
      Year: "",
      Tax_Type_ID: `select any one value from this list :- ${JSON.stringify(taxTypeList)}`,
      Prior_Delinquencies: `select any one value from this list :- ${JSON.stringify(priorDelinquenciesList)}`,
      Source_Type_ID: `select any one value from this list :- ${JSON.stringify(taxsourceList)}`,
      Parcel: "",
      Language: "",
      ...additionalAttributes
    };
  }

}