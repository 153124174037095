import { Injectable } from '@angular/core';

@Injectable()
export class StateService {

  private customPricing:any=null;
  private productPricing:any=null;
  private clients:any=null;
  private products:any=null;
  private users:any=null;
  private completedOrders:any=null;
  private cancelledOrders:any=null;
  private newOrders:any=null;
  private ordersInProgress:any=null;
  private messages:any=null;
  private ogranization:any=null;
  private EULA:any=null;
  private abstractor:any=null;
  private searchMasters:any=null;
  private excludedDomains:any=null;
  private allOrders:any=null;
  private searchOrders:any=null;
  private tags:any=null;
  private quickLinks:any=null;
  private confirmedOrders:any = null;
  private pausedOrders:any = null;
  private nonBillingOrders:any = null;
  private pdfData:any=null;
  private bulkOrdData:any=null;
  private uploadTypesData:any=null;
  private reportTypesData:any=null;
  private quoteData:any;
  private newCliOrds:any=null;
  private emailTemplates:any=null;
  private priorityOrders:any=null;
  private escalatedOrders:any=null;
  private WatchOrders:any=null;
  private counties:any = null;
  private vendorOrders:any=null;
  private promptTemplates:any = null;
  constructor() { }

  resetAll(){
    this.customPricing=null
    this.productPricing=null;
    this.clients=null;
    this.products=null;
    this.users=null;
    this.completedOrders=null;
    this.cancelledOrders=null;
    this.newOrders=null;
    this.ordersInProgress=null;
    this.messages=null;
    this.ogranization=null;
    this.EULA=null;
    this.abstractor=null;
    this.searchMasters=null;
    this.excludedDomains=null;
    this.allOrders=null;
    this.searchOrders = null;
    this.tags = null;
    this.quickLinks=null;    
    this.confirmedOrders = null    
    this.pausedOrders = null; 
    this.pdfData=null;
    this.bulkOrdData=null;
    this.uploadTypesData=null;
    this.reportTypesData=null;
    this.quoteData=null;
    this.nonBillingOrders=null;
    this.newCliOrds=null;
    this.emailTemplates=null;
    this.priorityOrders=null;
    this.escalatedOrders=null;
    this.WatchOrders=null;
    this.counties = null;
    this.vendorOrders=null;
  }

  setCustomPricing(customPricing){
    this.customPricing=customPricing;    
  }

  getCustomPricing(){
    if(this.customPricing) {      
      return this.customPricing
    }
  }

  setProductPricing(productPricing){
    this.productPricing=productPricing;    
  }

  getProductPricing(){
    if(this.productPricing) {      
      return this.productPricing
    }
  }

  setMessages(messages){
    this.messages=messages;    
  }

  getMessages(){
    if(this.messages) {      
      return this.messages
    }
  }

  setNewOrders(newOrders){
    this.newOrders=newOrders;    
  }

  getNewOrders(){
    if(this.newOrders) {      
      return this.newOrders
    }
  }

  setOrdersInProgress(ordersInProgress){
    this.ordersInProgress=ordersInProgress;    
  }

  getOrdersInProgress(){
    if(this.ordersInProgress) {      
      return this.ordersInProgress
    }
  }

  setConfirmedOrders(confirmedOrders){
    this.confirmedOrders=confirmedOrders;    
  }

  getConfirmedOrders(){
    if(this.confirmedOrders) {      
      return this.confirmedOrders
    }
  }

  setPausedOrders(pausedOrders){
    this.pausedOrders=pausedOrders;    
  }

  getPausedOrders(){
    if(this.pausedOrders) {      
      return this.pausedOrders
    }
  }

  setNewCliOrds(newCliOrds){
    this.newCliOrds=newCliOrds;    
  }

  getNewCliOrds(){
    if(this.newCliOrds) {      
      return this.newCliOrds
    }
  }

  setQuoteRequest(QuoteData){
    this.quoteData=QuoteData;    
  }

  getQuoteRequest(){
    if(this.quoteData) {      
      return this.quoteData
    }
  }

  setUsers(users){
    this.users=users;    
  }

  getUsers(){
    if(this.users) {      
      return this.users
    }
  }

  setCompletedOrders(completedOrders){
    this.completedOrders=completedOrders;    
  }

  getCompletedOrders(){
    if(this.completedOrders) {      
      return this.completedOrders
    }
  }

  setCancelledOrders(cancelledOrders){
    this.cancelledOrders=cancelledOrders;    
  }

  getCancelledOrders(){
    if(this.cancelledOrders) {      
      return this.cancelledOrders
    }
  }

  setClients(clients){
    this.clients=clients;    
  }

  getClients(){
    if(this.clients) {      
      return this.clients
    }
  }
  setProducts(products){
    this.products=products;    
  }

  getProducts(){
    if(this.products) {      
      return this.products
    }
  }

  setOrganization(ogranization){
    this.ogranization=ogranization;
  }

  getOrganization(){
    if(this.ogranization)
    return this.ogranization;
  }
  setEULA(EULA){
    this.EULA=EULA;
  }

  getEULA(){
    if(this.EULA)
    return this.EULA;
  }

  setAbstractor(abstractor){
    this.abstractor= abstractor;
  }
  
  getAbstractor(){
    if(this.abstractor)
    return this.abstractor
  }

  setSearchMasters(masters){
    this.searchMasters= masters;
  }
  
  getSearchMasters(){
    if(this.searchMasters)
    return this.searchMasters
  }

  setExcludedDomains(data){
    this.excludedDomains= data;
  }
  
  getExcludedDomains(){
    if(this.excludedDomains) return this.excludedDomains;
  }

  getAllOrders(){
    if(this.allOrders)
    return this.allOrders
  }

  setAllOrders(allOrders){
    this.allOrders=allOrders;    
  }

  setSearchOrders(searchOrders){
    this.searchOrders=searchOrders;    
  }
  getSearchOrders(){
    if(this.searchOrders) return this.searchOrders
  }

  setTags(tags){
    this.tags = tags;
  }

  getTags(){
    if(this.tags) return this.tags;
  }

  setQuickLinks(quickLinks) {
    this.quickLinks = quickLinks;
  }

  getQuickLinks() {
    if(this.quickLinks) return this.quickLinks;
  } 

  setPDFData(data) {
    this.pdfData = data;
  }

  getPDFData() {
    if(this.pdfData) return this.pdfData;
  } 

  setBulkOrdData(data) {
    this.bulkOrdData = data;
  }

  getBulkOrdData() {
    if(this.bulkOrdData) return this.bulkOrdData;
  } 

  setUploadTypesData(data) {
    this.uploadTypesData = data;
  }

  getUploadTypesData() {
    if(this.uploadTypesData) return this.uploadTypesData;
  } 

  setReportTypesData(data) {
    this.reportTypesData = data;
  }
  
  getReportTypesData() {
    if(this.reportTypesData) return this.reportTypesData;
  } 

  setNonBillingOrders(data){
    this.nonBillingOrders = data;
  }

  getNonBillingOrders(){
    return this.nonBillingOrders;
  }

  getEmailTemplates(){
    return this.emailTemplates;
  }

  setEmailTemplates(data){
    this.emailTemplates = data;
  }

  getPromptTemplates(){
    return this.promptTemplates;
  }

  setPromptTemplates(data){
    this.promptTemplates = data;
  }

  setPriorityOrders(priorityOrders){
    this.priorityOrders=priorityOrders;
  }

  getPriorityOrders(){
    return this.priorityOrders;
  }

  setWatcedOrders(WatchOrders){
    this.WatchOrders=WatchOrders;
  }

  getWatcedOrders(){
    return this.WatchOrders;
  }

  setEscalatedOrders(escalatedOrders){
    this.escalatedOrders=escalatedOrders;
  }

  getEscalatedOrders(){
    return this.escalatedOrders;
  }

  setVendorOrders(vendorOrders){
    this.vendorOrders=vendorOrders;
  }

  getVendorOrders(){
    return this.vendorOrders;
  }

  setCounties(counties){
    this.counties=counties;
  }

  getCounties(){
    return this.counties;
  }

}