import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuoterequestService } from '../../services';
@Component({
  selector: 'app-quote-info',
  imports: [],
  templateUrl: './quote-info.component.html',
  styleUrl: './quote-info.component.scss'
})
export class QuoteInfoComponent {
  quoteHistoryOrder_ID:any;
  quoteETA :any;
  quotePrice:any;
  quoteRequestedDate:any;
  quoteCompletedDate:any;
  quoteConvertedToOrder:any;
  quoteCompletedBy:any;
  quoteRequestedBy:any;
  quoteConvertedBy:any;
  quoteEtaChangedBy:any;
  quotePriceChangedBy:any;
  quoteCreatedBy:any;
  constructor(
    private route: ActivatedRoute,
    private quoteService: QuoterequestService,
  ){}

  ngOnInit(){
        this.route.params.subscribe(params => {
          this.quoteService.getQuoteHistory(params['orderId'])
          .subscribe((data) => {
            if (data?.quoteHistory) { 
            this.quoteHistoryOrder_ID = data.quoteHistory.Quote_Order_ID;
            this.quoteETA = data.quoteHistory.ETA;
            this.quotePrice = '$' + data.quoteHistory.Price;
            this.quoteCompletedBy = data.quoteHistory.Completed_By;
            this.quoteCreatedBy = data.quoteHistory.Created_By;
            this.quoteRequestedDate = new Date(data.quoteHistory.Requested_Date).toLocaleString("en-US", { timeZone: "America/New_York" });
            this.quoteCompletedDate=new Date(data.quoteHistory.Completed_Time).toLocaleString("en-US", { timeZone: "America/New_York" });
            this.quoteConvertedToOrder=new Date(data.quoteHistory.Convert_To_Order).toLocaleString("en-US", { timeZone: "America/New_York" });
            this.quoteConvertedBy = data.quoteHistory.Converted_By;
            }
          })
        })
  }

  
  getFormattedETA(dateDetails) {
    if (!dateDetails) {
      return 'N/A'; // Handle cases where ETA is not available
    }
    const date = new Date(dateDetails);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };

    return date.toLocaleString('en-US', options);
  }
}



