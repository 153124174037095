import { Injectable } from '@angular/core';
import { BaseAiSectionsService, SectionInfo } from '../base-ai-sections.service';
import { Observable } from 'rxjs';

export class DeedSectionStateService extends BaseAiSectionsService{

  attributes = {}

  async getDeedSectionInfoFromAI(sectionInfo:SectionInfo, instrumentTypeOptions): Promise<any> {
    const names = instrumentTypeOptions.map(e=>e.Name)
     this.attributes = {
      Grantor: '',
      Instrument_Type_ID: `select any one value from this list :- ${JSON.stringify(names)}`,
      Grantee: '',
      Estate_Of: '',
      Beneficiaries: '',
      Instrument_Num: '',
      Page: '',
      Dated_Date: '',
      Rec_Date: '',
      Date_Of_Death: '',
      Consideration: 'This is a Amount fetch this as a amount and NOT as a string',
      Others:'ONLY FILL THIS when Instrument_Type_ID IS Others'
     }
     const response = await this.getSectionInfoFromAI(sectionInfo).toPromise()
     const selectedOption = instrumentTypeOptions.find(
      (e) => e.Name?.toLowerCase()?.trim() === response?.Instrument_Type_ID?.toLowerCase()?.trim()
    );

    return {...response, Instrument_Type_ID: selectedOption?.Id ?? null}
  }

  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    return this.reportBuilder.getSectionDataFromAI(this.attributes, sectionInfo); 
  }
}
