import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef, Inject } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { AuthService } from '../../services/authentication/auth.service';
import * as moment from 'moment';
import { CONSTANTS } from 'app/app.constants';
import { DragDropModule } from '@angular/cdk/drag-drop';
import * as momentTimeZone from 'moment-timezone';
import { MatSelect } from '@angular/material/select';
import { ConfigService, SchedulingService } from '../../services';
import { UntypedFormControl, Validators,UntypedFormGroup,UntypedFormBuilder } from '@angular/forms';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-assign',
  templateUrl: './assign.component.html',
  styleUrls: ['./assign.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssignComponent {
  @ViewChild('okBtn') focusedElement: ElementRef;
  @ViewChild('myInput') myInput: ElementRef;
  @ViewChild('myFirstFocus') myFirstFocus: MatSelect;
  @ViewChild('etaDatePicker') etaDatePicker: ElementRef<HTMLElement>
  selectedAssignees:any = [];
  selectedAdmin: string = '';
  message:any={};
  title='';
  errors: any = {};
  getUSDateString = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
  minDate = new Date(this.getUSDateString);
  activeAdmin: any = '';
  messageText = '';
  checkAdmin :boolean
  assignType:number;
  order_ETA_Time :string;
  orderETAtime= new UntypedFormControl('', [Validators.required]);
  dueDateTimeCtrl =new UntypedFormControl(null);
  timeToComplete:string;
  isDueRangeErr: boolean = false;
  dueDateFrmObj: any;
  dueDateTimeObj: any;
  dueDate_Range: any;
  dueDateRangeOption: Number
  order_ETA_Date: any;
  isMultiAssign: boolean = false;
  timer: any = [];
  copyDetails: any;
  listOfHolidays: any;
  weekendHolidayName: String;
  newOrderForm: UntypedFormGroup;
  validDueDate: boolean = false;
  etaChangeReasonTypeId: any = null;
  etaChangeComment:any = null;
  etaChangeEmail:any = null;
  etaChangeNotify:any = null;
  etaChangeClientStatus:any = null;
  constants = CONSTANTS;
  constructor( private auth: AuthService,
    private frmBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AssignComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
     private config:ConfigService,
     private ref: ChangeDetectorRef) {
    //this['message']=[];    
    this.newOrderForm = frmBuilder.group({
      Order_ETA: [{ disabled: false, value: '' }],
    })

  }

  ngOnInit() {
    this.initDialog();
    if(this.message['Multi_Orders']) this.isMultiAssign = this.message['Multi_Orders'];
    this.activeAdmin = this.message['Current_Admin'];
    this.checkAdmin = (this.activeAdmin != this.auth.getUserId())
    this.getAvlOrderETAnTime();
    this.message['admins'].map((admin) => admin.Type = 1);
    if(this.message['Search_Masters']) this.message['Search_Masters'].map((admin) => admin.Type = 2)
    this.selectedAssignees = this.getAllAssignee();
    if(this.activeAdmin) this.selectedAdmin = this.activeAdmin;
    this.timer.push(setTimeout(() => { this.myFirstFocus.focus() }, 0));
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
    this.listOfHolidays = this.data.listOfHolidays;
  }

  getAvlOrderETAnTime() {
    if(this.message['Order_ETA']) {
      let etaFullDate = new Date(momentTimeZone.tz(this.message['Order_ETA'], 'America/New_York').format('MM/DD/YYYY HH:mm'));
      let date = new Date(etaFullDate.toDateString());
      this.message['Order_ETA'] = date;
      let etaHours = etaFullDate.getHours().toString();
      let etaMinutes= etaFullDate.getMinutes().toString();
      if(etaHours.length == 1) etaHours = "0"+etaHours;
      if(etaMinutes.length == 1) etaMinutes = "0"+etaMinutes;
      this.order_ETA_Time = etaHours+ ":"+etaMinutes;
      this.copyDetails = Object.assign({}, this.message); 
    } else {
      this.message['Order_ETA'] = null;
      this.order_ETA_Time = "17:00" ;
    }
    this.ref.detectChanges();
  }

  getSearchMasterRole() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return false
    else return true;
  }
  
  isSearchManager(){
    if(this.auth.isSearchManager()) return true
    else return false;
  }

  confirm(orderETA) {
    let dueDate_ETA;
    if(!this.isMultiAssign) {
    let finalDueDate
    if(this.checkIfETAChanged() && this.copyDetails.Order_ETA) this.checkForSelectedETA(orderETA, finalDueDate, dueDate_ETA)
    else this.dueDateProcess(finalDueDate, orderETA, dueDate_ETA)
    } else {
      dueDate_ETA=null;
      this.message['Order_ETA_Range'] = null;
      this.sendPrcoessDetails(dueDate_ETA)
    }
  }

  sendPrcoessDetails(dueDate_ETA) {
    this.dialogRef.close({
      Order_ETA:dueDate_ETA,
      admin:this.selectedAdmin,
      msgText:this.messageText.trim(),
      assignType: this.message['assignType'],
      getTimeToCompl:this.timeToComplete,
      Order_ETA_Range:this.message['Order_ETA_Range'],
      Order_ETA_Change_Reason_Type_ID : this.etaChangeReasonTypeId,
      Order_ETA_Comment: this.etaChangeComment,
      Email_Body: this.etaChangeEmail,
      Notify_Customer: this.etaChangeNotify,
      Client_Notif_Status: this.etaChangeClientStatus
    });
    this.clearTime();
  }

  assignOrderToMe() {
    this.selectedAdmin = this.auth.getUserId();;
  }

  unassign(){
    this.selectedAdmin="";
    this.timeToComplete="";
    this.confirm(this.message['Order_ETA']);
 }

  Close() {
    this.dialogRef.close(false);
    this.clearTime();
  }

  validateOrderETADateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_ETA = true;
    else delete this.errors.Order_ETA;
  }

  searchOptions(query){
    if(query.keyCode == 32 && query.type == "keydown") query.stopPropagation();
    let result = this.select(query.target.value);
    this.selectedAssignees = result;
  }

  select(query: string):string[]{
    let result: string[] = [];
    var assignees = this.getAllAssignee()
    for(let a of assignees){
      if(a['User_First_Name'].toLowerCase().indexOf(query.toLowerCase().trim()) > -1 || a['Email_Address'].toLowerCase().indexOf(query.toLowerCase().trim()) > -1){
        result.push(a)
      }
    }
    return result;
  }

  getAdmins(){
    return this.selectedAssignees.filter((user) => user.Type == 1);
  }

  getSearchers(){
    return this.selectedAssignees.filter((user) => user.Type == 2);
  }

  clearValue(myInput){
    if(myInput.nativeElement) myInput.nativeElement.value = '';
    else myInput.value = '';
    this.selectedAssignees = this.getAllAssignee()
  }

  getAllAssignee() {
    if (this.message['Search_Masters']) return this.message['admins'].concat(this.message['Search_Masters']);
    else if (!this.message['Search_Masters']) return this.message['admins']
  }

  clicked(event){
    if(event) this.timer.push(setTimeout(() => this.myInput.nativeElement.focus(), 0));
    else this.clearValue(this.myInput);
  }

  setOrderETATime(orderETA) {
    if(orderETA && orderETA._d){
    let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
    let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
    return orderETA._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    }else  if(orderETA && !orderETA._d){
    let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
    let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
    return orderETA.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    }else return null;
  }

  checkOrderETATime() {
    if(this.order_ETA_Time) return false;
    else return true;
  }

  getFormObject(event) {
    this.dueDateTimeCtrl = event;
    if(!this.dueDateTimeCtrl.value.taskDueDay && !this.dueDateTimeCtrl.value.taskDueHour) this.timeToComplete = null;
    if(this.dueDateTimeCtrl.value.taskDueDay){
      this.timeToComplete = this.dueDateTimeCtrl.value.taskDueDay + "d";
      if(this.dueDateTimeCtrl.value.taskDueHour) this.timeToComplete+= " "+this.dueDateTimeCtrl.value.taskDueHour +"h";
    }else if(this.dueDateTimeCtrl.value.taskDueHour)this.timeToComplete = this.dueDateTimeCtrl.value.taskDueHour +"h";
  }

  getDueDateData(event) {
    this.dueDateRangeOption = Number(event.dueDateType);
    if (Number(event.dueDateType)) {
      if (event.dueDateFrmCtrl) {
        this.isDueRangeErr = true;
        this.dueDateFrmObj = event.dueDateFrmCtrl;
        if(this.dueDateFrmObj) {
          if (this.dueDateFrmObj.value.fromDayCtrl && this.dueDateFrmObj.value.toDayCtrl) {
            let getDueDateRange = this.dueDateFrmObj.value.fromDayCtrl + "-" + this.dueDateFrmObj.value.toDayCtrl;
            let addDays: any = momentTimeZone.tz('America/New_York').add(Number(this.dueDateFrmObj.value.toDayCtrl), 'days').format()
            let getDateObj =  this.Convert_UTCDate_To_LocalDate(addDays, 'America/New_York');
            this.dueDate_Range = getDateObj; 
            this.message['Order_ETA_Range'] = getDueDateRange;
          }else {
            if(this.dueDateFrmObj.controls['fromDayCtrl'].touched && !(this.dueDateFrmObj.value.fromDayCtrl)) this.dueDateFrmObj.controls['fromDayCtrl'].setErrors({'fromDayRequired':true});
            if(this.dueDateFrmObj.controls['toDayCtrl'].touched && !(this.dueDateFrmObj.value.toDayCtrl)) this.dueDateFrmObj.controls['toDayCtrl'].setErrors({'toDayRequired':true});
          }
        }
      }
    } else {
      this.isDueRangeErr = false;
      this.dueDate_Range =null;
      this.message['Order_ETA_Range'] = null;
      if (event.dueDateTimeCtrl) {
        this.dueDateTimeObj = event.dueDateTimeCtrl;
        if (event.dueDateTimeCtrl.valid) this.checkForDueDateEST(event);
      }
      else this.checkForDueDateEST(event);
    }
    if(event.hasOwnProperty('reason')){
      this.etaChangeReasonTypeId = event.reason;
    }
    if(event.hasOwnProperty('comment')){
      this.etaChangeComment = event.comment;
    }
    if(event.hasOwnProperty('emailContent')){
      this.etaChangeEmail= event.emailContent;
    }
    if(event.hasOwnProperty('notifyCustomer')){
      this.etaChangeNotify = event.notifyCustomer;
    }
    if(event.hasOwnProperty('clientNotifStatus')){
      this.etaChangeClientStatus = event.clientNotifStatus;
    }
  }

  Convert_UTCDate_To_LocalDate(utcTime: Date, localTimeZone: string) {
    var utcMoment = momentTimeZone(utcTime);
    var localDateTime = utcMoment.tz(localTimeZone);
    return new Date(localDateTime.format('lll'));
  }

  checkForDueDateEST(event) {
    if(event.Due_Date_EST ){
    if (event.Due_Date_EST && event.Due_TIme_EST) {
      this.message['Order_ETA'] = event.Due_Date_EST;
      this.order_ETA_Time = event.Due_TIme_EST;
      this.validDueDate=false;
    }
  }else this.validDueDate=true; 
  }

  checkForDueDateValid() {
    if(this.isMultiAssign) return false
    else {
      if (!this.message['Order_ETA'] && !this.message['Order_ETA_Range']) return true;
      else {
        if (this.dueDateRangeOption) {
          if(this.dueDateFrmObj && this.dueDateFrmObj.valid) return false;
          else return true;
        } else {
          if (this.dueDateTimeObj) {
            if (this.dueDateTimeObj.valid) return false;
            else if (!this.dueDateTimeObj.valid) return true
          } else return false;
        }
      }
    }   
  }

  clearTime(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  checkForSelectedETA(orderETA, finalDueDate, dueDate_ETA){
    // let orderETADate = moment(orderETA).format("YYYY-MM-DD");
    // let checkETAIsHoliday = this.listOfHolidays.findIndex((holiday) => {
    //   if(holiday && moment(holiday.Holiday_Date, "YYYY-MM-DD").isSame(orderETADate)) return true;
    //   else return false
    // })
    // if(checkETAIsHoliday > -1 ) this.showWarningPopup(this.listOfHolidays[checkETAIsHoliday].Holiday_Name, finalDueDate, dueDate_ETA, orderETA)
    // else if(this.checkETALieOnWeekend(orderETA)) this.showWarningPopup(this.weekendHolidayName, finalDueDate, dueDate_ETA, orderETA)
    // else this.dueDateProcess(finalDueDate, orderETA, dueDate_ETA)
    this.dueDateProcess(finalDueDate, orderETA, dueDate_ETA)
  }

  showWarningPopup(holidayName, finalDueDate, dueDate_ETA, orderETA){
    let disposable = this.matDialog.open(ConfirmComponent, {data:{
      title:'ETA Note',
      message:"The ETA on this order is a US holiday ("+holidayName+"). Would you like to continue?"
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => { 
      if(res) this.dueDateProcess(finalDueDate, orderETA, dueDate_ETA)
    });
  }

  checkETALieOnWeekend(orderETA) {
    if(moment(orderETA).isoWeekday() == 7){ // iso Weekday for sunday is 7
      this.weekendHolidayName = "Sunday";
      return true;
    }
    else if(moment(orderETA).isoWeekday() == 6){ // iso Weekday for saturday is 6
      this.weekendHolidayName = "Saturday";
      return true;
    }else return false;
  }

  checkIfETAChanged() {
    let newETADate = moment(this.message.Order_ETA).format("YYYY-MM-DDTHH:mm");
    if(moment(this.copyDetails.Order_ETA, "YYYY-MM-DDTHH:mm").isSame(newETADate)) return false;
    else return true;
  }

  dueDateProcess(finalDueDate, orderETA, dueDate_ETA){
    if(this.dueDate_Range && this.message['Order_ETA_Range']) {
      this.order_ETA_Time = "17:00";
      finalDueDate = this.setOrderETATime(this.dueDate_Range);
    }else {
      if(this.message['Order_ETA_Range']) this.message['Order_ETA_Range'] = null;
      finalDueDate = this.setOrderETATime(orderETA);
    }
    var estShift = momentTimeZone.tz(finalDueDate, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(finalDueDate).utcOffset(moment(finalDueDate).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
     dueDate_ETA = etaInEstTimezone;
    this.sendPrcoessDetails(dueDate_ETA);
  }

  checkForETAValidation(){
    if(this.newOrderForm){
      if(this.message.Order_Status < CONSTANTS.orderStatus.confirmed) return false;
      else if(!this.newOrderForm.valid && this.newOrderForm.touched) return true;
      else false;
    }else false
  }

}