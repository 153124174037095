export const environment = {
  production: true,
  basePath: 'https://server.pippintitle.com',
  basePathSitusAMCService: 'https://situsamc-prod.pippintitle.com',
  access_token: 'xWeh4H0QgqIfM4k5cGW7XQQdIxLXxf9k',
  clientHome:'https://app.pippintitle.com',
  adminHome:  'https://appadmin.pippintitle.com',
  clientBasePath: 'https://server.pippintitle.com',
  qualiaOrgId: '6c5e6488-7187-40b7-9f66-dc374c99ecdb',
  superAdminId: '4c96fd2b-ba23-4451-b1be-f55fac496e87',
  salesMarketingGrpId : '6',
  newClientTagId:'156',
  priorityClientTagId: '104',
  firstOrderTagID: "144",
  prePaidTagId:'134',
  partiallyDelivered: '113',
  absDoubleAssign: '152',
  commitmentTypingTagID: '184',
  QCTagId: 41,
  SearchingTagId: 40,
  SoftProTagId: 191,
  AwaitDlvryTagId: 26,
  AwaitPartDlvryTagId: 187,
  vapidPublicKey :"BG72HPTS_Ji63weU-IoYHz62b6A_fpuuQ124rESQqMBl4kX-pupOoROKxJDznNLfOHqKaOauZJZhhaoHzuTwgfI",
  aws:{
    apiVersion:'2006-03-01',
    region:'us-west-2',
    bucket:'prod.fs.pippintitle',
    credentials:'us-west-2:1a57f7a1-ea10-4f25-86a8-295c8464d9fd'
  },
  nameSearchProductId:1019,
  updateSearchProductId: 5,
  copyChargeProductId:7,
  westFieldBank: "bbf6959f-3c98-4c12-8c0d-70af3a09900f",
  baseProductExpenseId: 97,
  googleFormLink:'https://docs.google.com/forms/d/e/1FAIpQLSf02eutVSozlGGo2ef0lKYvm8Bx1yeqSt-u2ZVM4QY7_Y3skA/',
  texasT00Link:'https://drive.google.com/uc?id=18a4h2c8Oemi8u7Chw6rjE7ARVoL85zAp&export=download',
  runsheetLink:'https://drive.google.com/uc?id=11j0DdlxEuYijnLkMoghz70woneCU8Xyl&export=download',
  qualiaExpenseId: 26,
  qualia_ms_url: 'https://qualia.pippintitle.com',
  wimbaCpnyId: "4c958a8d-f693-45a7-b7cc-a9a73f1da62a",
  wimbaTemplateLink: "https://drive.google.com/uc?id=1fVCCRjrwSwo4B2HCkkUaN6duD_YPcY3s&export=download",
  wimbaproductId: 1074,
  emailIdOptions: ['info@pippintitle.com', 'no-reply@pippintitle.com'],
  noCommitId:31,
  westcorOrgId : '15197577-6f67-423b-a2ab-3cc7bae13745',
  currentOwnerSearch_Product_ID : 1
};
