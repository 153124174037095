import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { CONSTANTS } from "app/app.constants";
import { ClientsService, ConfigService, OrdersService, SchedulingService } from "app/services";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import * as momentTimeZone from "moment-timezone";
import * as moment from "moment";
import { ConfirmComponent } from "../confirm/confirm.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "L",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-pricing-change-prompt",
  templateUrl: "./pricing-change-prompt.component.html",
  styleUrl: "./pricing-change-prompt.component.scss",
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PricingChangePromptComponent {
  @ViewChild("etaDatePicker") etaDatePicker: ElementRef;
  dueDateEtaDate: any;
  dueDateEtaTime: any;
  clientNotifStatus: boolean = false;
  dueDateObj = {};
  orderETAtime = new UntypedFormControl({ value: "", disabled: true }, [
    Validators.required,
  ]);
  errors: any = {};
  getUSDateString: any;
  minDate: any;
  dueDateEvent: any;
  timer: any = [];
  newGrpFrm: UntypedFormGroup;
  messageText = "";
  selectedReason = "";
  selectType: any;
  error: string = "";
  constants = CONSTANTS;
  etaReasonsTypes: any = [];
  // notifyCustomer: boolean = true;
  notifyFeeChange:boolean = true;
  originalDate: any = null;
  originalTime: any = null;
  dueDateCtrl = new UntypedFormControl({ value: "", disabled: false });
  client_mail_text : string = '';
  isConfirmed :boolean =false;
  holidayList= [];
  tinyOptions: any = {
    auto_focus: false,
    branding: false,
    promotion: false,
    skin: "oxide",
    menubar: false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code", "autoresize"],
    placeholder: "Email text body for order delay.",
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
    height: "auto",
    autoresize_bottom_margin: 0, // Removes extra bottom space
    autoresize_overflow_padding: 0,
    autoresize_min_height: 100, // Minimum height required
    autoresize_max_height: 300,
  };
  vendorAmt = '';
  constructor(
    public dialogRef: MatDialogRef<PricingChangePromptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private orderService: OrdersService,
    private config: ConfigService,
    private matDialog: MatDialog,
    private clientService: ClientsService,
    private holidayScheduling: SchedulingService,
  ) {}
  ngOnInit() {
    this.getAvlOrderETAnTime();
    this.originalDate =
      this.data && this.dueDateEtaDate ? this.dueDateEtaDate : null;
    this.originalTime =
      this.data && this.dueDateEtaTime ? this.dueDateEtaTime : null;
    if (this.data) {
      if (!this.dueDateEtaTime) this.dueDateEtaTime = "17:00";
    }
    if (!this.data.reqDate) {
      this.getUSDateString = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });
      this.minDate = new Date(this.getUSDateString);
    } else {
      this.getUSDateString = new Date("01/02/1500").toLocaleString("en-US", {
        timeZone: "America/New_York",
      });
      this.minDate = new Date(this.getUSDateString);
    }
    this.timer.push(
      setTimeout(() => {
        this.getETAChangeReasonTypes();
        if (this.data && this.data.customerId) this.fetchFlagDetails();
      }, 0)
    );
    this.getholidaylistFromLocal();
  }

  dueDateNTime() {
    if (this.orderETAtime.valid) {
      if (this.dueDateEtaDate && this.dueDateEtaTime) {
        if (this.data.RequestDate) {
          this.dueDateObj["req_Date_EST"] = this.dueDateEtaDate;
          this.dueDateObj["req_Time_EST"] = this.dueDateEtaTime;
        } else {
          this.dueDateObj["Due_Date_EST"] = this.dueDateEtaDate;
          this.dueDateObj["Due_TIme_EST"] = this.dueDateEtaTime;
        }
        this.dueDateEvent = this.dueDateObj;
      }
    } else {
      this.dueDateObj["dueDateTimeCtrl"] = this.orderETAtime;
      this.dueDateEvent = this.dueDateObj;
    }
  }

  validateOrderETADateFn(event) {
    if (event.value) {
      if (this.dueDateEtaDate && this.dueDateCtrl.valid)
        this.orderETAtime.enable();
      if (this.data.RequestDate) {
        this.dueDateObj["req_Date_EST"] = this.dueDateEtaDate;
        this.dueDateObj["req_Time_EST"] = this.dueDateEtaTime;
      } else {
        this.dueDateObj["Due_Date_EST"] = this.dueDateEtaDate;
        this.dueDateObj["Due_TIme_EST"] = this.dueDateEtaTime;
      }
      this.dueDateEvent = this.dueDateObj;
    } else {
      if (this.data.RequestDate) {
        this.dueDateObj["req_Date_EST"] = event.value;
        this.dueDateObj["req_Time_EST"] = this.dueDateEtaTime;
      } else {
        this.dueDateObj["Due_Date_EST"] = event.value;
        this.dueDateObj["Due_TIme_EST"] = this.dueDateEtaTime;
      }
      this.dueDateEvent = this.dueDateObj;
    }
  }

  clearForms() {
    this.errors = {};
    if (this.dueDateEtaDate && this.dueDateCtrl.valid)
      this.orderETAtime.enable();
    else this.orderETAtime.disable();
  }

  restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[///\d\s]/.test(input);
  }

  checkYear() {
    if (this.dueDateObj) {
      if (
        (this.dueDateObj["req_Date_EST"] &&
          this.dueDateObj["req_Date_EST"]._.getFullYear() >= "1500") ||
        (this.dueDateObj["Due_Date_EST"] &&
          this.dueDateObj["Due_Date_EST"]._d.getFullYear() >= "1500")
      )
        return true;
      else return false;
    } else return false;
  }

  confirm() {
    console.log("dueDateEvent,", this.dueDateEvent);
    this.error = '';
    if(this.dueDateEvent){
      if(!this.notifyFeeChange && this.clientNotifStatus) 
        this.checkForHoliday({
          eta: this.composeDueDate(),
          reason: this.selectType,
          comment: `${this.selectedReason} - ${this.messageText}`,
          orderId: this.data.orderId,
          emailContent : null,
          notifyCustomer: true,
          clientNotifStatus: this.clientNotifStatus,
          Due_Date_EST: this.dueDateEvent.Due_Date_EST,
          Due_TIme_EST: this.dueDateEvent.Due_TIme_EST,
          dueDateType: 0
        }); 
      else {
        this.checkForHoliday({
          eta: this.composeDueDate(),
          reason: this.selectType,
          comment: this.selectedReason ? `${this.selectedReason} - ${this.messageText}` : this.messageText,
          orderId: this.data.orderId,
          emailContent : null,
          clientNotifStatus: this.clientNotifStatus,
          Due_Date_EST: this.dueDateEvent.Due_Date_EST,
          Due_TIme_EST: this.dueDateEvent.Due_TIme_EST,
          notifyFeeChange: this.notifyFeeChange,
          vendorAmt: this.vendorAmt,
          notifyCustomer: this.clientNotifStatus,
        }); 
      }  
    }
    else {
      this.checkForHoliday({
        comment: this.selectedReason ? `${this.selectedReason} - ${this.messageText}` : this.messageText,
        orderId: this.data.orderId,
        notifyFeeChange: this.notifyFeeChange,
        vendorAmt: this.vendorAmt
      }); 
    }  
  }

  close() {
    this.dialogRef.close(null);
  }

  send(){
    let result = {
      eta: this.composeDueDate(),
      reason: this.selectType,
      comment: this.selectedReason ? `${this.selectedReason} - ${this.messageText}` : this.messageText,
      orderId: this.data.orderId,
      emailContent : this.client_mail_text,
      clientNotifStatus: this.clientNotifStatus,
      Due_Date_EST: this.dueDateEvent.Due_Date_EST,
      Due_TIme_EST: this.dueDateEvent.Due_TIme_EST,
      notifyFeeChange: this.notifyFeeChange,
      vendorAmt: this.vendorAmt
    }
    this.dialogRef.close(result); 
  }

  getETAChangeReasonTypes() {
    this.orderService.getETAChangeReasonTypes().subscribe(
      (res) => {
        if (res) {
          this.etaReasonsTypes = res;
        }
      },
      (err) => {
        this.openErrorPopup("Error while fetching data. Please try again.");
      }
    );
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {
      data: {
        message: msg,
      },
      ...this.config.getDialogOptions(),
    });
  }

  onSelect(event) {
    // if (this.selectType != 999) {  // PT-3628
    var selectedOption = this.etaReasonsTypes.filter(
      (reason) => reason.Order_ETA_Change_Reason_Type_ID == this.selectType
    );
    this.selectedReason = selectedOption[0].Order_ETA_Change_Reason_Text;
    // }
  }

  clearTime() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }


  fetchFlagDetails() {
    this.orderService
      .getOrderUpdatesEmailStatus({ Order_ID : this.data.orderId, Update_Type: 'order_delay' })
      .subscribe((flagDetails) => {
        if (flagDetails) {
          this.clientNotifStatus = flagDetails.status;
        }
      });
  }

  convertMomentTodate(momentVal) {
    if (momentVal) return moment(momentVal).format("MM/DD/YY");
    else return null;
  }

  tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  composeDueDate() {
    if (this.dueDateEvent) {
      if (this.dueDateEvent.Due_Date_EST || this.dueDateEvent.Due_TIme_EST) {
        let order_ETA_Date = this.setOrderETATime(
          this.dueDateEvent.Due_Date_EST
        );
        var estShift = momentTimeZone
          .tz(order_ETA_Date, "America/New_York")
          .isDST()
          ? 4
          : 5;
        var etaInEstTimezone = moment(order_ETA_Date)
          .utcOffset(moment(order_ETA_Date).utcOffset())
          .add(estShift, "hours")
          .format("YYYY-MM-DDTHH:mm");
        return etaInEstTimezone;
      }
      else return null;
    }
    else return null;
  }

  setOrderETATime(orderETA) {
    if (orderETA && orderETA._d) {
      let getOrderEtaHours = this.dueDateEvent.Due_TIme_EST.split(":")[0];
      let getOrderEtaMinutes = this.dueDateEvent.Due_TIme_EST.split(":")[1];
      return orderETA._d.setHours(
        Number(getOrderEtaHours),
        Number(getOrderEtaMinutes)
      );
    } else if (orderETA && !orderETA._d) {
      let getOrderEtaHours = this.dueDateEvent.Due_TIme_EST.split(":")[0];
      let getOrderEtaMinutes = this.dueDateEvent.Due_TIme_EST.split(":")[1];
      return orderETA.setHours(
        Number(getOrderEtaHours),
        Number(getOrderEtaMinutes)
      );
    } else return null;
  }

  getAvlOrderETAnTime() {
    if(this.data['orderETA']) {
      let etaFullDate = new Date(momentTimeZone.tz(this.data['orderETA'], 'America/New_York').format('MM/DD/YYYY HH:mm'));
      let date = new Date(etaFullDate.toDateString());
      this.data['orderETA'] = date;
      let etaHours = etaFullDate.getHours().toString();
      let etaMinutes= etaFullDate.getMinutes().toString();
      if(etaHours.length == 1) etaHours = "0"+etaHours;
      if(etaMinutes.length == 1) etaMinutes = "0"+etaMinutes;
      this.dueDateEtaTime = etaHours+ ":"+etaMinutes;
      this.dueDateEtaDate = this.data['orderETA'];
    } else {
      this.dueDateEtaTime = "17:00" ;
      this.dueDateEtaDate = null;
    }
  }

  checkForDisabled(){
    if(!this.data){
      return true;
    }
    else {
      if(this.data.disableDatePicker)
        return !(this.messageText.length > 0)
      else 
        return !(this.dueDateCtrl.valid && this.orderETAtime.valid && this.messageText.length > 0)
    } 
  }

  generateEmailText(result){
    this.orderService.createpAIEmailDraft(result)
    .subscribe((aiDraft) => {
      console.log("aiDraft", aiDraft);
      this.client_mail_text = aiDraft.text;
      this.isConfirmed = true;
    }, (err) => {
      if(err.msg) this.error = err.msg
      else this.error = 'Unable to create email draft via pAI.'
    })
  }

  setHoliday(holiday){
    this.holidayList = holiday
  }

  getholidaylistFromLocal(){
    let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
    if(allHolidays) this.setHoliday(allHolidays)
    else this.getholidaylist();
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      if(holidays){
        this.setHoliday(holidays)
        sessionStorage.setItem('holidays', JSON.stringify(holidays));
        setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);
      }
    }, (err) =>{
      console.log("Error",err)
    })
  }

  checkForHoliday(result){
      let orderETADate = momentTimeZone.utc(result.eta).tz("America/New_York");
      let checkETAIsHoliday = this.holidayList.findIndex((holiday) => {
        if (
          holiday &&
          momentTimeZone.utc(holiday.Holiday_Date).tz("America/New_York").isSame(orderETADate)
        )
          return true;
        else return false;
      });
      if (checkETAIsHoliday > -1)
        this.showWarningPopup(
          this.holidayList[checkETAIsHoliday].Holiday_Name,
         result
        );
      else if (this.checkETALieOnWeekend(result.eta).length > 0)
        this.showWarningPopup(
          this.checkETALieOnWeekend(result.eta),
          result
        );
      else {
        if(!this.notifyFeeChange && this.clientNotifStatus && this.dueDateEvent) 
          this.generateEmailText(result)
        else
          this.dialogRef.close(result);
      }
    }

    showWarningPopup(holidayName, data){
      let disposable = this.matDialog.open(ConfirmComponent, { data:{
        title:'ETA Note',
        message:"The ETA on this order is a US holiday ("+holidayName+"). Would you like to continue?"
      }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => { 
        if(res){
          if(!this.notifyFeeChange && this.clientNotifStatus && this.dueDateEvent) 
            this.generateEmailText(data)
          else
            this.dialogRef.close(data);
        }
      });
    }


    checkETALieOnWeekend(orderETA) {
        if (momentTimeZone.utc(orderETA).tz("America/New_York").isoWeekday() == 7) {
          // iso Weekday for sunday is 7
          return  "Sunday";
        } else if (momentTimeZone.utc(orderETA).tz("America/New_York").isoWeekday() == 6) {
          // iso Weekday for saturday is 6
          return  "Saturday";
        } else return "";
      }

}
