import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, PagerService, ConfigService, OrdersService, PricingService, StateService, OrganizationService, ScrollService, TagsService, UsersService, UsermessageService, SchedulingService } from '../../services';
import { CONSTANTS } from '../../app.constants'
import { Router } from '@angular/router';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { NewTagComponent } from '../../dialog/new-tag/new-tag.component';
import { DialogService } from "ng2-bootstrap-modal";
import * as moment from 'moment'
import { forkJoin as observableForkJoin, Subject } from 'rxjs';
import * as momentTimeZone from 'moment-timezone';
import { MatMenuTrigger } from '@angular/material/menu';
import { SearchPipe } from '../../pipes/search/search.pipe';
import { DatePipe } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AssignComponent } from '../../dialog/assign/assign.component';
import { EtaChangeReasonComponent } from '../../dialog/eta-change-reason/eta-change-reason.component';
import * as _ from 'underscore';
import { environment } from 'environments/environment';
import { ScreenOrderComponent } from '../../dialog/screen-order/screen-order.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-vendor-orders',
  templateUrl: './vendor-orders.component.html',
  styleUrl: './vendor-orders.component.scss',
  providers: [SearchPipe],
})
export class VendorOrdersComponent implements OnInit{
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  constants = CONSTANTS
  vendorOrders: any = []
  listingType:string;
  orderStatus: any = {};
  ordersToDisplay: any;
  totalCount: number;
  selectedState = '';
  selectedStatus = 'all';
  selectedCounty = '';
  selectedProduct = 0;
  pager: any = {};
  states = [];
  counties = [];
  products = [];
  filterBy = [];
  searchString: string;
  searchInProgress: boolean = false;
  isDemoFlag: boolean = false;
  sortField: string;
  orderBy: string;
  pageNum: number;
  organizations = [];
  allorganizations = [];
  selectedOrgId = '';
  selectedOrgId2 = '';
  scrollEnable: boolean = true;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  getDuplicateOrder = [];
  etaDate: any;
  searchTag: string = ''
  selectedTags = [];
  allActiveTags = [];
  tagsOrderCount = [];
  orderTags = [];
  associatedTags = [];
  ordsToassign=[];
  selectedOrgIds=[];
  searchQuery = new Subject<string>();
  timer: any = [];
  selectedorg = '';
  selecteState = '';
  selectStatus= 'all';
  selectecounty = '';
  selecteprod = 0;
  newClientTagId = '';
  priorityClientTagId = '';
  firstOrdTagId = '';
  prePaidTagId= '';
  holidayList: any;
  absDoubleAssignTagId : string = '';
  order_Status: any;
  orderStatusList = [];
  currTime = new Date().getTime();
  selectAllTags: boolean = false;
  negativeTag: boolean = false;
  intialTag: boolean = true;
  selectedNegTags =[];
  isNegTagSelected: boolean = false;
  allNegativeTags = [];
  allActiveTagsBackUp=[];
  constructor(
    private auth: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    public orderService: OrdersService,
    private preloaderService: PreloaderService,
    private pricingService: PricingService,
    private stateService: StateService,
    private organizationService: OrganizationService,
    private matDialog:MatDialog,
    private scrollService: ScrollService,
    private tagService: TagsService,
    private searchPipe: SearchPipe,
    private _datePipe: DatePipe,
    private userService: UsersService,
    private userMsg: UsermessageService,
    private holidayScheduling: SchedulingService
  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.orderBy = 'ASC';
    if(this.checkIfSearchMaster()) this.sortField = 'Order_Internal_Due_Time';
    else this.sortField = 'Order_ETA';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
   }

  ngOnInit() {
    this.timer.push(setTimeout(() => this.config.setSideBarNode(37), 0));
    if(this.checkIfSearchMaster()) this.listingType = 'my';
    else this.listingType = 'all';
    this.pricingService.getAvailableStateList().subscribe((states) => { this.states = states });
    this.pricingService.getProductList()
    .subscribe((products: any[]) => { 
      this.products = products
    });

    if (this.stateService.getVendorOrders()) this.initializeComponentData(this.stateService.getVendorOrders());
    else {
    this.order_Status=this.selectedStatus;
    this.getAllActiveTags();
    this.getOrders();
    }
    this.orderStatus = this.orderService.getPriorityOrderStatus();
    this.getAdminOrganizationList();
    this.getAllOrganizationList();
    if(this.config.getNewClientTagId()) this.newClientTagId = this.config.getNewClientTagId();
    if(this.config.getPriorityClientTagId()) this.priorityClientTagId = this.config.getPriorityClientTagId();
    if(this.config.getFirstOrdTagId()) this.firstOrdTagId = this.config.getFirstOrdTagId();
    if(this.config.getPrePaidTagId()) this.prePaidTagId = this.config.getPrePaidTagId();
    if(this.config.getAbsDoubleAssignTagId()) this.absDoubleAssignTagId = this.config.getAbsDoubleAssignTagId();
    this.getholidaylistFromLocal();
    this.orderStatusList =  [
      { order_status: 'Received', value: CONSTANTS.orderStatus.received.toString() },
      { order_status: 'Assigned', value: CONSTANTS.orderStatus.assigned.toString() },
      { order_status: 'Confirmed', value: CONSTANTS.orderStatus.confirmed.toString() }, 
      { order_status: 'Processing', value: CONSTANTS.orderStatus.processing.toString() },
      { order_status: 'Completed', value: CONSTANTS.orderStatus.completed.toString() },
      { order_status: 'Cancelled', value: CONSTANTS.orderStatus.cancelled.toString() },
    ]
    
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  isDueDate(order) {
    if (order) {
      if (order.Order_ETA) {
        var momentToday = momentTimeZone.tz('America/New_York').format();  
        var momentAfter2Days = momentTimeZone.tz('America/New_York').add(2, 'days').utc().format(); 
        var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
        if (orderETAMoment.isSameOrAfter(momentToday) && orderETAMoment.isSameOrBefore(momentAfter2Days)) return true;
        else return false;
      }
    }
  }

  isPastDate(order) {
    if (order) {
      if (order.Order_ETA) {
        var momentToday = momentTimeZone.tz('America/New_York').format();  
        var orderETAMoment = momentTimeZone.tz(order.Order_ETA, 'America/New_York');
        if (orderETAMoment.isBefore(momentToday)) return true;
        else return false;
      }
    }
  }

  checkDemoFlag(event){
    if(event.checked == true) this.isDemoFlag = true;
    else  this.isDemoFlag = false;
    this.getOrders();
    }

    getAdminOrganizationList() {
      this.organizationService.addedOrganizationList(this.auth.getUserId())
        .subscribe((result) => {
          if (result) {
            this.organizations = result;
          }
        }, (err) => {
          console.log("err", err);
          // this.errorMsg = err;
        })
    }

    getAllOrganizationList() {
      this.organizationService.getAllOrganizations()
        .subscribe((result) => {
          if (result) {
            this.allorganizations = result;
          }
        }, (err) => {
          console.log("err", err);
          // this.errorMsg = err;
        })
    }

    getAllActiveTags() {
      this.tagService.getAllActiveTagsForListing()
        .subscribe((activeTags) => {
          if (activeTags) {
            this.allActiveTags = activeTags.rows;
            this.allActiveTags = []
          if (this.selectedTags.length > 0 || this.allNegativeTags.length>0) {
            activeTags.rows.forEach((chip) => {
              let index=this.allNegativeTags.indexOf(chip.Tag_ID);
              if(index == -1){
                index = this.selectedTags.indexOf(chip.Tag_ID);
                if (index > -1) chip.selected = true;
                this.allActiveTags.push(chip);
              }  
            })
          }else this.allActiveTags=activeTags.rows; 
            if(this.tagsOrderCount.length > 0) this.addOrderCountToTags(this.tagsOrderCount);
          }
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    }

    getOrderTags(order){
      this.orderService.getOrderTags(order.Order_ID)
      .subscribe((ordTags) => {
        if (ordTags) {
          let getAllTagID = "";
          ordTags.forEach((tag) => {
            getAllTagID += "," + tag.Tag_ID;
          })
          order.Order_Tags = getAllTagID;
          order.Tag_Names = ordTags;
        }
        else order.Tag_Names = [];
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
     }

     onChipSelect(chip,i) {
    if(this.selectAllTags && this.negativeTag && Boolean(chip.selected)){
      this.onNegSelectionChange(chip, i);
    }else{
        chip.selected = !chip.selected;
        this.selectAllTags = true;
        this.negativeTag = true;
      this.onSelectionChange(chip);
    }}
    onNegSelectionChange(chip,i){
      var index = this.selectedNegTags.indexOf(chip.Tag_ID);
      if (index > -1) {
        this.selectedNegTags.splice(index, 1);
        this.allActiveTags.push(chip)
        chip.selected = !chip.selected
        if(this.allNegativeTags.length>0) this.allNegativeTags.splice(i,1)
        this.isNegTagSelected = true;
        this.pageNum = 1;
        this.setScrollResetPos()
        this.getOrders();
      } else{ 
        this.selectedNegTags.push(chip.Tag_ID);
        this.allActiveTags.splice(i,1)
        this.allNegativeTags.push(chip)
        this.allNegativeTags.map((chip) => {
            chip.deselected = true
          })
        this.isNegTagSelected = false;
        this.onSelectionChange(chip);
      }
    }

    onSelectionChange(chip) {
      var index = this.selectedTags.indexOf(chip.Tag_ID);
      if (index > -1) {
        this.selectedTags.splice(index, 1);
      } else this.selectedTags.push(chip.Tag_ID);
      this.pageNum = 1;
      this.setScrollResetPos()
      this.getOrders();
    }

    getUserRole() {
      return Number(this.auth.getUserRole())
    }

    detectTypeChange(event) {
      this.deselectAllChips();
      this.pageNum = 1;
      this.setScrollResetPos();
      this.getOrders();
    }

    getCountyList(event) {
      if (event == 'all') {
        this.selectedCounty = 'all';
        if(this.selecteState != this.selectedState){
          this.selecteState='';
          this.applyFilter(true)
        }
        else this.applyFilter();
      }
      if (event.length != 0 && event != 'all') {
        this.pricingService.getAvailableCountyList(this.selectedState).subscribe((counties) => { this.counties = counties });
        if(this.selecteState != this.selectedState){
          this.selecteState='';
          this.applyFilter(true);
        }
        else this.applyFilter();
    }
    }

    applyFilter(filterFlag = false) {
      if(!filterFlag){
        if(((this.selectedOrgId != this.selectedorg) || (this.selectecounty != this.selectedCounty) || (this.selecteprod!=this.selectedProduct) || (this.selectStatus != this.selectedStatus))) {
              this.deselectAllChips();}
      }else this.deselectAllChips();
      this.setScrollResetPos();
      this.pageNum = 1;
      this.filterBy = [];
      if (this.selectedOrgId && this.selectedOrgId != 'all') this.filterBy.push({ Organization_ID: this.selectedOrgId })
      if (this.selectedOrgId2 && this.selectedOrgId2 != 'all') this.filterBy.push({ Organization_ID: this.selectedOrgId2 })
      if (this.selectedState && this.selectedState != 'all') this.filterBy.push({ Property_State_Abbr: this.selectedState })
      if (this.selectedCounty && this.selectedCounty != 'all') this.filterBy.push({ Property_County: this.selectedCounty })
      if (this.selectedProduct && this.selectedProduct != -1) this.filterBy.push({ Product_Description: this.selectedProduct })
      if (this.selectedStatus) this.order_Status=this.selectedStatus;
      if(!filterFlag){
        if(((this.selectedOrgId != this.selectedorg) || (this.selectecounty != this.selectedCounty) || (this.selecteprod!=this.selectedProduct) || 
        (this.selectStatus != this.selectedStatus))) {
          if(this.selectedOrgId != this.selectedorg) this.selectedorg='';
          if(this.selectecounty != this.selectedCounty) this.selectecounty='';
          if(this.selecteprod != this.selectedProduct) this.selecteprod=0; 
          if(this.selectStatus != this.selectedStatus) this.selectStatus=this.selectedStatus;
          this.getOrders();
        }
      }else this.getOrders();
    }

    setSort(field: string) {
      this.pageNum = 1;
      if (field == this.sortField) {
        if (this.orderBy == 'ASC') this.orderBy = 'DESC';
        else this.orderBy = 'ASC';
      } else {
        this.sortField = field;
        this.orderBy = 'ASC';
      }
      this.getOrders();
    }

    setSortDefaultAsDec(field: string) {
      this.pageNum = 1;
      if (field == this.sortField) {
        if (this.orderBy == 'ASC') this.orderBy = 'DESC';
        else this.orderBy = 'ASC';
      } else {
        this.sortField = field;
        this.orderBy = 'DESC';
      }
      this.getOrders();
    } 
    
    setDisplayOrders() {
      let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
      if (this.totalCount != undefined) this.ordersToDisplay = this.vendorOrders.slice(start, this.totalCount);
      if (this.ordersToDisplay.length > this.config.getNumRecordsPerPage()) this.ordersToDisplay = this.ordersToDisplay.slice(0, this.config.getNumRecordsPerPage());
    }

    getOrders() {
      if(this.pageNum == 1) {
        this.stateService.setVendorOrders(this);
      this.orderService.getVendorOrders(this.listingType, this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags, this.isDemoFlag)
      .subscribe((result) => {
          if(result){
            this.currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
            this.tagsOrderCount = result['tagsWithOrderCount'];
            this.vendorOrders = result['rows'];
            this.totalCount = result['count'];
            this.ordsToassign=[];
            this.selectedOrgIds=[];
            this.addCheckedProperty(this.vendorOrders);   
            this.addOrderCountToTags(result['tagsWithOrderCount']);
            this.getPriorityData(this.totalCount)
            this.getScrollPosData()
          }
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
      }else this.getRetainData()
    }

    getRetainData() {
      var ObsCollection = [];
      let pageSize = this.pageNum * this.config.getNumOrdRecordsPerPage();
      ObsCollection.push(this.orderService.getVendorOrders(this.listingType, 1, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags, this.isDemoFlag, pageSize))
      // for (var i = 1; i <= this.pageNum; i++) {
      //   ObsCollection.push(this.orderService.getVendorOrders(this.listingType, i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags, this.isDemoFlag, this.order_Status))
      // }
      observableForkJoin(ObsCollection)
        .subscribe((retainedPriorityOrd) => {
          if (retainedPriorityOrd) {
            var totalOrders = [];
            for (var i = 0; i < retainedPriorityOrd.length; i++) {
              this.stateService.setVendorOrders(this)
              totalOrders = totalOrders.concat(retainedPriorityOrd[i]['rows']);
            }
          this.tagsOrderCount = retainedPriorityOrd[0]['tagsWithOrderCount'];
          this.vendorOrders = totalOrders;
          this.ordsToassign=[];
          this.selectedOrgIds=[];
          this.addCheckedProperty(this.vendorOrders); 
          this.totalCount = retainedPriorityOrd[0]['count'];
          this.addOrderCountToTags(retainedPriorityOrd[0]['tagsWithOrderCount']);
            this.getPriorityData(this.totalCount)
            this.getScrollPosData()
          }
        })
    }

    getSearchString() {
      var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
      if (format.test(this.searchString)) {
        return '';
      } else return this.searchString;
    }

    searchField(search){
      this.scrollService.setScrollPosPriorityOrder(0);
      var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
      search = search.trim();
      this.searchString = search;
      if(!format.test(this.searchString)&& this.searchString==" "){
        this.searchString='';
        this.getOrders();
      }else this.findOrders();
    }

    findOrders() {
      this.pageNum = 1;
      this.preloaderService.setSearchSpin();
      this.searchInProgress = true;
      this.getOrders();
    }

    goToOrderDetails(orderId) {
      if(this.checkAdminType()){
        var getPos = document.documentElement.scrollTop;
        this.scrollService.setScrollPosPriorityOrder(getPos);
        this.router.navigate(['/orders/' + orderId + '/view'])
      }
    }

  initializeComponentData(data) {
    let orderState;
    console.log("(data.listingType == my)",(data.listingType == "my"))
    if(data.listingType == "my"){
      let assign = this.checkIfSearchMaster();
      orderState={
        orderStatus: "priority",
        adminID: this.auth.getUserId(),
        org_ID: data.selectedOrgId,
        state: data.selectedState,
        county: data.selectedCounty,
        prod: data.selectedProduct,
        orderAssign: assign,
        Orders_Status: data.selectedStatus
      }
    }else{
      orderState={
        orderStatus: "priority",
        org_ID: data.selectedOrgId,
        state: data.selectedState,
        county: data.selectedCounty,
        prod: data.selectedProduct,
        Orders_Status: data.selectedStatus
 }
    }
    this.orderService.getAssociatedTags(orderState)
    .subscribe((tags)=>{
      console.log("tags",tags)
    let filterdata = _.intersection(data.selectedTags, tags)
    this.selectedTags = filterdata;
    this.selectedorg = data.selectedOrgId;
    this.selecteState  = data.selectedState;
    this.selectStatus = data.selectedStatus
    this.selectecounty  = data.selectedCounty;
    this.selecteprod = data.selectedProduct;
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.selectedOrgId = data.selectedOrgId;
    this.selectedOrgId2 = data.selectedOrgId2;
    this.selectedState = data.selectedState;
    this.selectedStatus = data.selectedStatus;
    this.selectedCounty = data.selectedCounty;
    this.selectedProduct = data.selectedProduct;
    this.filterBy = data.filterBy;
    this.counties = data.counties;
    // this.products = data.products;
    this.totalCount = data.totalCount;
    this.listingType = data.listingType;
    this.ordersToDisplay = data.ordersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.isDemoFlag = data.isDemoFlag;
    this.order_Status = data.selectedStatus
    this.selectAllTags = data.selectAllTags;
    this.allNegativeTags = data.allNegativeTags;
    this.negativeTag = data.negativeTag;
    this.selectedNegTags = data.selectedNegTags;
    this.isNegTagSelected = data.isNegTagSelected;
    this.intialTag = data.intialTag;
    this.allActiveTags = data.allActiveTags 
    // this.getAllActiveTags();
    this.getOrders();
    })
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleEvent(event){
    if(event ==false){
    if(this.totalCount > this.config.getNumRecordsPerPage()){
      if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
      else this.scrollEnable = true;
    }
  }else this.scrollEnable = true;
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.orderService.getVendorOrders(this.listingType, this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags, this.isDemoFlag)
      .subscribe((result) => {
          if (result) {
            this.totalCount = result['count'];
            if (this.totalCount > this.vendorOrders.length) {
              this.addCheckedProperty(result['rows']);   
              this.vendorOrders = this.vendorOrders.concat(result['rows']);
              this.pager = this.pagerService.getPagerOrders(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  getPriorityData(result) {
    this.config.setClearTimeout();
    this.setDisplayOrders();
    // this.pager = this.pagerService.getPagerOrders(result, this.pageNum);

    this.pager = this.pagerService.getPagerOrders(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }


  getScrollPosData() {
    if (this.scrollService.getScrollPosPriorityOrder()) {
      var x = this.scrollService.getScrollPosPriorityOrder()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  setScrollResetPos() {
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosInprocOrd(x);
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }

  clearTags(){
    this.selectedTags = [];
    this.allActiveTags.map((chip) => chip.selected = false);
    this.selectedNegTags =[];
    this.allNegativeTags.forEach((negTag)=>{
      negTag.deselected = false;
      negTag.selected = false;
      this.allActiveTags.push(negTag)
    })
    this.allNegativeTags = [];
  }
  resetFilter(){
    this.selectedState = '';
    this.selectedStatus = 'all';
    this.selectedCounty = '';
    this.selectedProduct = 0;
    this.selectedOrgId = '';
    this.selectedOrgId2 = '';
    this.searchString = '';
    if(this.checkIfSearchMaster()) this.listingType = 'my';
    else this.listingType = 'all';
    this.filterBy = [];
    this.counties = [];
    this.isDemoFlag = false;
    this.selectAllTags = false;
    this.negativeTag = false;
    this.isNegTagSelected = false;
    this.order_Status=this.selectedStatus;
    this.clearTags()
    this.getOrders()
  }
  
  deselctAllNegTagsChip(){
    // this.selectedNegTags =[];
    // this.allNegativeTags.map((chip) => chip.deselected = false)
    // this.allNegativeTags.forEach((negTag)=>{
    //   this.allActiveTags.push(negTag)
    // })
    // this.allNegativeTags = [];
  } 
  checkIfFilterPresent(){
    let filterPresentType
    if(this.checkIfSearchMaster()) filterPresentType = "my";
    else filterPresentType = "all";
    if (this.selectedOrgId == 'all' || this.selectedOrgId2 == 'all' || this.selectedState == 'all' || this.selectedProduct == -1 || this.searchString ||
      (this.filterBy.length > 0) || !this.listingType.includes(filterPresentType) || (this.selectedTags.length > 0) || (this.selectedNegTags.length > 0) || this.isDemoFlag || this.selectedStatus != 'all') return true
    else return false
  }

  openTagMenu(event, order) {
    event.stopPropagation();
    this.assignPreselectedTags(order);
  }

  emitOrdTagCreateEvt() {
    this.getAllActiveTags();
    this.getOrders();
  }

  openNewTagModal(order) {
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID: order.Order_ID }
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
        this.tagsOrderCount = [];
        this.getAllActiveTags();   
        if(selectedTagsCopy.length > 0) this.getOrders();
        else {
          this.getOrderTags(order);
          this.getOrders();
        }
      }
    });
  }

  getAvailableTags(order) {
    let preselectedTags = [];
    let availableTags = [];
    if (order.Tag_Names) preselectedTags = order.Tag_Names;
    for (var i = 0, len = this.allActiveTags.length; i < len; i++) {
      if (preselectedTags.findIndex((select => this.allActiveTags[i].Tag_ID == select.Tag_ID)) == -1) {
        if(this.allActiveTags[i].Tag_ID != this.newClientTagId && this.allActiveTags[i].Tag_ID != this.priorityClientTagId && this.allActiveTags[i].Tag_ID != this.firstOrdTagId && this.allActiveTags[i].Tag_ID != this.prePaidTagId) availableTags.push(this.allActiveTags[i]);
      }
    }
    return availableTags;
  }

  assignPreselectedTags(order) {
    if (order.Tag_Names) this.associatedTags = order.Tag_Names
  }

  menuClosed(order) {
    this.searchTag = '';    
  }

  menuOpened(order) {
    this.orderTags = [];
    this.searchTag = '';
    this.getAvailableTags(order).map((tag) => tag.on = false)
    this.associatedTags= [];
  }

  updateOrderTags(order, associatedTags) {
    if (this.selectedTags.length > 0) this.deselectAllChips();
    this.setScrollResetPos();
    this.orderService.updateOrderTags({ Order_ID: order.Order_ID, Order_Tags: this.orderTags.concat(associatedTags) }).subscribe(() => {
      this.matMenuTrigger.closeMenu();
      this.getOrders();
    })
  }

  checkIfAlreadyCompleted(orderStatusDet){
    let completedOrdIndex = orderStatusDet.findIndex((data) => data.Status_New === CONSTANTS.orderStatus.completed)
    if(completedOrdIndex > -1) return true;
    else return false;
  }

  modifyOrderTags(event, tag, order) {
    if(tag && tag.Tag_ID ==  this.config.getPartiallyDeliveredTagId()){      
      this.orderService.getAllOrderStatus(order.Order_ID)
      .subscribe((orderStatusDet) => {
        if(this.checkIfAlreadyCompleted(orderStatusDet)) this.modifyOrderTagsRequest(event, tag, order);
        else {
          this.openErrorPopup('This tag can only be assigned after an order has been completed at least once.');
          event.source.checked = false;
          this.matMenuTrigger.closeMenu();
        }
      })      
    }
    else this.modifyOrderTagsRequest(event, tag, order);    
  }

  modifyOrderTagsRequest(event, tag, order){
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    if (this.selectedTags.length > 0) this.deselectAllChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();

     var isTagToAdd:boolean;
     if(event.checked) isTagToAdd = true;
     else isTagToAdd = false;
      this.orderService.modifyOrderTags({ Order_ID: order.Order_ID, Order_Tag: tag, isTagToAdd: isTagToAdd})
      .subscribe((orderTagdata) => {
        order.Tag_Names=orderTagdata.Tag_Names;
        order.Order_Tags=orderTagdata.Order_Tags;
        if(selectedTagsCopy.length > 0) {
          this.matMenuTrigger.closeMenu();
          this.getOrders();
        }
        else {
          this.getOrders();
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderTagging);
        this.matMenuTrigger.closeMenu();
    })
   }


   emitOrdTagChangeEvt() {
    var selectedTagsCopy = Object.assign([], this.selectedTags);
   if (this.selectedTags.length > 0) this.deselectAllChips();
   if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
   if (selectedTagsCopy.length > 0) {
     this.getOrders();
   }
   else this.getTagCount();
    // removed because no api found to get tag count for vendor tags, it is utilizing by api of priority tags
  // Needs to re-instated again after API creation. Service functions are already there, just need to build root and controller

  }

   deselectAllChips() {
    this.selectedTags = [];
    this.allActiveTags.map((chip) => chip.selected = false);
  }

  clearSearchTag() {
    this.searchTag = '';
  }

  onSelectedOptionsChange(order) {
    this.timer.push(setTimeout(() => {
      this.orderTags = this.getAvailableTags(order).filter(tag => tag.on);
    }))
  }
 

  createExcel() {
    this.orderService.createVendorOrdsExcelReport(this.filterBy, this.searchString, this.sortField, this.orderBy, this.selectedTags,this.selectedNegTags, this.listingType, this.isDemoFlag)
      .subscribe((blobData) => {
        if (blobData) this.composeAndDwnld(blobData);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }


  composeAndDwnld(blobData) {
    let file = new Blob([blobData], { type: 'application/octet-stream' });
   var fileUrl = URL.createObjectURL(file);
   var link = document.createElement("a");
   link.setAttribute("href", fileUrl);
   link.setAttribute("download", "Report.xlsx");
   link.style.visibility = "hidden";
   document.body.appendChild(link);
   link.click();
   document.body.removeChild(link);
 }

 csvExport() {

  const fields = [
    {
      label: 'Sl No',
      value: 'serialNumber'
    },
    {
      label: 'Pippin Order Number',
      value: 'Order_ID'
    },
    {
      label: 'Client Reference',
      value: 'File_ID'
    },
    {
      label: 'Client',
      value: (row, User_First_Name) => {
        if (row.Organization_Name) return row.Client_User_Full_Name+ ' - ' + row.Organization_Name;
        else return row.Client_User_Full_Name;
      }
    },
    {
      label: 'Qualia Order',
      value: 'qualiaOrder'
    },
    {
      label: 'Owner/Seller',
      value: 'Property_First_Name'
    },
    {
      label: 'Property Address',
      value: (row, Property_Address_1) => {
        var completeAddress = '';
        if (row.Property_Address_1) completeAddress += row.Property_Address_1
        else return completeAddress;
        if (row.Property_Address_2) {
          if (completeAddress) completeAddress += '\t'
          completeAddress += row.Property_Address_2
        }
        if (row.Property_City) {
          if (completeAddress) completeAddress += '\t'
          completeAddress += row.Property_City + ', ' + row.Property_State_Abbr + ' ' + row.Property_ZipCode
        }
        if (!row.Property_City) {
          if (completeAddress) completeAddress += '\t'
          completeAddress += row.Property_State_Abbr + ' ' + row.Property_ZipCode
        }
        return completeAddress;
      }
    },
    {
      label: 'State',
      value: 'Property_State_Abbr'
    },
    {
      label: 'County',
      value: 'Property_County'
    },
    {
      label: 'Product Type',
      value: 'Product_Description'
    },
    {
      label: 'Submission Date',
      value: (row, Order_Creation_Date) => {
        return this._datePipe.transform(row.Order_Creation_Date_EST, 'MMM d, yyyy');
      }
    },
    {
      label: 'Submission Time (ET)',
      value: (row, Order_Creation_Date) => {
        return this._datePipe.transform(row.Order_Creation_Date_EST, 'h:mm a');
      }
    },
    {
      label: 'Due Date',
      value: (row, Order_ETA) => {
        if (row.Order_ETA) return this._datePipe.transform(row.Order_ETA_EST, 'MMM d, yyyy');
        else return '';
      }
    },
    {
      label: 'Due Time (ET)',
      value: (row, Order_ETA) => {
        if (row.Order_ETA) return this._datePipe.transform(row.Order_ETA_EST, 'h:mm a');
        else return '';
      }
    },
    {
      label: 'Handled By',
      value: (row, Order_Admin_ID) => {
        if (row.Order_Admin_ID) return `${row.Admin_User_Full_Name}`;
        else return '';
      }
    },
    {
      label: 'Assigned Duration',
      value: (row, Order_Admin_ID) => {
        const duration = this.orderService.getAssignedDuration(row);
        return duration.replace(/[\(\)]/g, ''); // Remove parentheses
      }
    },
    {
      label: 'Searcher',
      value: (row, Order_Admin_ID) => {
        if (row.Order_Admin_ID && row.Abstrator_Price_ID) return 'Abstractor';
        else if (row.Order_Admin_ID) return 'Pippin';
        else return '';
      }
    },
    {
      label: 'Search Master Type',
      value: (row, Order_Admin_ID) => {
        if(row.Order_Admin_ID) {
          if(row.Admin_User_Role == CONSTANTS.userRole.searchMaster){
            if(row.Admin_User_Type == CONSTANTS.userType.internalSM || row.Admin_User_Type == null) return "Internal";
            else if(row.Admin_User_Type == CONSTANTS.userType.externalSM) return "External"
          }
        } else return ""; 
      } 
    },
    {
      label: 'Assigned Abstractor',
      value: (row, Abstrator_ID) => {
        if (row.Order_Admin_ID && row.Abstrator_Price_ID) return row.Abstractor_User_Full_Name;
        else return '';
      }
    },
    {
      label: 'Priority Order',
      value: (row, Order_Creation_Date) => {
        if(row.Order_Priority == CONSTANTS.orderPriority.level2) return 'Yes';
        else return '';
      }
    },
    {
      label: 'Original ETA Date',
      value: (row, Order_Original_ETA) => {
        if (row.Order_Original_ETA) return this._datePipe.transform(row.Order_Original_ETA_EST, 'MMM d, yyyy');
        else return '';
      }
    },
    {
      label: 'Original ETA Time (ET)',
      value: (row, Order_Original_ETA) => {
        if (row.Order_Original_ETA) return this._datePipe.transform(row.Order_Original_ETA_EST, 'h:mm a');
        else return '';
      }
    },
    {
      label: 'Current Task Due Date',
      value: (row, Order_Internal_Due_Time) => {
        if (row.Order_Internal_Due_Time) return this._datePipe.transform(row.Order_Internal_Due_Time_EST, 'MMM d, yyyy');
        else return '';
      }
    },
    {
      label: 'Current Task Due Time (ET)',
      value: (row, Order_Internal_Due_Time) => {
        if (row.Order_Internal_Due_Time) return this._datePipe.transform(row.Order_Internal_Due_Time_EST, 'h:mm a');
        else return '';
      }
    },
    {
      label: 'Tags',
      value: 'Order_Tag_Name'
    },
    // {
    //   label: 'ETA Delay Reason',
    //   value: (row, Order_ETA_Change_Reason) => {
    //     if (row.Order_ETA_Change_Reason) return row.Order_ETA_Change_Reason;
    //     else return '';
    //   }
    // },
    {
      label : 'Latest Note',
      value: (row, Latest_Note) => {
        if (row.Latest_Note) return row.Latest_Note;
        else return '';
      },
    }
  ];

  if(this.checkForSuperRole()){
    fields.push({
      label: 'Price',
      value: (row, Order_Custom_Price) => {
        if(row.Order_Custom_Price) return row.Order_Custom_Price;
        else return '';
      }
    })
  }

  if (this.checkIfSearchMaster()) {
    var costIndex = fields.findIndex(x => x.label === "Price");
    if(costIndex > -1) fields.splice(costIndex, 1);
  }

  this.orderService.downloadCSVfile(fields, this.vendorOrders, this.allActiveTags)
}

checkDaylight(date) {
  if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
  else return 'EST'
}

addOrderCountToTags(tagsOrderCount) {
  tagsOrderCount.forEach((chip) => {
      this.allActiveTags.forEach((activeChip)=>{
          if(chip.Tag_ID == activeChip.Tag_ID) activeChip.Order_Count = chip.Order_Count;
      })
      this.allNegativeTags.forEach((negativeChip) => {
        if (chip.Tag_ID == negativeChip.Tag_ID) negativeChip.Order_Count = chip.Order_Count;
      })
  })
}

trackOrders(order){
  return order.Order_ID;
}

changeAssignee(ordDetails){
  var data = {};
  if (ordDetails.Organization_ID) data['Organization_ID'] = ordDetails.Organization_ID;
    // this.userService.getAllOrgAdminsToOrderAssign(data).subscribe((adminData) => {
    //   this.userService.getAllOrgSearchMasterToOrderAsign(data).subscribe((searchData) => {
      this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
        let disposable = this.matDialog.open(AssignComponent, {data:{
          title: 'Assign order',
          message: {
            Order_ETA: ordDetails.Order_ETA ? ordDetails.Order_ETA: new Date(),
            admins: adminData['admins'],
            Search_Masters: adminData['searchers'],
            Current_Admin: ordDetails.Order_Admin_ID,
            Order_ID: ordDetails.Order_ID,
            Customer_ID: ordDetails.Customer_ID
          },
          listOfHolidays:this.holidayList
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }
              let additionalInfo = {
                Notify_Customer: res.hasOwnProperty('Notify_Customer') ? res.Notify_Customer : null,
                Client_Notif_Status: res.Client_Notif_Status ? res.Client_Notif_Status : null,
                Email_Body: res.Email_Body ? res.Email_Body : null,
              }
              this.orderService.changeOrderAssign(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range, additionalInfo)
                .subscribe((data) => {
                  this.getOrders()
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
            }
          });
      }, (err) => { 
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })    
}

getFontColor(hexColor){
  return this.tagService.getFontColor(hexColor)  
}

checkIfNotExternalSM(){
  if(Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.externalSM) return false;
  else return true;
}

orderAssignee(ordDetails) {
  var data = {};
  if (ordDetails.Organization_ID) data['Organization_ID'] = ordDetails.Organization_ID;
  // this.userService.getAllOrgAdminsToOrderAssign(data).subscribe((adminData) => {
  //   this.userService.getAllOrgSearchMasterToOrderAsign(data).subscribe((searchData) => {
    this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
      if (ordDetails.Order_Status == CONSTANTS.orderStatus.received || ordDetails.Order_Status == CONSTANTS.orderStatus.assigned) {

        let dataToScreener = {
          admins: adminData['admins'],
          Search_Masters: adminData['searchers'],
          Current_Admin: ordDetails.Order_Admin_ID
        }
        let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
          title: 'Screen order',
          message: dataToScreener
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }
              if(res.admin && res.getTimeToCompl){
                if(ordDetails.Order_Admin_ID){
                  this.orderService.changeScreener(ordDetails.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
                  .subscribe((data) => {
                  this.getOrders();
                  }, (err) => {
                    console.log(err);
                    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                  })
                }else{
                  this.orderService.screenOrder(ordDetails.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
                  .subscribe((data) => {
                    this.getOrders();
                  }, (err) => {
                    console.log(err);
                    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                  })
                }
              }
              else{
                  this.orderService.unassignOrder(ordDetails.Order_ID,res.msgText,msgType,ordDetails.Order_Status).subscribe((data)=>{
                    this.getOrders()
                  }, (err) => {
                    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
              }
            }
          });
      } else {
        var dataToDialog = {
          Order_ETA: ordDetails.Order_ETA,
          admins: adminData['admins'],
          Search_Masters: adminData['searchers'],
          Order_ID: ordDetails.Order_ID,
          Customer_ID: ordDetails.Customer_ID
        };
        if (ordDetails.Order_Admin_ID) dataToDialog['Current_Admin'] = ordDetails.Order_Admin_ID;
        let disposable = this.matDialog.open(AssignComponent, {data:{
          title: 'Assign order',
          message: dataToDialog,
          listOfHolidays:this.holidayList
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }
              if(res.admin && res.getTimeToCompl){
                if (ordDetails.Order_Admin_ID) {
                  // if(this.isETAChanged(res.Order_ETA, ordDetails) && ordDetails.Order_Status >= CONSTANTS.orderStatus.confirmed) {
                  //   this.openETAChangeMsgPopup(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range)
                  // } else {
                  //   this.changeOrderAssignee(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range, '', '')
                  // }
                  this.changeOrderAssignee(
                    ordDetails.Order_ID, 
                    res.admin, res.Order_ETA, 
                    res.msgText, msgType, 
                    res.getTimeToCompl, 
                    res.Order_ETA_Range, 
                    res.Order_ETA_Comment ? res.Order_ETA_Comment : '',
                    res.Order_ETA_Change_Reason_Type_ID ? res.Order_ETA_Change_Reason_Type_ID : '',
                    res.hasOwnProperty('Notify_Customer') ? res.Notify_Customer : null,
                    res.Client_Notif_Status ? res.Client_Notif_Status : null,
                    res.Email_Body ? res.Email_Body : null,
                  )
                } else {
                  // if(this.isETAChanged(res.Order_ETA, ordDetails) && ordDetails.Order_Status >= CONSTANTS.orderStatus.confirmed) {
                  //   this.openETAChangeMsgInterface(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range)
                  // } else {
                  //   this.assignOrder(ordDetails.Order_ID, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range, '', '')
                  // }
                  this.assignOrder(
                    ordDetails.Order_ID, 
                    res.admin, 
                    res.Order_ETA, 
                    res.msgText, 
                    msgType, 
                    res.getTimeToCompl, 
                    res.Order_ETA_Range, 
                    res.Order_ETA_Comment ? res.Order_ETA_Comment : '',
                    res.Order_ETA_Change_Reason_Type_ID ? res.Order_ETA_Change_Reason_Type_ID : '',
                    res.hasOwnProperty('Notify_Customer') ? res.Notify_Customer : null,
                    res.Client_Notif_Status ? res.Client_Notif_Status : null,
                    res.Email_Body ? res.Email_Body : null,
                  )
                }
              }
              else{
                  this.orderService.unassignOrder(ordDetails.Order_ID,res.msgText,msgType,ordDetails.Order_Status).subscribe((data)=>{
                    this.getOrders()
                  }, (err) => {
                    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
              }
            }
          })
      };
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
    })
}

isETAChanged(eta, ordDetails) {   
  var oldETA;
  var newETA;
  if(ordDetails.Order_ETA) { 
    let estShift = momentTimeZone.tz(ordDetails.Order_ETA, 'America/New_York').isDST() ? 4 : 5;
    oldETA = momentTimeZone.tz(ordDetails.Order_ETA,'America/New_York').add(estShift, 'hours').format('MM-DD-YYYYTHH:mm');
  } else oldETA = null;
  if(eta) newETA = momentTimeZone.tz(eta, 'America/New_York').format('MM-DD-YYYYTHH:mm');
  else newETA = null;
  if(oldETA != newETA) return true;
  else return false;
}

// openETAChangeMsgPopup(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange) {
//   let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
//   }, ...{ closeByClickingOutside: false }}).afterClosed()
//     .subscribe((res) => {
//       if(res.state) { 
//         this.changeOrderAssignee(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, res.Message_Text, res.Order_ETA_Change_Reason_Type_ID);
//       }
//     });
// }

changeOrderAssignee(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID,notifCust,
  clinNotifSts,
  emailbody) {
  let additionalInfo = {
    Notify_Customer: notifCust,
    Client_Notif_Status: clinNotifSts,
    Email_Body: emailbody,
  }
  this.orderService.changeOrderAssign(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, additionalInfo, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID)
  .subscribe((data) => {
    this.getOrders()
  }, (err) => {
    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
  })
}

// openETAChangeMsgInterface(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange) {
//   let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
//   }, ...{ closeByClickingOutside: false }}).afterClosed()
//     .subscribe((res) => {
//       if(res.state) { 
//         this.assignOrder(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, res.Message_Text, res.Order_ETA_Change_Reason_Type_ID);
//       }
//     });
// }

assignOrder(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID,notifCust,
  clinNotifSts,
  emailbody) {
  let additionalInfo = {
    Notify_Customer: notifCust,
    Client_Notif_Status: clinNotifSts,
    Email_Body: emailbody,
  }
  this.orderService.initOrderProcess(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID, additionalInfo)
    .subscribe((data) => {
      this.getOrders()
    }, (err) => {
      this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
    })
}

onOrdSelectEvt(data) {
  this.onOrdSelect(data.event, data.ordId, data.order, data.checked)
}

onOrdSelect(event, ordId, order, checked) {
  event.stopPropagation();
  if(this.ordsToassign.includes(ordId)){
    var index = this.ordsToassign.indexOf(ordId);
    if (index > -1) {
      this.ordsToassign.splice(index, 1);
    }
  } else {
   this.ordsToassign.push(ordId);      
  }
  if(checked) {
    var index = this.selectedOrgIds.findIndex((filterItem) => filterItem.Order_ID === order.Order_ID);
    if(index > -1) {
      this.selectedOrgIds.splice(index, 1);
    }
  } else {
    this.selectedOrgIds.push(order);   
  }
  
}

assignSelectedOrds() {
  var data = {};
  if(this.selectedOrgIds.length > 0) data['Organization_IDs'] = this.selectedOrgIds;
  this.userService.getCommonAdminsNSearchers(data).subscribe((list) => {
      var dataToDialog = {
        Order_ETA: null,
        Current_Admin:null,
        admins: list.adminList,
        Search_Masters: list.searcherList,
        Multi_Orders: true,
        Order_ID: null,
        Customer_ID: null
      };
      let disposable = this.matDialog.open(AssignComponent, {data:{
        title: 'Assign order',
        message: dataToDialog,
        listOfHolidays:this.holidayList
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          if (res) {
            let msgType;
            if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
            else {
              res.msgText = ""
              msgType = CONSTANTS.messageType.internalNotes;
            }
              this.orderService.assignBulkOrds(this.ordsToassign, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range,false,true)
                .subscribe((data) => {
                  this.ordsToassign=[];
                  this.selectedOrgIds=[];
                  this.getOrders()
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
          }
        });
  }, (err) =>{
    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
  })
}

addCheckedProperty(orders) {    
  orders.map((order) => {
    order.checked=false;
    return order;
  })
} 

removeDuplicateOrg(orgIDs) {
  return orgIDs.filter((orgId, index) => orgIDs.indexOf(orgId) === index);
}

getTagCount(){
  this.orderService.getVendorTagOrderCount(this.listingType, this.getSearchString(), this.filterBy, this.isDemoFlag, this.order_Status)
  .subscribe((data) => {
   this.tagsOrderCount= data;
   this.addOrderCountToTags(data);
  }, (err) => {
    this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
  })
}

resetAllValue(event){
  this.selectedCounty = '';    
}  

afterTextCopy() {
  this.userMsg.setSuccessCopyMsg("Text copied successfully");
}

getFullAddress(order){
  let completeAddress = '';
  if (order.Property_Address_1) completeAddress += order.Property_Address_1
  if (order.Property_Address_2) {
    if (completeAddress) completeAddress += '\n'
    completeAddress += order.Property_Address_2
  }
  if (order.Property_City) {
    if (completeAddress) completeAddress += '\n'
    completeAddress += order.Property_City
  }
  if (order.Property_State_Abbr) {
    if (completeAddress) completeAddress += ', '
    completeAddress += order.Property_State_Abbr
  }
  if (order.Property_ZipCode) {
    if (completeAddress) completeAddress += ' '
    return completeAddress += order.Property_ZipCode
  }
}

getClientFullName(order) {
  if(order.Organization_Name) return order.Client_User_Full_Name + " - "  +order.Organization_Name;
  else return order.Client_User_Full_Name;
}

getholidaylistFromLocal(){
  let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
  if(allHolidays) this.setHoliday(allHolidays)
  else this.getholidaylist();
}

setHoliday(holiday){
  this.holidayList = holiday
}

getholidaylist(){
  this.holidayScheduling.getAllHolidays()
  .subscribe((holidays)=>{
    if(holidays){
      this.setHoliday(holidays)
      sessionStorage.setItem('holidays', JSON.stringify(holidays));
   setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);
    
    }
  }, (err) =>{
    console.log("Error",err)
  })
}

checkAdminType() {
  if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
  else return true;
}

isSuperAdmin(){
  if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
  else if(this.auth.getUserId() == environment.superAdminId) return true;
  else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
  else return false;
}

checkForSuperRole(){
  if(this.auth.getUserId() == environment.superAdminId) return true;
  else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) return true;
  else return false;
}
}
