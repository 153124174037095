// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  border: 1px solid;
  border-radius: 3px;
  padding: 4px;
}

.edit-icon-pos {
  position: absolute;
  right: 20px;
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/pai-config-listing/pai-config-listing.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,eAAA;AACF","sourcesContent":[".box{\n  border: 1px solid;\n  border-radius: 3px;\n  padding: 4px;\n}\n\n.edit-icon-pos{\n  position: absolute;\n  right: 20px;\n  margin-top: 4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
