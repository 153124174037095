import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { CONSTANTS } from "app/app.constants";
import { ClientsService, ConfigService, OrdersService } from "app/services";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import * as momentTimeZone from "moment-timezone";
import * as moment from "moment";
import { ConfirmComponent } from "../confirm/confirm.component";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "L",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-estimated-delivery-change",
  templateUrl: "./estimated-delivery-change.component.html",
  styleUrl: "./estimated-delivery-change.component.scss",
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class EstimatedDeliveryChangeComponent implements OnInit {
  @ViewChild("etaDatePicker") etaDatePicker: ElementRef;
  clientNotifStatus: boolean = false;
  dueDateObj = {};
  orderETAtime = new UntypedFormControl({ value: "", disabled: true }, [
    Validators.required,
  ]);
  errors: any = {};
  getUSDateString: any;
  minDate: any;
  dueDateEvent: any;
  timer: any = [];
  newGrpFrm: UntypedFormGroup;
  messageText = "";
  selectedReason = "";
  selectType: any;
  error: string = "";
  constants = CONSTANTS;
  etaReasonsTypes: any = [];
  notifyCustomer: boolean = true;
  cnfLabel: string = "Confirm";
  originalDate: any = null;
  originalTime: any = null;
  isConfirmed :boolean =false;
  client_mail_text : string = '';
  tinyOptions: any = {
    auto_focus: false,
    branding: false,
    promotion: false,
    skin: "oxide",
    menubar: false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code", "autoresize"],
    placeholder: "Unable to create draft using pAI.",
    toolbar:
      "undo redo | styleselect | forecolor backcolor |  bold italic |  alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
    height: "auto",
    autoresize_bottom_margin: 0, // Removes extra bottom space
    autoresize_overflow_padding: 0,
    autoresize_min_height: 100, // Minimum height required
    autoresize_max_height: 300,
    content_style: `
    body {
      min-height: 150px !important; 
      max-height: 400px !important;
      overflow: auto !important;
    }
  `
  };
  savedETA:any = null;
  constructor(
    public dialogRef: MatDialogRef<EstimatedDeliveryChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private orderService: OrdersService,
    private config: ConfigService,
    private matDialog: MatDialog,
    private clientService: ClientsService
  ) {}
  ngOnInit() {
    console.log("holiday list", this.data.holidayList)
    this.originalDate =
      this.data && this.data.dueDateEtaDate ? this.data.dueDateEtaDate : null;
    this.originalTime =
      this.data && this.data.dueDateEtaTime ? this.data.dueDateEtaTime : null;
    if (this.data) {
      if (!this.data.dueDateEtaTime) this.data.dueDateEtaTime = "17:00";
    }
    if (!this.data.reqDate) {
      this.getUSDateString = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });
      this.minDate = new Date(this.getUSDateString);
    } else {
      this.getUSDateString = new Date("01/02/1500").toLocaleString("en-US", {
        timeZone: "America/New_York",
      });
      this.minDate = new Date(this.getUSDateString);
    }
    this.timer.push(
      setTimeout(() => {
        this.getETAChangeReasonTypes();
        if (this.data && this.data.customerId) this.fetchFlagDetails();
      }, 0)
    );
  }

  dueDateNTime() {
    if (this.orderETAtime.valid) {
      if (this.data.dueDateEtaDate && this.data.dueDateEtaTime) {
        if (this.data.RequestDate) {
          this.dueDateObj["req_Date_EST"] = this.data.dueDateEtaDate;
          this.dueDateObj["req_Time_EST"] = this.data.dueDateEtaTime;
        } else {
          this.dueDateObj["Due_Date_EST"] = this.data.dueDateEtaDate;
          this.dueDateObj["Due_TIme_EST"] = this.data.dueDateEtaTime;
        }
        this.dueDateEvent = this.dueDateObj;
      }
    } else {
      this.dueDateObj["dueDateTimeCtrl"] = this.orderETAtime;
      this.dueDateEvent = this.dueDateObj;
    }
  }

  validateOrderETADateFn(event) {
    if (event.value) {
      if (this.data.dueDateEtaDate && this.data.dueDateCtrl.valid)
        this.orderETAtime.enable();
      if (this.data.RequestDate) {
        this.dueDateObj["req_Date_EST"] = this.data.dueDateEtaDate;
        this.dueDateObj["req_Time_EST"] = this.data.dueDateEtaTime;
      } else {
        this.dueDateObj["Due_Date_EST"] = this.data.dueDateEtaDate;
        this.dueDateObj["Due_TIme_EST"] = this.data.dueDateEtaTime;
      }
      this.dueDateEvent = this.dueDateObj;
    } else {
      if (this.data.RequestDate) {
        this.dueDateObj["req_Date_EST"] = event.value;
        this.dueDateObj["req_Time_EST"] = this.data.dueDateEtaTime;
      } else {
        this.dueDateObj["Due_Date_EST"] = event.value;
        this.dueDateObj["Due_TIme_EST"] = this.data.dueDateEtaTime;
      }
      this.dueDateEvent = this.dueDateObj;
    }
  }

  clearForms() {
    this.errors = {};
    if (this.data.dueDateEtaDate && this.data.dueDateCtrl.valid)
      this.orderETAtime.enable();
    else this.orderETAtime.disable();
  }

  restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[///\d\s]/.test(input);
  }

  checkYear() {
    if (this.dueDateObj) {
      if (
        (this.dueDateObj["req_Date_EST"] &&
          this.dueDateObj["req_Date_EST"]._.getFullYear() >= "1500") ||
        (this.dueDateObj["Due_Date_EST"] &&
          this.dueDateObj["Due_Date_EST"]._d.getFullYear() >= "1500")
      )
        return true;
      else return false;
    } else return false;
  }

  confirm() {
    this.error = '';
    let result = {
      eta: this.composeDueDate(),
      reason: this.selectType,
      comment: `${this.selectedReason} - ${this.messageText}`,
      orderId: this.data.orderId,
      emailContent : null,
      notifyCustomer: this.notifyCustomer,
      clientNotifStatus: this.clientNotifStatus,
      Due_Date_EST: this.dueDateEvent.Due_Date_EST,
      Due_TIme_EST: this.dueDateEvent.Due_TIme_EST,
      dueDateType: 0
    }
    this.checkForHoliday(result)
      
  }

  sendEmailNotification(result){
    this.orderService.createpAIEmailDraft(result)
    .subscribe((aiDraft) => {
      console.log("aiDraft", aiDraft);
      this.client_mail_text = aiDraft.text;
      this.isConfirmed = true;
    }, (err) => {
      if(err.msg) this.error = err.msg
      else this.error = 'Unable to create email draft via pAI.'
    })
  }

  processConfirm(result){
    this.orderService.updateConfirmedETA({
      Order_ETA: this.composeDueDate(),
      Order_ETA_Change_Reason_Type_ID: this.selectType,
      orderETAChangeReason: `${this.selectedReason} - ${this.messageText}`,
      Order_ID: this.data.orderId,
      Email_Body : null,
      Notify_Customer: this.notifyCustomer,
      Client_Notif_Status: this.clientNotifStatus
    })
    .subscribe((data) => {
      this.savedETA = data.hasOwnProperty('Order_ETA') ? data.Order_ETA : null;
      if(this.notifyCustomer && this.clientNotifStatus) 
        this.sendEmailNotification(result);
      else {
        if(this.savedETA) 
          this.dialogRef.close({
            Due_Date_EST: this.dueDateEvent.Due_Date_EST,
            Due_TIme_EST: this.dueDateEvent.Due_TIme_EST,
          });
        else 
          this.error = 'Unable to update Order ETA.'
      }
    }, () => {
      this.error = 'Unable to update Order ETA.'
    })
  }

  send(){
    this.error = '';
    let result = {
      Old_Order_ETA: this.composeOldDueDate() ? moment.utc(this.composeOldDueDate()).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null ,
      Order_ETA: this.composeDueDate(),
      Order_ETA_Change_Reason_Type_ID: this.selectType,
      orderETAChangeReason: `${this.selectedReason} - ${this.messageText}`,
      Order_ID: this.data.orderId,
      Email_Body : this.client_mail_text,
      Notify_Customer: this.notifyCustomer,
      Client_Notif_Status: this.clientNotifStatus
    }
    this.orderService.sendConfirmedETAChangeNotification(result)
    .subscribe(() => {
      this.dialogRef.close({  
        Due_Date_EST: this.dueDateEvent.Due_Date_EST,
        Due_TIme_EST: this.dueDateEvent.Due_TIme_EST,
      });
    }, () => {
      this.error = 'Unable to send ETA change slack and email notification.'
    })
    
  }

  close() {
    this.dialogRef.close(null);
  }

  getETAChangeReasonTypes() {
    this.orderService.getETAChangeReasonTypes().subscribe(
      (res) => {
        if (res) {
          this.etaReasonsTypes = res;
        }
      },
      (err) => {
        this.openErrorPopup("Error while fetching data. Please try again.");
      }
    );
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {
      data: {
        message: msg,
      },
      ...this.config.getDialogOptions(),
    });
  }

  onSelect(event) {
    // if (this.selectType != 999) {  // PT-3628
    var selectedOption = this.etaReasonsTypes.filter(
      (reason) => reason.Order_ETA_Change_Reason_Type_ID == this.selectType
    );
    this.selectedReason = selectedOption[0].Order_ETA_Change_Reason_Text;
    // }
  }

  clearTime() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  updateNotifyStatus() {
    this.notifyCustomer = !this.notifyCustomer;
  }

  fetchFlagDetails() {
    this.orderService
      .getOrderUpdatesEmailStatus({ Order_ID : this.data.orderId, Update_Type: 'order_delay' })
      .subscribe((flagDetails) => {
        if (flagDetails) {
          this.clientNotifStatus = flagDetails.status;
        }
      });
  }

  handleToggleChange(event){
    this.cnfLabel =
            this.notifyCustomer
              ? "Confirm"
              : "Confirm without Notifying";
  }

  convertMomentTodate(momentVal) {
    if (momentVal) return moment(momentVal).format("MM/DD/YY");
    else return null;
  }

  tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  composeDueDate() {
    if (this.dueDateEvent) {
      if (this.dueDateEvent.Due_Date_EST || this.dueDateEvent.Due_TIme_EST) {
        let order_ETA_Date = this.setOrderETATime(
          this.dueDateEvent.Due_Date_EST
        );
        var estShift = momentTimeZone
          .tz(order_ETA_Date, "America/New_York")
          .isDST()
          ? 4
          : 5;
        var etaInEstTimezone = moment(order_ETA_Date)
          .utcOffset(moment(order_ETA_Date).utcOffset())
          .add(estShift, "hours")
          .format("YYYY-MM-DDTHH:mm");
        return etaInEstTimezone;
      }
      else return null;
    }
    else return null;
  }

  composeOldDueDate() {
    if (this.originalDate) {
      let order_ETA_Date = this.setOldOrderETATime(
        this.originalDate
      );
      var estShift = momentTimeZone
        .tz(order_ETA_Date, "America/New_York")
        .isDST()
        ? 4
        : 5;
      var etaInEstTimezone = moment(order_ETA_Date)
        .utcOffset(moment(order_ETA_Date).utcOffset())
        .add(estShift, "hours")
        .format("YYYY-MM-DDTHH:mm");
      return etaInEstTimezone;
    }
    else return null;
  }

  setOrderETATime(orderETA) {
    if (orderETA && orderETA._d) {
      let getOrderEtaHours = this.dueDateEvent.Due_TIme_EST.split(":")[0];
      let getOrderEtaMinutes = this.dueDateEvent.Due_TIme_EST.split(":")[1];
      return orderETA._d.setHours(
        Number(getOrderEtaHours),
        Number(getOrderEtaMinutes)
      );
    } else if (orderETA && !orderETA._d) {
      let getOrderEtaHours = this.dueDateEvent.Due_TIme_EST.split(":")[0];
      let getOrderEtaMinutes = this.dueDateEvent.Due_TIme_EST.split(":")[1];
      return orderETA.setHours(
        Number(getOrderEtaHours),
        Number(getOrderEtaMinutes)
      );
    } else return null;
  }

  setOldOrderETATime(orderETA) {
    if (orderETA && orderETA._d) {
      let getOrderEtaHours = this.originalTime.split(":")[0];
      let getOrderEtaMinutes = this.originalTime.split(":")[1];
      return orderETA._d.setHours(
        Number(getOrderEtaHours),
        Number(getOrderEtaMinutes)
      );
    } else if (orderETA && !orderETA._d) {
      let getOrderEtaHours = this.originalTime.split(":")[0];
      let getOrderEtaMinutes = this.originalTime.split(":")[1];
      return orderETA.setHours(
        Number(getOrderEtaHours),
        Number(getOrderEtaMinutes)
      );
    } else return null;
  }

  showWarningPopup(holidayName, data){
    let disposable = this.matDialog.open(ConfirmComponent, { data:{
      title:'ETA Note',
      message:"The ETA on this order is a US holiday ("+holidayName+"). Would you like to continue?"
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => { 
      if(res) this.processConfirm(data);
    });
  }

  checkForHoliday(result){
    let orderETADate = momentTimeZone.utc(result.eta).tz("America/New_York");
    let checkETAIsHoliday = this.data.holidayList.findIndex((holiday) => {
      if (
        holiday &&
        momentTimeZone.utc(holiday.Holiday_Date).tz("America/New_York").isSame(orderETADate, 'day')
      )
        return true;
      else return false;
    });
    if (checkETAIsHoliday > -1)
      this.showWarningPopup(
        this.data.holidayList[checkETAIsHoliday].Holiday_Name,
       result
      );
    else if (this.checkETALieOnWeekend(result.eta).length > 0)
      this.showWarningPopup(
        this.checkETALieOnWeekend(result.eta),
        result
      );
    else this.processConfirm(result);
  }

  checkETALieOnWeekend(orderETA) {
    if (momentTimeZone.utc(orderETA).tz("America/New_York").isoWeekday() == 7) {
      // iso Weekday for sunday is 7
      return  "Sunday";
    } else if (momentTimeZone.utc(orderETA).tz("America/New_York").isoWeekday() == 6) {
      // iso Weekday for saturday is 6
      return  "Saturday";
    } else return "";
  }
}
