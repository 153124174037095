import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { CONSTANTS } from "app/app.constants";
import { RbGlobCommSectionComponent } from "./rb-glob-comm-section/rb-glob-comm-section.component";
import { getNextSortOrder } from "app/utils/number-utils";

@Component({
  selector: "app-rb-global-commitments",
  templateUrl: "./rb-global-commitments.component.html",
  styleUrls: ["./rb-global-commitments.component.scss"],
})
export class RbGlobalCommitmentsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  sectionObject: any = {};
  searchPackage: any = {};
  sectionOptions: any;
  sectionData: any = [];
  deletedSectionData: any = [];
  dbSectionData: any = [];
  isDragable: boolean = true;
  constants= CONSTANTS;
  constructor(private builder: RptBuilderService) {}
  @ViewChildren('section') sections!: QueryList<RbGlobCommSectionComponent>;
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) =>
          (this.sectionOptions = data
            ? data.Commitment_Typing_Categories
            : null)
      );
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => (this.searchPackage = data && data.dbData ? data.dbData : {})
      );
    this.builder.globalCommit$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComponent(data));
    this.builder.deletedItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initDeletedItems(data));
  }

  initComponent(data) {
    this.sectionObject = data;
    //modifiying thecondtion below because adding new assessments is not refrshing the list
    // if (data && data.curData && this.getLength() == 0) {
    if (data && data.curData && data.dbData) {
      this.sectionData = data.curData;
      this.dbSectionData = data.dbData;
    }
  }

  initDeletedItems(data) {    
    if(data){
      if(data.hasOwnProperty('Global_Commitments')) this.deletedSectionData = data['Global_Commitments'];      
    }
  }

  getLength() {
    return this.sectionData ? this.sectionData.length : 0;
  }

  addNewSectionItem(item, sortOrder) {
    const sortOrderOfNewItem = sortOrder > 0 ? sortOrder : getNextSortOrder(this.sectionData)
    let newItem = {
      Id: "New",
      Sp_Id: this.searchPackage.Id,
      Sort_Order: sortOrderOfNewItem,
      Applies: 1,
      Derived_From: null,
      Status: 1,
      Category_Id: item.Id,
      Statement: "",
    };
    let payload = {
      curData: newItem,
      dbData: null,
      Sp_Id: this.searchPackage["Id"],
      Derived_From: this.searchPackage["Derived_From"],
    };
    this.builder.saveGlbComm(payload, true).pipe(take(1)).subscribe(() => { setTimeout(() => {
      const index = this.sectionData.findIndex(e=> e.Sort_Order === sortOrderOfNewItem)
      this.focusAddedItem(index);
    }),100});
  }

  isDragEnable(val) {
    if (val) {
      this.isDragable = false;
    } else {
      this.isDragable = true;
    }
  }

  getdbData(era) {
    let dbData = this.dbSectionData.find((item) => item.Id == era.Id);
    if (dbData !== undefined) return dbData;
    return null;
  }

  dropSectionBlock(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sectionData, event.previousIndex, event.currentIndex);
    this.sectionData.forEach((item, index) => {
      item.Sort_Order = index;
    });
    let payload = {
      curData: this.sectionData,
      dbData: this.dbSectionData,
      Sp_Id: this.searchPackage["Id"],
      Derived_From: this.searchPackage["Derived_From"],
    };
    this.builder.updateGlbCommSortOrder(payload).subscribe();
  }

  focusAddedItem(index){
    if(this.sections && this.sections.length > 0){
      if(this.sections.get(index).firstInput) this.sections.get(index).firstInput.focus()
    }
  }

  focusFirsttItem(){
    if(this.sections && this.sections.length > 0){
      if(this.sections.first['firstInput']) this.sections.first['firstInput'].focus()
    }
  }
}
