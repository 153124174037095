import { Component, forwardRef, Input } from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { CurrencyPipe } from "@angular/common";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-ai-input-field",
  templateUrl: "./ai-input-field.component.html",
  styleUrl: "./ai-input-field.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AiInputFieldComponent),
      multi: true,
    },
    CurrencyPipe, // Provide CurrencyPipe
  ],
})
export class AiInputFieldComponent implements ControlValueAccessor {
  @Input() type: string = "text";
  @Input() label: string;
  @Input() id!: string;
  @Input() name!: string;
  @Input() status: string;
  @Input() maxLength: string = null;
  @Input() field: string = "";
  @Input() errorMessages: { [key: string]: string } = {};
  @Input() modified: boolean = false;
  @Input() formControl: FormControl;
  @Input() onValueChange!: (newValue: string, field: string) => Promise<void>;
  @Input() onStatusChange!: (field: string) => Promise<void>;
  @Input() currency: boolean = false; // New Input Property to enable currency format

  _value: string = "";
  private valueChangeSubject: Subject<string> = new Subject<string>();

  @Input()
  set value(val: string) {
    if (val != undefined && val !== this._value) {
      this._value = val;
    }
  }

  get value(): string {
    return this._value;
  }

  onChange = (value: string) => {};
  onTouched = () => {};

  constructor(private currencyPipe: CurrencyPipe) {
    this.valueChangeSubject.pipe(debounceTime(250)).subscribe(async (newValue) => {
      if (this.onValueChange) {
        this.onChange(newValue);
        this.updateForm(newValue);
        await this.onValueChange(newValue, this.field);
      }
    });
  }

  ngOnInit(): void {
    if (this.formControl) {
      this.formControl.valueChanges.subscribe((newValue) => { 
        if (this.formControl.dirty && this._value !== newValue) {
          this._value = newValue;
          this.updateForm(newValue);
          this.onChange(newValue);
        }
      });
    }
  }

  updateForm(newValue) {
    if (this.formControl) {
      this.formControl.setValue(newValue, { emitEvent: true });
      this.formControl.markAsDirty();
      this.formControl.markAsTouched();
      this.formControl.updateValueAndValidity();
    }
  }

  async onInputChange(event: Event) {
    let newValue = (event.target as HTMLInputElement).value;
    if (this.currency) {
      newValue = newValue.replace(/[^0-9.]/g, "");  
    }

    this._value = newValue;
    
    if (this.onValueChange) {
      this.valueChangeSubject.next(newValue);
    }
  }

  formatCurrencyOnBlur() {
    if (this.currency) {
      this._value = this.currencyPipe.transform(this._value || "0", "USD", "symbol", "1.2-2") || "$0.00";
    }
  }

  removeFormatting() {
    if (this.currency) {
      this._value = String(this._value).replace(/[^0-9.]/g, ""); // Remove formatting for editing
    }
  }

  async onButtonClick() {
    if (this.onStatusChange) {
      await this.onStatusChange(this.field);
    }
  }

  writeValue(value: string | number | null | undefined): void {
    if (value === this._value) return;  
    let numericValue: number | null = null;
  
    if (typeof value === "number") {
      numericValue = value;  
    } else if (typeof value === "string" && value.trim() !== "") {
      const sanitizedValue = value.replace(/[^0-9.]/g, "");  
      if ((sanitizedValue.match(/\./g) || []).length <= 1) {
        numericValue = parseFloat(sanitizedValue);
        if (isNaN(numericValue)) numericValue = null;
      }
    }

    this._value = this.currency
      ? this.currencyPipe.transform(numericValue ?? 0, "USD", "symbol", "1.2-2") || "$0.00"
      : String(value ?? "");
  }
  

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Handle disabled state if needed
  }
}
