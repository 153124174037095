// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader-full {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /*background: rgba(255, 203, 140, .3);*/
  background: rgba(2, 18, 69, 0.1);
}

.loader {
  margin-left: auto;
  margin-right: auto;
  top: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/services/preloader/preloader.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,uCAAA;EACA,gCAAA;AACF;;AA2BA;EACE,iBAAA;EACA,kBAAA;EACA,QAAA;AAxBF","sourcesContent":[".preloader-full {\n  z-index: 99999;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  width: 100%;\n  height: 100%;\n  /*background: rgba(255, 203, 140, .3);*/\n  background: rgba(2, 18, 69, .1);\n}\n\n\n\n// .preloader-full__spinner {\n//   __<<ngThemingMigrationEscapedComment1>>__\n//   color: #286090;\n//   position: absolute;\n//   top: 50%;\n//   left: 50%;\n//   font-size: 80px;\n//   margin-top: -52px;\n//   margin-left: -40px;\n//   animation: preloaderFullRotate 1s infinite linear;\n// }\n\n\n\n// @keyframes preloaderFullRotate {\n//   0% {\n//     transform: rotate(0deg);\n//   }\n//   100% {\n//     transform: rotate(360deg);\n//   }\n// }\n\n.loader{\n  margin-left: auto;\n  margin-right: auto;\n  top: 50%;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
