import { Injectable } from "@angular/core";
import {
  BaseAiSectionsService,
  SectionInfo,
} from "../base-ai-sections.service";
import { Observable } from "rxjs";
import { isNA } from "app/utils/utils";

export class AssessmentSectionStateService extends BaseAiSectionsService {

  async getAssesmentInforFromAI(sectionInfo: SectionInfo) {
    const response = await this.getSectionInfoFromAI(sectionInfo).toPromise()

    if(response.Year && !isNA(response.Year)){
      response.Year = String(response.Year).slice(0,4)
    }
    if(isNA(response.Year)){
      response.Year = null
    }
    return response
  }


  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    const attributes = {
      Year: "",
      Land: "This refers to cost of land",
      Building: "This refers to cost of Building",
      Extras: "This refers to cost of Extras",
      Total: "This refers to cost of Total",
      Parcel: "",
      // Comments: "",
      // Internal_Comments: "",
      // Applies: "",
      // Language: "",
    };
    return this.reportBuilder.getSectionDataFromAI(attributes, sectionInfo);
  }
}
