export const isAIFilled = (placeholderState) => {
  if (!placeholderState) return false;
  return Object.values(placeholderState).some(
    (e: any) => e.status !== "default"
  );
};

export function validateStatus(
  input: string
): "autofilled" | "default" | "edited" | "accepted" | "unsure" {
  if (
    input === "autofilled" ||
    input === "default" ||
    input === "edited" ||
    input === "accepted" ||
    input === "unsure"
  ) {
    return input;
  }
  return "default"; // fallback value
}


export function isNA(val: unknown): boolean {
  if (val === null || val === undefined) return false;
  const normalized = String(val).toLowerCase().replace(/\s+/g, '');
  return normalized === 'na';
};