import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { CONSTANTS } from "app/app.constants";
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { RbAssessmentSectionComponent } from "./rb-assessment-section/rb-assessment-section.component";
import { getNextSortOrder } from "app/utils/number-utils";

@Component({
  selector: "app-rb-assessment",
  templateUrl: "./rb-assessment.component.html",
  styleUrls: ["./rb-assessment.component.scss"],
})
export class RbAssessmentComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  sectionObject: any = {};
  searchPackage: any = {};
  assessmentOptions: any;
  assessments: any = [];
  dbAssessments: any = [];
  deletedSectionData: any = [];
  generalComments = null;
  isDragable: boolean = true;
  constants= CONSTANTS;
  constructor(private builder: RptBuilderService) {}
  @ViewChildren('section') sections!: QueryList<RbAssessmentSectionComponent>;
  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) =>
          (this.assessmentOptions = data ? data.SP_Party_Entities : null)
      );
    this.builder.basic$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) => (this.searchPackage = data && data.dbData ? data.dbData : {})
      );
    this.builder.assessments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComponent(data));
    this.builder.generalComments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComments(data));
    this.builder.deletedItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initDeletedItems(data));
  }

  initComments(data) {
    if (data && data.curData) {
      let comment = data.curData.find(
        (gc) => gc.Comments_Type_ID == CONSTANTS.srchPkgItmTypes.assmts
      );
      this.generalComments = comment !== undefined ? comment : null;
    }
  }

  initDeletedItems(data) {    
    if(data){
      if(data.hasOwnProperty('SP_Assessments')) this.deletedSectionData = data['SP_Assessments'];     
    }
  }

  initComponent(data) {
    this.sectionObject = data;
    //modifiying thecondtion below because adding new assessments is not refrshing the list
    // if (data && data.curData && this.getLength() == 0) {
    if (data && data.curData && data.dbData) {
      this.assessments = data.curData;
      this.dbAssessments = data.dbData;
    } else {
      this.assessments = [];
      this.dbAssessments = [];
    }
  }

  getLength() {
    return this.assessments ? this.assessments.length : 0;
  }

  removeGenMessage() {
    let copyGenCom = {...this.generalComments}
    this.generalComments.Status = 0;
    this.builder.updateGeneralComment(this.generalComments, copyGenCom, this.searchPackage.Id, true).subscribe();
  }

  addGenMessage() {
    if (this.generalComments) this.generalComments.Status = 1;
    else {
      this.generalComments = {
        Id: "New",
        Sp_Id: this.searchPackage.Id,
        Comments: "",
        Comments_Type_ID: CONSTANTS.srchPkgItmTypes.assmts,
      };
    }
    this.builder.updateGeneralComment(this.generalComments, null, this.searchPackage.Id, true).subscribe();
  }

  addAssessment(item, index) {
    const indexOfNewItem = index > 0 ? index : getNextSortOrder(this.assessments)
    let assess = {
      Id: "New",
      Sp_Id: this.searchPackage.Id,
      Year: "",
      Land: 0,
      Building: 0,
      Extras: 0,
      Total: 0,
      Comments: "",
      Sort_Order: indexOfNewItem,
      Applies: 1,
      Parcel: this.searchPackage.Parcel,
      Derived_From: null,
      Status: 1,
    };
    let payload = {
      curData: assess,
      dbData: null,
      Sp_Id: this.searchPackage["Id"],
      Derived_From: this.searchPackage["Derived_From"],
    };
    this.builder.saveAssesments(payload, true).pipe(take(1)).subscribe(() => { setTimeout(() => {
      this.focusAddedItem(indexOfNewItem);
    }),250});
  }

  isDragEnable(val) {
    if (val) {
      this.isDragable = false;
    } else {
      this.isDragable = true;
    }
  }

  getdbData(assessment) {
    let dbData = this.dbAssessments.find(
      (assess) => assess.Id == assessment.Id
    );
    if (dbData !== undefined) return dbData;
    return null;
  }

  dropAssessment(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.assessments, event.previousIndex, event.currentIndex);
    this.assessments.forEach((item, index) => {
      item.Sort_Order = index;
    });
    let payload = {
      curData: this.assessments,
      dbData: this.dbAssessments,
      Sp_Id: this.searchPackage["Id"],
      Derived_From: this.searchPackage["Derived_From"],
    };
    this.builder.updateAssesmentSortOrder(payload).subscribe();
  }

  focusAddedItem(index){
    if(this.sections && this.sections.length > 0){
      if(this.sections.get(index).firstInput) this.sections.get(index).firstInput.focus()
    }
  }

  focusFirsttItem(){
    if(this.sections && this.sections.length > 0){
      if(this.sections.first['firstInput']) this.sections.first['firstInput'].focus()
    }
  }
}
