import { Component, Input, Signal } from '@angular/core';

@Component({
  selector: 'app-rb-ai-header-button',
  standalone: false,
  templateUrl: './rb-ai-header-button.component.html',
  styleUrl: './rb-ai-header-button.component.scss'
})
export class RbAiHeaderButtonComponent {
   @Input()  handleCallback!: () => Promise<void>;
   @Input() isDisabled!: Signal<boolean>;
   @Input() isAIUsedForSection!: Signal<boolean>;

   isLoading:boolean = false;

   async handleClick(){
    if(!this.handleCallback){
      console.error('Callback function is not provided')
    }
    this.isLoading = true;
    try {
      await this.handleCallback();
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }

   }
}
