// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dueDateTime {
  max-width: 35%;
  width: 35%;
}

.matTimepicker {
  display: flex;
  flex-direction: column;
}
.matTimepicker label {
  display: flex;
  text-overflow: ellipsis;
  margin-left: 15px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  max-lines: 1;
  white-space: nowrap;
}
.matTimepicker input {
  padding-left: 15px;
  border: none;
  border-bottom: 1px solid;
  line-height: 36px;
  outline: none;
}

.cont {
  display: flex;
  justify-content: space-between; /* Spaces divs evenly */
  gap: 10px;
  padding-bottom: 8px;
}

.datebox {
  width: 65%; /* Adjust width as needed */
}

.timebox {
  width: 35%; /* Adjust width as needed */
}

mat-hint.time-hint {
  font-size: 14.5px;
  text-align: right;
}

mat-hint.date-hint {
  font-size: 14.5px;
  position: absolute;
  right: 0;
}

.note-label {
  color: #939393;
  font-size: 15px;
  display: block;
}

.notify-text {
  font-weight: 700;
}

.tox-tinymce {
  width: 100% !important;
  min-height: 50px !important;
  max-height: 200px !important;
  overflow-y: auto;
}

.tox-edit-area {
  padding: 5px !important;
}

:host ::ng-deep mat-hint {
  color: #B6B6B6;
}`, "",{"version":3,"sources":["webpack://./src/app/dialog/pricing-change-prompt/pricing-change-prompt.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,UAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,eAAA;EACA,yBAAA;EACA,iBAAA;EACA,YAAA;EACA,mBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,YAAA;EACA,wBAAA;EACA,iBAAA;EACA,aAAA;AAEJ;;AAEA;EACE,aAAA;EACA,8BAAA,EAAA,uBAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE,UAAA,EAAA,2BAAA;AACF;;AAEA;EACE,UAAA,EAAA,2BAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,QAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,sBAAA;EACA,2BAAA;EACA,4BAAA;EACA,gBAAA;AACF;;AACA;EACE,uBAAA;AAEF;;AACA;EACE,cAAA;AAEF","sourcesContent":[".dueDateTime {\n  max-width: 35%;\n  width: 35%;\n}\n\n.matTimepicker {\n  display: flex;\n  flex-direction: column;\n  label {\n    display: flex;\n    text-overflow: ellipsis;\n    margin-left: 15px;\n    font-size: 11px;\n    color: rgba(0, 0, 0, 0.5);\n    font-weight: bold;\n    max-lines: 1;\n    white-space: nowrap;\n  }\n  input {\n    padding-left: 15px;\n    border: none;\n    border-bottom: 1px solid;\n    line-height: 36px;\n    outline: none;\n  }\n}\n\n.cont {\n  display: flex;\n  justify-content: space-between; /* Spaces divs evenly */\n  gap: 10px;\n  padding-bottom: 8px;\n}\n\n.datebox {\n  width: 65%; /* Adjust width as needed */\n}\n\n.timebox {\n  width: 35%; /* Adjust width as needed */\n}\n\nmat-hint.time-hint {\n  font-size: 14.5px;\n  text-align: right;\n}\n\nmat-hint.date-hint {\n  font-size: 14.5px;\n  position: absolute;\n  right: 0;\n}\n\n.note-label {\n  color: #939393;\n  font-size: 15px;\n  display: block;\n}\n\n.notify-text{\n  font-weight: 700;;\n}\n\n.tox-tinymce {\n  width: 100% !important;\n  min-height: 50px !important;\n  max-height: 200px !important;\n  overflow-y: auto;\n}\n.tox-edit-area {\n  padding: 5px !important;\n}\n\n:host ::ng-deep mat-hint {\n  color: #B6B6B6;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
