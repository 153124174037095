import { Injectable } from "@angular/core";
import {
  BaseAiSectionsService,
  SectionInfo,
} from "../base-ai-sections.service";
import { Observable } from "rxjs";

export class LiensStateService extends BaseAiSectionsService {
  attributes = {};

  getLiensSectionInfoFromAI(
    sectionInfo: SectionInfo,
    dropDownOptions
  ): Observable<any> {
    const names = dropDownOptions.map((e) => e.Name);
    this.attributes = {
      Type_ID: `select any one value from this list :- ${JSON.stringify(
        names
      )}`,
      Book_Case: "",
      PG_Case: "",
      Amount: "",
      Debtor_Defendant: "",
      Type: "",
      Against_Or_Infavorof: "",
      Dated_Date: "",
      Rec_Date: "",
    };
    return this.getSectionInfoFromAI(sectionInfo);
  }

  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    return this.reportBuilder.getSectionDataFromAI(
      this.attributes,
      sectionInfo
    );
  }
}
