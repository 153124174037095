import { Injectable } from "@angular/core";
import {
  BaseAiSectionsService,
  SectionInfo,
} from "../base-ai-sections.service";
import { Observable } from "rxjs";

export class EncumbranceStateService extends BaseAiSectionsService {
  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    const attributes = {
      Detail: "",
      Book_Instrument: "",
      Page: "",
      Dated_Date: "",
      Rec_Date: "",
    };

    return this.reportBuilder.getSectionDataFromAI(attributes, sectionInfo);
  }
}
