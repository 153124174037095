import { Component, OnInit, ViewChild } from '@angular/core';
import { CONSTANTS } from '../../app.constants'
import {
  AuthService, PagerService, ConfigService, OrdersService, PricingService, TagsService, StateService,
  PreloaderService, OrganizationService, ScrollService, UsermessageService, QuoterequestService, SocketService, UsersService
} from '../../services';
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as momentTimeZone from 'moment-timezone';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import * as _ from 'underscore';
import { NewTagComponent } from '../../dialog/new-tag/new-tag.component';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';
import { ScreenOrderComponent } from 'app/dialog/screen-order/screen-order.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-request-quote',
  templateUrl: './request-quote.component.html',
  styleUrls: ['./request-quote.component.scss']
})
export class RequestQuoteComponent implements OnInit {
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  constants = CONSTANTS
  orders: any = [];
  orderStatus: any = {};
  organizations = [];
  allorganizations = [];
  selectedOrgId = '';
  selectedOrgId2 = '';
  selectedState = '';
  selectedCounty = '';
  allActiveTags = [];
  selectedTags = [];
  tagsOrderCount = [];
  scrollEnable: boolean = true;
  filterModel: any = {};
  orderStatusList = [];
  selectedQuoteType: any;
  searchTag: string = '';
  orderTags = [];
  selectedOrgIds = [];
  ordersToDisplay: any;

  totalCount: number;
  states = [];
  counties = [];
  filterBy: any = [];
  searchString: string;
  pageNum: number;
  sortField: string;
  orderBy: string;
  quotes = [];
  associatedTags = [];
  pager: any = {};
  selectedorg = '';
  selecteState = '';
  selectecounty = '';
  selecteQuoteType = '';
  newClientTagId = '';
  priorityClientTagId = '';
  prePaidTagId = '';
  partiallyDeliveredTagId = '';
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  searchInProgress: boolean = false;
  isDemoFlag: boolean = false;
  searchQuery = new Subject<string>();
  absDoubleAssignTagId = '';
  currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
  selectAllTags: boolean = false;
  negativeTag: boolean = false;
  intialTag: boolean = true;
  selectedNegTags =[];
  isNegTagSelected: boolean = false;
  allNegativeTags = [];
  allActiveTagsBackUp=[];
  constructor(
    private auth: AuthService,
    private organizationService: OrganizationService,
    private pricingService: PricingService,
    private tagService: TagsService,
    private matDialog:MatDialog,
    private config: ConfigService,
    private quoteService: QuoterequestService,
    private preloaderService: PreloaderService,
    private scrollService: ScrollService,
    private router: Router,
    private orderService: OrdersService,
    private stateService: StateService,
    private userMsg: UsermessageService,
    private pagerService: PagerService,
    private _datePipe: DatePipe,
    private socket: SocketService,
    private userService: UsersService,

  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.orderBy = 'DESC';
    this.sortField = 'Order_Creation_Date';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    this.auth.refreshToken();
    this.pricingService.getAvailableStateList().subscribe((states) => { this.states = states });
    if (this.stateService.getQuoteRequest()) {
      this.initializeComponentData(this.stateService.getQuoteRequest());
    } else {
      this.getAllActiveTags();
      this.getquoteRequest()
    }
    this.getAdminOrganizationList();
    this.getAllOrganizationList();
    if (this.config.getNewClientTagId()) this.newClientTagId = this.config.getNewClientTagId();
    if (this.config.getPriorityClientTagId()) this.priorityClientTagId = this.config.getPriorityClientTagId();
    if (this.config.getPrePaidTagId()) this.prePaidTagId = this.config.getPrePaidTagId();
    if (this.config.getPartiallyDeliveredTagId()) this.partiallyDeliveredTagId = this.config.getPartiallyDeliveredTagId();
    if (this.config.getAbsDoubleAssignTagId()) this.absDoubleAssignTagId = this.config.getAbsDoubleAssignTagId();
    setTimeout(() => this.config.setSideBarNode(26), 0);
    this.orderStatus = this.orderService.getOrderStatus();
    this.socket.syncMessage("quoteReq").subscribe((result) => {
      console.log("CAUGHT EVENT EMITTED  QUOTE CREATE............")
      // this.getQuotesOnBg();
    })
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  checkDemoFlag(event) {
    if (event.checked == true) this.isDemoFlag = true;
    else this.isDemoFlag = false;
    this.getquoteRequest();
  }

  getQuotesOnBg() {
    if (this.pageNum == 1) {
      this.stateService.setQuoteRequest(this);
      this.quoteService.getBgQuoteRequestData(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags)
        .subscribe((result) => {
          if (result) this.getQuotesData(result);
        })
    } else {
      this.getRetainQuoteBG()
    }
  }


  getRetainQuoteBG() {
    var ObsCollection = [];
    let pageSize = this.pageNum * this.config.getNumOrdRecordsPerPage();
    ObsCollection.push(this.quoteService.getBgQuoteRequestData(1, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags, pageSize))
    // for (var i = 1; i <= this.pageNum; i++) {
    //   ObsCollection.push( this.quoteService.getBgQuoteRequestData(i, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags,))
    // }
    observableForkJoin(ObsCollection)
      .subscribe((retainedNewOrd) => {
        this.getRetainedNewQuote(retainedNewOrd);
      })
  }

  getRetainedNewQuote(retainedNewOrd) {
    var totalOrders = [];
    for (var i = 0; i < retainedNewOrd.length; i++) {
      this.stateService.setQuoteRequest(this);
      totalOrders = totalOrders.concat(retainedNewOrd[i]['rows']);
    }
    this.orders = totalOrders;
    this.totalCount = retainedNewOrd[0]['count'];
    this.tagsOrderCount = retainedNewOrd[0]['tagsWithOrderCount'];
    this.selectedOrgIds = [];
    this.addCheckedProperty(this.orders);
    this.addOrderCountToTags(retainedNewOrd[0]['tagsWithOrderCount']);
    this.getOrdersRecordsData(this.totalCount);
    this.getScrollQuoteData();
  }

  getOrdersRecordsData(result) {
    this.config.setClearTimeout();
    this.setDisplayOrders();
    this.pager = this.pagerService.getPagerOrders(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }


  setDisplayOrders() {
    let start = ((this.pageNum - 1) * this.config.getNumOrdRecordsPerPage());
    if (this.totalCount != undefined) this.ordersToDisplay = this.orders.slice(start, this.totalCount);
    if (this.ordersToDisplay.length > this.config.getNumOrdRecordsPerPage()) this.ordersToDisplay = this.ordersToDisplay.slice(0, this.config.getNumOrdRecordsPerPage());
  }


  initializeComponentData(data) {
    let orderState = {
      orderStatus: CONSTANTS.orderStatus.received,
      org_ID: data.selectedOrgId,
      state: data.selectedState,
      county: data.selectedCounty,
      quote: data.selectedQuoteType,
    }
    this.orderService.getAssociatedTags(orderState)
      .subscribe((tags) => {
        let filterdata = _.intersection(data.selectedTags, tags)
        this.selectedTags = filterdata;
        this.selectedorg = data.selectedOrgId;
        this.selecteState = data.selectedState;
        this.selectecounty = data.selectedCounty;
        this.selecteQuoteType = data.selectedQuoteType;
        this.pager = data.pager;
        this.pageNum = data.pageNum;
        this.searchString = data.searchString;
        this.orderBy = data.orderBy;
        this.sortField = data.sortField;
        this.selectedOrgId = data.selectedOrgId;
        this.selectedOrgId2 = data.selectedOrgId2;
        this.selectedState = data.selectedState;
        this.selectedCounty = data.selectedCounty;
        this.selectedQuoteType = data.selectedQuoteType;
        this.filterBy = data.filterBy;
        this.counties = data.counties;
        this.totalCount = data.totalCount;
        this.searchInProgress = data.searchInProgress;
        this.isDemoFlag = data.isDemoFlag;
        this.selectAllTags = data.selectAllTags;
        this.allNegativeTags = data.allNegativeTags;
        this.negativeTag = data.negativeTag;
        this.selectedNegTags = data.selectedNegTags;
        this.isNegTagSelected = data.isNegTagSelected;
        this.intialTag = data.intialTag;
        this.allActiveTags = data.allActiveTags
        // this.getAllActiveTags();
        this.getquoteRequest();
      })
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }

  openTagMenu(event, order) {
    event.stopPropagation();
    this.assignPreselectedTags(order);
  }

  assignPreselectedTags(order) {
    if (order.Tag_Names) this.associatedTags = order.Tag_Names
  }

  trackOrders(order) {
    return order.Order_ID;
  }


  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }
  resetPageNum() {
    this.filterModel.pageNum = 1;
  }



  getquoteRequest() {
    if(!this.selectedQuoteType) {
      this.filterBy.push({ order_Status: CONSTANTS.orderStatus.received });
      this.selectedQuoteType = "1";
    }
    this.currTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime();
    if (this.pageNum == 1) {
      this.stateService.setQuoteRequest(this);
      this.quoteService.getQuoteRequestData(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags, this.isDemoFlag)
        .subscribe((result) => {
          if (result) this.getQuotesData(result)

        }), (err) => {
          console.log("Checking errors", err)
        }
    } else this.getRetainQuoteRequestData();
  }

  getRetainQuoteRequestData() {
    var ObsCollection = [];
    let pageSize = this.pageNum * this.config.getNumOrdRecordsPerPage();
    ObsCollection.push(this.quoteService.getQuoteRequestData(1, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags,this.selectedNegTags, this.isDemoFlag, pageSize))
    // for (var i = 1; i <= this.pageNum; i++) {
    //   ObsCollection.push(this.quoteService.getQuoteRequestData(i ,this.sortField,this.orderBy,this.getSearchString(),this.filterBy,this.selectedTags,this.selectedNegTags,this.isDemoFlag))
    // }
    observableForkJoin(ObsCollection)
      .subscribe((retainedQuotes) => {
        this.getRetainedNewQuote(retainedQuotes);
      })
  }

  getQuotesData(result) {
    this.orders = result.rows;
    this.totalCount = result['count'];
    this.getRequestOrderData(this.totalCount);
    this.addOrderCountToTags(result['tagsWithOrderCount']);
    this.getScrollQuoteData();
  }

  csvExport() {

    const fields = [
      {
        label: 'Sl No',
        value: 'serialNumber'
      },
      {
        label: 'Request Quote Number',
        value: 'Order_ID'
      },
      {
        label: 'Client',
        value: (row, User_First_Name) => {
          if (row.Organization_Name) return row.Client_User_Full_Name + ' - ' + row.Organization_Name;
          else return row.Client_User_Full_Name;
        }
      },
      {
        label: 'Qualia Order',
        value: 'qualiaOrder'
      },
      {
        label: 'Owner/Seller',
        value: 'Property_First_Name'
      },
      {
        label: 'Property Address',
        value: (row, Property_Address_1) => {
          var completeAddress = '';
          if (row.Property_Address_1) completeAddress += row.Property_Address_1
          else return completeAddress;
          if (row.Property_Address_2) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_Address_2
          }
          if (row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_City + ', ' + row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          if (!row.Property_City) {
            if (completeAddress) completeAddress += '\t'
            completeAddress += row.Property_State_Abbr + ' ' + row.Property_ZipCode
          }
          return completeAddress;
        }
      },
      {
        label: 'State',
        value: 'Property_State_Abbr'
      },
      {
        label: 'County',
        value: 'Property_County'
      },
      {
        label: 'Status',
        value: (record, Order_Status) => {
          if (record.Order_Status == CONSTANTS.orderStatus.received) return "Received";
          else if (record.Order_Status == CONSTANTS.orderStatus.cancelled)
            return "Cancelled";
          else if (record.Order_Status == CONSTANTS.orderStatus.assigned)
            return "Assigned";
          else if (record.Order_Status == CONSTANTS.orderStatus.confirmed)
            return "Confirmed";
          else if (record.Order_Status == CONSTANTS.orderStatus.processing)
            return "Processing";
          else if (record.Order_Status == CONSTANTS.orderStatus.completed)
            return "Completed";
          else if (record.Order_Status == CONSTANTS.orderStatus.quotecompleted)
            return "Completed";
        }
      },
      {
        label: 'Submission Date',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'MMM d, yyyy');
        }
      },
      {
        label: 'Submission Time (ET)',
        value: (row, Order_Creation_Date) => {
          return this._datePipe.transform(row.Order_Creation_Date_EST, 'h:mm a');
        }
      },
      {
        label: 'Completed Date',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Status == CONSTANTS.orderStatus.quotecompleted) return this._datePipe.transform(row.Order_Completion_Date_EST, 'MMM d, yyyy');
          else if (row.Order_Status == CONSTANTS.orderStatus.cancelled) return this._datePipe.transform(row.Order_Cancellation_Date, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Completed Time (ET)',
        value: (row, Order_Completion_Date) => {
          if (row.Order_Status == CONSTANTS.orderStatus.quotecompleted) return this._datePipe.transform(row.Order_Completion_Date_EST, 'h:mm a');
          else if (row.Order_Status == CONSTANTS.orderStatus.cancelled) return this._datePipe.transform(row.Order_Cancellation_Date, 'h:mm a');
          else return '';
        }
      },
      {
        label: 'Due Date',
        value: (row, Quote_ETA) => {
          if (row.Quote_ETA_EST) return this._datePipe.transform(row.Quote_ETA_EST, 'MMM d, yyyy');
          else return '';
        }
      },
      {
        label: 'Due Time (ET)',
        value: (row, Quote_ETA) => {
          if (row.Quote_ETA_EST) return this._datePipe.transform(row.Quote_ETA_EST, 'h:mm a');
          else return '';
        }
      },      
      {
        label: 'Handled By',
        value: (row, Order_Admin_ID) => {
          if (row.Order_Admin_ID) return `${row.Admin_User_First_Name} ${row.Admin_User_Last_Name}`;
          else return '';
        }
      },
      {
        label: 'Assigned Duration',
        value: (row, Order_Admin_ID) => {
          const duration = this.orderService.getAssignedDuration(row);
          return duration.replace(/[\(\)]/g, ''); // Remove parentheses
        }
      },
      {
        label: 'Tags',
        value: 'Order_Tag_Name'
      },
      // {
      //   label: 'ETA Delay Reason',
      //   value: (row, Order_ETA_Change_Reason) => {
      //     if (row.Order_ETA_Change_Reason) return row.Order_ETA_Change_Reason;
      //     else return '';
      //   }
      // },
    ];

    this.orderService.downloadCSVfile(fields, this.orders, this.allActiveTags)
  }
  getRequestOrderData(result) {
    this.config.setClearTimeout();
    // this.setDisplayOrders();
    this.pager = this.pagerService.getPagerOrders(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }


  goToOrderDetails(quoteId) {
    if (this.checkAdminType()) {
      var getPos = document.documentElement.scrollTop
      this.scrollService.setScrollPosQuotereq(getPos);
      this.router.navigate(['/quotes/' + quoteId + '/view'])
    }
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumOrdRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  getFullAddress(orderDetails) {
    let completeAddress = '';
    if (orderDetails.Property_Address_1) completeAddress += orderDetails.Property_Address_1
    if (orderDetails.Property_Address_2) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += orderDetails.Property_Address_2
    }
    if (orderDetails.Property_City) {
      if (completeAddress) completeAddress += '\n'
      completeAddress += orderDetails.Property_City
    }
    if (orderDetails.Property_State_Abbr) {
      if (completeAddress) completeAddress += ', '
      completeAddress += orderDetails.Property_State_Abbr
    }
    if (orderDetails.Property_ZipCode) {
      if (completeAddress) completeAddress += ' '
      return completeAddress += orderDetails.Property_ZipCode
    }
  }


  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  menuClosed(order) {
    this.searchTag = '';
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  createExcel() {
    this.quoteService.createQuoteRequestExcelReport(this.filterBy, this.searchString, this.sortField, this.orderBy, this.selectedTags,this.selectedNegTags, this.isDemoFlag)
      .subscribe((blobData) => {
        if (blobData) this.composeAndDwnld(blobData);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }


  composeAndDwnld(blobData) {
    let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileUrl = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", "Report.xlsx");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }



  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.quoteService.getQuoteRequestData(this.pageNum, this.sortField, this.orderBy, this.getSearchString(), this.filterBy, this.selectedTags, this.selectedNegTags, this.isDemoFlag)
        .subscribe((result) => {
          if (result) {
            this.totalCount = result['count'];
            if (this.totalCount > this.orders.length) {
              this.addCheckedProperty(result['rows']);
              this.orders = this.orders.concat(result['rows']);
              this.pager = this.pagerService.getPagerOrders(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  addCheckedProperty(orders) {
    orders.map((order) => {
      order.checked = false;
      return order;
    })
  }


  menuOpened(order) {
    this.orderTags = [];
    this.searchTag = '';
    this.getAvailableTags(order).map((tag) => tag.on = false)
    this.associatedTags = [];
  }

  getClientName(order) {
    if (order.Organization_Name) return order.Client_User_Full_Name + " - " + order.Organization_Name
    else return order.Client_User_Full_Name
  }

  searchField(search) {
    this.scrollService.setScrollPosQuotereq(0)
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if (!format.test(this.searchString) && this.searchString == " ") {
      this.searchString = '';
      this.getquoteRequest();
    } else this.findOrders();
  }

  findOrders() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getquoteRequest();
  }

  getSearchString() {
    var format = /[!@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }


  getAllActiveTags() {
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        if (activeTags) {
          this.allActiveTags = []
          if (this.selectedTags.length > 0 || this.allNegativeTags.length>0) {
            activeTags.rows.forEach((chip) => {
              let index=this.allNegativeTags.indexOf(chip.Tag_ID);
              if(index == -1){
                index = this.selectedTags.indexOf(chip.Tag_ID);
                if (index > -1) chip.selected = true;
                this.allActiveTags.push(chip);
              }  
            })
          }else this.allActiveTags=activeTags.rows; 
          if (this.tagsOrderCount.length > 0) this.addOrderCountToTags(this.tagsOrderCount);
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }


  getFontColor(hexColor) {
    return this.tagService.getFontColor(hexColor)
  }

  clearSearchTag() {
    this.searchTag = '';
  }


  addOrderCountToTags(tagsOrderCount) {
    tagsOrderCount.forEach((chip) => {
      this.allActiveTags.forEach((activeChip) => {
        if (chip.Tag_ID == activeChip.Tag_ID) activeChip.Order_Count = chip.Order_Count;
      })
      this.allNegativeTags.forEach((negativeChip) => {
        if (chip.Tag_ID == negativeChip.Tag_ID) negativeChip.Order_Count = chip.Order_Count;
      })
    })
  }
  onChipSelect(chip,i) {
    if(this.selectAllTags && this.negativeTag && Boolean(chip.selected)){
      this.onNegSelectionChange(chip, i);
    }else{
        chip.selected = !chip.selected;
        this.selectAllTags = true;
        this.negativeTag = true;
      this.onSelectionChange(chip);
    }
  }

  onNegSelectionChange(chip,i){
    var index = this.selectedNegTags.indexOf(chip.Tag_ID);
    if (index > -1) {
      this.selectedNegTags.splice(index, 1);
      this.allActiveTags.push(chip)
      chip.selected = !chip.selected
      if(this.allNegativeTags.length>0) this.allNegativeTags.splice(i,1)
      this.isNegTagSelected = true;
      this.pageNum = 1;
      this.setScrollResetPos()
      this.getquoteRequest();
    } else{ 
      this.selectedNegTags.push(chip.Tag_ID);
      this.allActiveTags.splice(i,1)
      this.allNegativeTags.push(chip)
      this.allNegativeTags.map((chip) => {
          chip.deselected = true
        })
      this.isNegTagSelected = false;
      this.onSelectionChange(chip);
    }
  }
  onSelectionChange(chip) {
    var index = this.selectedTags.indexOf(chip.Tag_ID);
    if (index > -1) {
      this.selectedTags.splice(index, 1);
    } else this.selectedTags.push(chip.Tag_ID);
    this.pageNum = 1;
    this.setScrollResetPos()
    this.getquoteRequest();
  }



  deselectAllChips() {
    this.selectedTags = [];
    this.allActiveTags.map((chip) => chip.selected = false);
  }

  getAvailableTags(order) {
    let preselectedTags = [];
    let availableTags = [];
    if (order.Tag_Names) preselectedTags = order.Tag_Names;
    for (var i = 0, len = this.allActiveTags.length; i < len; i++) {
      if (preselectedTags.findIndex((select => this.allActiveTags[i].Tag_ID == select.Tag_ID)) == -1) {
        if (this.allActiveTags[i].Tag_ID != this.newClientTagId && this.allActiveTags[i].Tag_ID != this.priorityClientTagId
          && this.allActiveTags[i].Tag_ID != this.prePaidTagId && this.allActiveTags[i].Tag_ID != this.partiallyDeliveredTagId
          && this.allActiveTags[i].Tag_ID != this.config.getAwaitDlvryTagId() && this.allActiveTags[i].Tag_ID != this.config.getAwaitPartDlvryTagId())
          availableTags.push(this.allActiveTags[i]);
      }
    }
    return availableTags;
  }


  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getquoteRequest();
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  getUserRole() {
    return Number(this.auth.getUserRole())
  }


  getAdminOrganizationList() {
    this.organizationService.addedOrganizationList(this.auth.getUserId())
      .subscribe((result) => {
        if (result) {
          this.organizations = result;
        }
      }, (err) => {
        console.log("err", err);
        // this.errorMsg = err;
      })
  }

  getAllOrganizationList() {
    this.organizationService.getAllOrganizations()
      .subscribe((result) => {
        if (result) {
          this.allorganizations = result;
        }
      }, (err) => {
        console.log("err", err);
        // this.errorMsg = err;
      })
  }

  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getquoteRequest();
  }

  resetAllValue(event) {
    this.selectedCounty = '';
  }
  clearTags(){
    this.selectedTags = [];
    this.allActiveTags.map((chip) => chip.selected = false);
    this.selectedNegTags =[];
    this.allNegativeTags.forEach((negTag)=>{
      negTag.deselected = false;
      negTag.selected = false;
      this.allActiveTags.push(negTag)
    })
    this.allNegativeTags = [];
  }
  resetFilter() {
    this.selectedOrgId = '';
    this.selectedOrgId2 = '';
    this.selectedState = '';
    this.selectedCounty = '';
    this.searchString = '';
    this.selectedQuoteType = '';
    this.filterBy = [];
    this.counties = [];
    this.isDemoFlag = false;
    this.selectAllTags = false;
    this.negativeTag = false;
    this.isNegTagSelected = false;
    this.clearTags()
    this.getquoteRequest();
  }
  deselctAllNegTagsChip(){
    // this.selectedNegTags =[];
    // this.allNegativeTags.map((chip) => chip.deselected = false)
    // this.allNegativeTags.forEach((negTag)=>{
    //   this.allActiveTags.push(negTag)
    // })
    // this.allNegativeTags = [];
  }
  checkIfFilterPresent() {
    if (this.selectedOrgId == 'all' || this.selectedOrgId2 == 'all' || this.selectedState == 'all' || this.searchString || this.selectedQuoteType === 0 ||
      (this.filterBy.length > 0) || (this.selectedTags.length > 0) || (this.selectedNegTags.length > 0) || this.isDemoFlag) return true

    else return false

  }
  getCountyList(event) {
    if (event == 'all') {
      this.selectedCounty = 'all';
      this.applyFilter();
    }
    if (event.length != 0 && event != 'all') {
      this.pricingService.getAvailableCountyList(this.selectedState).subscribe((counties) => { this.counties = counties });
      this.applyFilter();
    }
  }

  applyFilter() {
    this.setScrollResetPos();
    this.pageNum = 1;
    this.filterBy = [];
    if (this.selectedOrgId && this.selectedOrgId != 'all') this.filterBy.push({ Organization_ID: this.selectedOrgId })
    if (this.selectedOrgId2 && this.selectedOrgId2 != 'all') this.filterBy.push({ Organization_ID: this.selectedOrgId2 })
    if (this.selectedState && this.selectedState != 'all') this.filterBy.push({ Property_State_Abbr: this.selectedState })
    if (this.selectedCounty && this.selectedCounty != 'all') this.filterBy.push({ Property_County: this.selectedCounty })
    if (this.selectedQuoteType && this.selectedQuoteType != 0) this.filterBy.push({ order_Status: this.selectedQuoteType })
    this.getquoteRequest();
  }

  setScrollResetPos() {
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosQuotereq(x);
  }


  getScrollQuoteData() {
    if (this.scrollService.getScrollPosQuotereq()) {
      var x = this.scrollService.getScrollPosQuotereq()
      setTimeout(function () {
        window.scrollTo(0, x)
      })
    }
  }

  modifyOrderTags(event, tag, order) {
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    if (this.selectedTags.length > 0) this.deselectAllChips();
    if (this.selectedNegTags.length > 0) this.deselctAllNegTagsChip();
    var isTagToAdd: boolean;
    if (event.checked) isTagToAdd = true;
    else isTagToAdd = false;
    this.orderService.modifyQuoteTags({ Order_ID: order.Order_ID, Order_Tag: tag, isTagToAdd: isTagToAdd })
      .subscribe((orderTagdata) => {
        order.Tag_Names = orderTagdata.Tag_Names;
        order.Order_Tags = orderTagdata.Order_Tags;
        if (selectedTagsCopy.length > 0) {
          this.matMenuTrigger.closeMenu();
          this.getquoteRequest();
        }
        else this.getquoteRequest();
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderTagging);
        this.matMenuTrigger.closeMenu();
      })
  }


  openNewTagModal(order) {
    var selectedTagsCopy = Object.assign([], this.selectedTags);
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID: order.Order_ID }
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
        this.tagsOrderCount = [];
        this.getAllActiveTags();
        // this.getAvailableTagLocal();
        this.getquoteRequest();
        if (selectedTagsCopy.length > 0) this.getquoteRequest();
        else {
          this.getOrderTags(order);
        }
      }
    });
  }


  getOrderTags(order) {
    this.orderService.getOrderTags(order.Order_ID)
      .subscribe((ordTags) => {
        if (ordTags) {
          let getAllTagID = "";
          ordTags.forEach((tag) => {
            getAllTagID += "," + tag.Tag_ID;
          })
          order.Order_Tags = getAllTagID;
          order.Tag_Names = ordTags;
        }
        else order.Tag_Names = [];
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  isSuperAdmin() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else if (this.auth.getUserId() == environment.superAdminId) return true;
    else if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }

  getAdminFullName(order) {
    if (order.Admin_User_First_Name && order.Admin_User_Last_Name) return order.Admin_User_First_Name + ' ' + order.Admin_User_Last_Name;
    else return "";
  }

  orderAssignee(order) {
    var data = {};
    if (this.selectedOrgIds.length > 0) data['Organization_IDs'] = this.selectedOrgIds;
    if (order.Organization_ID) data['Organization_ID'] = order.Organization_ID;
    this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
      let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
        title: 'Assign Quote',
        message: {
          admins: adminData['admins'],
          Search_Masters: adminData['searchers'],
          Current_Admin: order.Order_Admin_ID
        }
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res:any) => {
          if (res) {
            let msgType;
            if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
            else {
              res.msgText = ""
              msgType = CONSTANTS.messageType.internalNotes;
            }

            if (res.admin && res.getTimeToCompl) {

              this.orderService.changeQuoteAssignee(order.Order_ID, res.admin, res.msgText, msgType, res.getTimeToCompl)
                .subscribe((data) => {
                  this.selectedOrgIds = [];
                  this.getquoteRequest();
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
            } else {
              this.orderService.unassignOrder(order.Order_ID, res.msgText, msgType, order.Order_Status).subscribe((data) => {
                this.selectedOrgIds = [];
                this.getquoteRequest();
              }, (err) => {
                this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
              })

            }
          }
        }, (err) => { console.log(err); })
    })
  }
}

