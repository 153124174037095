import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import * as moment from "moment";
import { MatSelect } from "@angular/material/select";
import { CONSTANTS } from "app/app.constants";
import { ConfigService, OrdersService } from "app/services";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import * as momentTimeZone from "moment-timezone";
import { ConfirmComponent } from "../confirm/confirm.component";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-order-assign",
  templateUrl: "./order-assign.component.html",
  styleUrls: ["./order-assign.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class OrderAssignComponent  {
  @ViewChild("okBtn") focusedElement: ElementRef;
  @ViewChild("myInput") myInput: ElementRef;
  @ViewChild("myFirstFocus") myFirstFocus: MatSelect;
  @ViewChild("etaDatePicker") etaDatePicker: ElementRef<HTMLElement>;
  errors: any = {};
  message: any = {};
  title = "";
  selectedOrder: any = null;
  filteredOrders: any = [];
  unassignedOrders: any = [];
  orderStatus: any;
  listOfHolidays: any;
  weekendHolidayName: String;
  dueDateTimeCtrl = new UntypedFormControl(null);
  timeToComplete: string;
  messageText = "";
  timer: any = [];
  newOrderForm: UntypedFormGroup;
  order_ETA_Time: string;
  orderETAtime = new UntypedFormControl("", [Validators.required]);
  isDueRangeErr: boolean = false;
  dueDateFrmObj: any;
  dueDateTimeObj: any;
  dueDate_Range: any;
  dueDateRangeOption: Number;
  order_ETA_Date: any;
  validDueDate: boolean = false;
  etaChangeReasonTypeId: any = null;
  etaChangeComment:any = null;
  etaChangeEmail:any = null;
  etaChangeNotify:any = null;
  etaChangeClientStatus:any = null;
  constructor(
    private frmBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<OrderAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog:MatDialog,
    private config: ConfigService,
    private orderService: OrdersService,
    private ref: ChangeDetectorRef
  ) {
    
    this.newOrderForm = this.frmBuilder.group({
      Order_ETA: [{ disabled: false, value: "" }],
    });
  }

  ngOnInit() {
    this.initDialog();
    this.unassignedOrders = this.message["orderList"];
    this.filteredOrders = this.unassignedOrders;
    this.orderStatus = this.orderService.getWatchOrderStatus();
    // this.getAvlOrderETAnTime();
    this.timer.push(
      setTimeout(() => {
        this.myFirstFocus.focus();
      }, 0)
    );
  }

  initDialog(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  getAvlOrderETAnTime() {
    if (this.message["Order_ETA"]) {
      let etaFullDate = new Date(
        momentTimeZone
          .tz(this.message["Order_ETA"], "America/New_York")
          .format("MM/DD/YYYY HH:mm")
      );
      let date = new Date(etaFullDate.toDateString());
      this.message["Order_ETA"] = date;
      let etaHours = etaFullDate.getHours().toString();
      let etaMinutes = etaFullDate.getMinutes().toString();
      if (etaHours.length == 1) etaHours = "0" + etaHours;
      if (etaMinutes.length == 1) etaMinutes = "0" + etaMinutes;
      this.order_ETA_Time = etaHours + ":" + etaMinutes;
    } else {
      this.message["Order_ETA"] = null;
      this.order_ETA_Time = "17:00";
    }
    this.ref.detectChanges();
  }

  searchOptions(query) {
    if (query.keyCode == 32 && query.type == "keydown") query.stopPropagation();
    this.filteredOrders = this.select(query.target.value);
  }

  clicked(event) {
    if (this.selectedOrder && this.selectedOrder.Order_ETA){
      this.message["Order_ETA"] = new Date(this.selectedOrder.Order_ETA);
      this.getAvlOrderETAnTime();
      this.validDueDate = true;
    }
    if (event)
      this.timer.push(setTimeout(() => this.myInput.nativeElement.focus(), 0));
    else this.clearValue(this.myInput);
  }

  isOrderConfirmed() {
    return (
      this.selectedOrder &&
      this.selectedOrder.Order_Status == CONSTANTS.orderStatus.confirmed &&
      this.selectedOrder.Order_ETA
    );
  }

  select(query: string): string[] {
    let result: string[] = [];
    for (let a of this.unassignedOrders) {
      if (
        a["Order_ID"].toString().indexOf(query.toLowerCase().trim()) > -1 ||
        (a["Organization_Name"] &&
          a["Organization_Name"]
            .toLowerCase()
            .indexOf(query.toLowerCase().trim()) > -1) ||
        (a["Customer_Name"] &&
          a["Customer_Name"].toLowerCase().indexOf(query.toLowerCase().trim()) >
            -1) ||
        (a["Product_Description"] &&
          a["Product_Description"]
            .toLowerCase()
            .indexOf(query.toLowerCase().trim()) > -1) ||
        (a["Property_Address_1"] &&
          a["Property_Address_1"]
            .toLowerCase()
            .indexOf(query.toLowerCase().trim()) > -1) ||
        (a["Property_City"] &&
          a["Property_City"].toLowerCase().indexOf(query.toLowerCase().trim()) >
            -1) ||
        (a["Property_County"] &&
          a["Property_County"]
            .toLowerCase()
            .indexOf(query.toLowerCase().trim()) > -1) ||
        (a["Property_State_Abbr"] &&
          a["Property_State_Abbr"]
            .toLowerCase()
            .indexOf(query.toLowerCase().trim()) > -1) ||
        (a["Order_Status"] &&
          this.orderStatus[a["Order_Status"]]
            .toLowerCase()
            .indexOf(query.toLowerCase().trim()) > -1)
      ) {
        result.push(a);
      }
    }
    return result;
  }

  clearValue(myInput) {
    if (myInput.nativeElement) myInput.nativeElement.value = "";
    else myInput.value = "";
    this.filteredOrders = this.unassignedOrders;
  }

  getFormObject(event) {
    this.dueDateTimeCtrl = event;
    if (
      !this.dueDateTimeCtrl.value.taskDueDay &&
      !this.dueDateTimeCtrl.value.taskDueHour
    )
      this.timeToComplete = null;
    if (this.dueDateTimeCtrl.value.taskDueDay) {
      this.timeToComplete = this.dueDateTimeCtrl.value.taskDueDay + "d";
      if (Boolean(this.dueDateTimeCtrl.value.taskDueHour))
        this.timeToComplete +=
          " " + this.dueDateTimeCtrl.value.taskDueHour + "h";
    } else if (this.dueDateTimeCtrl.value.taskDueHour)
      this.timeToComplete = this.dueDateTimeCtrl.value.taskDueHour + "h";
  }

  Close() {
    this.dialogRef.close(false);
    this.clearTime();
  }

  clearTime() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  validateOrderETADateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP =
      /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/; //for m/d/yyyy and mm/dd/yyyy
    if (
      event.targetElement.value &&
      !DATE_REGEXP.test(event.targetElement.value)
    )
      this.errors.Order_ETA = true;
    else delete this.errors.Order_ETA;
  }

  getDueDateData(event) {
    this.dueDateRangeOption = Number(event.dueDateType);
    if (Number(event.dueDateType)) {
      if (event.dueDateFrmCtrl) {
        this.isDueRangeErr = true;
        this.dueDateFrmObj = event.dueDateFrmCtrl;
        if (this.dueDateFrmObj) {
          if (
            this.dueDateFrmObj.value.fromDayCtrl &&
            this.dueDateFrmObj.value.toDayCtrl
          ) {
            let getDueDateRange =
              this.dueDateFrmObj.value.fromDayCtrl +
              "-" +
              this.dueDateFrmObj.value.toDayCtrl;
            let addDays: any = momentTimeZone
              .tz("America/New_York")
              .add(Number(this.dueDateFrmObj.value.toDayCtrl), "days")
              .format();
            let getDateObj = this.Convert_UTCDate_To_LocalDate(
              addDays,
              "America/New_York"
            );
            this.dueDate_Range = getDateObj;
            this.message["Order_ETA_Range"] = getDueDateRange;
          } else {
            if (
              this.dueDateFrmObj.controls["fromDayCtrl"].touched &&
              !this.dueDateFrmObj.value.fromDayCtrl
            )
              this.dueDateFrmObj.controls["fromDayCtrl"].setErrors({
                fromDayRequired: true,
              });
            if (
              this.dueDateFrmObj.controls["toDayCtrl"].touched &&
              !this.dueDateFrmObj.value.toDayCtrl
            )
              this.dueDateFrmObj.controls["toDayCtrl"].setErrors({
                toDayRequired: true,
              });
          }
        }
      }
    } else {
      this.isDueRangeErr = false;
      this.dueDate_Range = null;
      this.message["Order_ETA_Range"] = null;
      if (event.dueDateTimeCtrl) {
        this.dueDateTimeObj = event.dueDateTimeCtrl;
        if (event.dueDateTimeCtrl.valid) this.checkForDueDateEST(event);
      } else this.checkForDueDateEST(event);
    }
    if(event.hasOwnProperty('reason')){
      this.etaChangeReasonTypeId = event.reason;
    }
    if(event.hasOwnProperty('comment')){
      this.etaChangeComment = event.comment;
    }
    if(event.hasOwnProperty('emailContent')){
      this.etaChangeEmail= event.emailContent;
    }
    if(event.hasOwnProperty('notifyCustomer')){
      this.etaChangeNotify = event.notifyCustomer;
    }
    if(event.hasOwnProperty('clientNotifStatus')){
      this.etaChangeClientStatus = event.clientNotifStatus;
    }
  }

  Convert_UTCDate_To_LocalDate(utcTime: Date, localTimeZone: string) {
    var utcMoment = momentTimeZone(utcTime);
    var localDateTime = utcMoment.tz(localTimeZone);
    return new Date(localDateTime.format("lll"));
  }

  checkForDueDateEST(event) {
    if (event.Due_Date_EST) {
      if (event.Due_TIme_EST) {
        this.message["Order_ETA"] = event.Due_Date_EST;
        this.order_ETA_Time = event.Due_TIme_EST;
        this.validDueDate = true;
      }
    } else this.validDueDate = false;
  }

  confirm(orderETA) {
    let dueDate_ETA;
    let finalDueDate;
    if (this.checkIfETAChanged() && this.selectedOrder.Order_ETA)
      this.checkForSelectedETA(orderETA, finalDueDate, dueDate_ETA);
    else this.dueDateProcess(finalDueDate, orderETA, dueDate_ETA);
  }

  checkForSelectedETA(orderETA, finalDueDate, dueDate_ETA) {
    // let orderETADate = moment(orderETA).format("YYYY-MM-DD");
    // let checkETAIsHoliday = this.listOfHolidays.findIndex((holiday) => {
    //   if (
    //     holiday &&
    //     moment(holiday.Holiday_Date, "YYYY-MM-DD").isSame(orderETADate)
    //   )
    //     return true;
    //   else return false;
    // });
    // if (checkETAIsHoliday > -1)
    //   this.showWarningPopup(
    //     this.listOfHolidays[checkETAIsHoliday].Holiday_Name,
    //     finalDueDate,
    //     dueDate_ETA,
    //     orderETA
    //   );
    // else if (this.checkETALieOnWeekend(orderETA))
    //   this.showWarningPopup(
    //     this.weekendHolidayName,
    //     finalDueDate,
    //     dueDate_ETA,
    //     orderETA
    //   );
    // else this.dueDateProcess(finalDueDate, orderETA, dueDate_ETA);
    this.dueDateProcess(finalDueDate, orderETA, dueDate_ETA);
  }

  checkIfETAChanged() {
    let newETADate = moment(this.message.Order_ETA).format("YYYY-MM-DDTHH:mm");
    if (
      moment(this.selectedOrder.Order_ETA, "YYYY-MM-DDTHH:mm").isSame(
        newETADate
      )
    )
      return false;
    else return true;
  }

  dueDateProcess(finalDueDate, orderETA, dueDate_ETA) {
    if (this.dueDate_Range && this.message["Order_ETA_Range"]) {
      this.order_ETA_Time = "17:00";
      finalDueDate = this.setOrderETATime(this.dueDate_Range);
    } else {
      if (this.message["Order_ETA_Range"])
        this.message["Order_ETA_Range"] = null;
      finalDueDate = this.setOrderETATime(orderETA);
    }
    var estShift = momentTimeZone.tz(finalDueDate, "America/New_York").isDST()
      ? 4
      : 5;
    var etaInEstTimezone = moment(finalDueDate)
      .utcOffset(moment(finalDueDate).utcOffset())
      .add(estShift, "hours")
      .format("YYYY-MM-DDTHH:mm");
    dueDate_ETA = this.isOrderConfirmed() ? etaInEstTimezone : null;
    if(!this.isOrderConfirmed()) this.message["Order_ETA_Range"] = null;
    this.sendPrcoessDetails(dueDate_ETA);
  }

  showWarningPopup(holidayName, finalDueDate, dueDate_ETA, orderETA) {
   this.matDialog
      .open(
        ConfirmComponent,
        {data:{
          title: "ETA Note",
          message:
            "The ETA on this order is a US holiday (" +
            holidayName +
            "). Would you like to continue?",
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {
        if (res) this.dueDateProcess(finalDueDate, orderETA, dueDate_ETA);
      });
  }

  checkETALieOnWeekend(orderETA) {
    if (moment(orderETA).isoWeekday() == 7) {
      // iso Weekday for sunday is 7
      this.weekendHolidayName = "Sunday";
      return true;
    } else if (moment(orderETA).isoWeekday() == 6) {
      // iso Weekday for saturday is 6
      this.weekendHolidayName = "Saturday";
      return true;
    } else return false;
  }

  setOrderETATime(orderETA) {
    if (orderETA && orderETA._d) {
      let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
      let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
      return orderETA._d.setHours(
        Number(getOrderEtaHours),
        Number(getOrderEtaMinutes)
      );
    } else if (orderETA && !orderETA._d) {
      let getOrderEtaHours = this.order_ETA_Time.split(":")[0];
      let getOrderEtaMinutes = this.order_ETA_Time.split(":")[1];
      return orderETA.setHours(
        Number(getOrderEtaHours),
        Number(getOrderEtaMinutes)
      );
    } else return null;
  }

  sendPrcoessDetails(dueDate_ETA) {
    this.dialogRef.close({
      Order_ID: this.selectedOrder.Order_ID,
      Order_ETA: dueDate_ETA,
      Orig_ETA: this.selectedOrder.Order_ETA,
      admin: this.message["selectedAdmin"],
      msgText: this.messageText.trim(),
      getTimeToCompl: this.timeToComplete,
      Order_ETA_Range: this.message["Order_ETA_Range"],
      Order_ETA_Change_Reason_Type_ID : this.etaChangeReasonTypeId,
      Order_ETA_Comment: this.etaChangeComment,
      Email_Body: this.etaChangeEmail,
      Notify_Customer: this.etaChangeNotify,
      Client_Notif_Status: this.etaChangeClientStatus
    });
    this.clearTime();
  }

  checkForDueDateValid() {
    if (!this.message["Order_ETA"] && !this.message["Order_ETA_Range"])
      return true;
    else {
      if (this.dueDateRangeOption) {
        if (this.dueDateFrmObj && this.dueDateFrmObj.valid) return false;
        else return true;
      } else {
        if (this.dueDateTimeObj) {
          if (this.dueDateTimeObj.valid) return false;
          else if (!this.dueDateTimeObj.valid) return true;
        } else return false;
      }
    }
  }

  isDataValid() {
    if(this.isOrderConfirmed()) {
        return (
          !this.selectedOrder ||
          this.errors.Order_ETA == true  ||
          this.checkForDueDateValid() ||
          !this.validDueDate ||
          !this.timeToComplete
        );  
    } else {
      return (
        !this.selectedOrder ||
        !this.timeToComplete
      );  
    }
  }
}
