import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common'

@Component({
  selector: 'app-rb-section-scroller',
  templateUrl: './rb-section-scroller.component.html',
  styleUrls: ['./rb-section-scroller.component.scss']
})
export class RbSectionScrollerComponent implements OnInit {
  @Output() focusFirsttItem= new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  scrollToPageTop(){
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  scrollToSectionTop(){
    this.focusFirsttItem.emit(true);
  }

}
