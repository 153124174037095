import {
  Component,
  Input,
  OnChanges,
  OnInit,
  signal,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenu } from "@angular/material/menu";
import { LogDialogComponent } from "app/components/log-dialogue/log-dialogue.component";
import { isNA } from "app/utils/utils";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-ai-fill-in-blanks",
  templateUrl: "./ai-fill-in-blanks.component.html",
  styleUrls: ["./ai-fill-in-blanks.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AiFillInBlanksComponent implements OnInit, OnChanges {
  @Input() templateText: string = "";
  @Input() isRemoteLanguage: boolean = false;
  @Input() autoFillCallback!: () => Promise<any>;
  @Input() saveCommitmentCallback!: (finalText: string, fieldSet: any) => void;
  @Input() saveCommitmentParsedTemplate!: (parsedTemplate: any) => void;
  @Input() fetchCommitmentLogs!: (commitmentId: any) => Array<any>;
  @Input() parsedTemplateFromBE: any;

  @ViewChild("paiMenu") paiMenu: MatMenu;
  hasBeenClicked = false;
  showVariables = false;
  isLoading = false;
  private acceptedStateSubject = new BehaviorSubject<boolean>(true);
  acceptedState$ = this.acceptedStateSubject.asObservable();
  private _paiButtonState: string = 'default-state';
  get paiButtonState(): string {
    return this._paiButtonState;
  }

  placeholderState: {
    [key: string]: {
      value: string;
      originalValue: string;
      suggestedValue?: string;
      status: "default" | "edited" | "accepted" | "autofilled" | "unsure";
      isAccepted: boolean;
      originalPlaceholderForFieldSet: string;
    };
  } = {};

  textState: {
    [index: number]: {
      value: string;
      originalValue: string;
      isEdited: boolean;
    };
  } = {};

  parsedTemplate: {
    isPlaceholder: boolean;
    text?: string;
    key?: string;
    index?: number;
  }[] = [];

  private inputSubject = new Subject<{
    key: string;
    value: string;
    isPlaceholder: boolean;
    index?: number;
  }>();

  private updateAcceptedState(): void {
    const placeholders = this.parsedTemplate.filter(e=> e.isPlaceholder).map(e=> e.key.trim())
    const values = placeholders.map(k => this.placeholderState[k]).filter(e=>e);
    if (values.length === 0) {
      this.acceptedStateSubject.next(true);
      return;
    }
    const allAccepted = values.length > 0 && values.every((e) => e.isAccepted);
    this.acceptedStateSubject.next(allAccepted);
  }

  private saveTemplateState(): void {
    const templateState = {
      parsedTemplate: this.parsedTemplate,
      placeholderState: this.placeholderState,
      textState: this.textState,
    };
    this.saveCommitmentParsedTemplate(templateState);
    const finalText = this.getFinalText();
    const fieldSet = this.getFieldStateFromPlaceholdersState();
    this.saveCommitmentCallback(finalText, fieldSet);
  }

  openDialogue() {
    this.dialog.open(LogDialogComponent, {
      width: "85vw",
      height: "50vh",
      data: {
        fetchLogs: this.fetchCommitmentLogs,
      }, 
    });
  }

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.parsedTemplateFromBE && this.parsedTemplateFromBE.parsedTemplate) {
      this.initializeFromParsedTemplate();
    } else {
      this.initializePlaceholderState();
      this.parseTemplate(this.templateText);
    }
    this.updateAcceptedState();
    this.updatePaiButtonState();
    this.inputSubject
      .pipe(debounceTime(250))
      .subscribe(({ key, value, isPlaceholder, index }) => {
        if (isPlaceholder) {
          this.placeholderState[key].value = value;
          this.placeholderState[key].status = "edited";
        } else if (index !== undefined) {
          this.textState[index] = {
            ...this.textState[index],
            value: value,
            isEdited: true,
          };
        }
        this.saveTemplateState();
        this.updatePaiButtonState();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["templateText"] && !changes["templateText"].firstChange) {
      const currentTemplate = changes['templateText'].currentValue
      this.initializePlaceholderState(currentTemplate);
      this.parseTemplate(currentTemplate);
      this.updateAcceptedState();
    }
    if (
      changes["parsedTemplateFromBE"] &&
      !changes["parsedTemplateFromBE"].firstChange
    ) {
      if (
        this.parsedTemplateFromBE &&
        this.parsedTemplateFromBE.parsedTemplate
      ) {
        this.initializeFromParsedTemplate();
        this.updateAcceptedState();
        this.updatePaiButtonState()
      }
    }
  }

  private isAIUsedAlready(){
   return Object.values(this.placeholderState).some(e=> e.status !== 'default')
  }

  initializeFromParsedTemplate() {
    this.parsedTemplate = this.parsedTemplateFromBE.parsedTemplate;
    this.placeholderState = this.parsedTemplateFromBE.placeholderState;
    this.textState = this.parsedTemplateFromBE.textState;
  }

  getFinalText(): string {
    let finalText = "";
    this.parsedTemplate.forEach((part) => {
      if (part.isPlaceholder) {
        const placeholderValue =
          this.placeholderState[part.key!]?.value ??
          this.placeholderState[part.key!]?.originalValue;
        finalText += ` ${placeholderValue?.trim()} `;
      } else if (part.index !== undefined) {
        finalText += this.textState[part.index]?.value ?? " ";
      }
    });
    return finalText;
  }

  initializePlaceholderState(templateText = '') {
    const template = templateText ? templateText: this.templateText
    const regex = /(?:\*\w+\*)|(?:_[^_]+_)|(?:{[^}]+})/g;
    const matches = Array.from(template.matchAll(regex));
    this.placeholderState = {};
    matches.forEach((match) => {
      const placeholder = match[0];
      let key: string;

      if (placeholder.startsWith("*") && placeholder.endsWith("*")) {
        key = placeholder.slice(1, -1);
      } else if (placeholder.startsWith("_") && placeholder.endsWith("_")) {
        key = placeholder.slice(1, -1);
      } else if (placeholder.startsWith("{") && placeholder.endsWith("}")) {
        key = placeholder.slice(1, -1);
      } else {
        key = placeholder;
      }

      this.placeholderState[key] = {
        value: key,
        originalValue: key,
        status: "default",
        isAccepted: false,
        originalPlaceholderForFieldSet: placeholder,
      };
    });
  }

  hasAcceptedAllValues(): boolean {
    const values = Object.values(this.placeholderState);
    const nonAcceptedValues = values.filter((e) => !e.isAccepted);
    return nonAcceptedValues.length === 0;
  }

  updateShowVariables():void{
    this.showVariables = !this.showVariables
  }

  parseTemplate(template :string) {
    if(!template) return;
    // this function is responsible for generating the span array also the textSpanState -- call it carefully
    const regex = /(?:\*\w+\*)|(?:_[^_]+_)|(?:{[^}]+})/g;
    let lastIndex = 0;
    const matches = Array.from(template.matchAll(regex));
    let textPartIndex = 0;

    this.parsedTemplate = [];
    this.textState = {}
    matches.forEach((match) => {
      const startIndex = match.index || 0;

      if (startIndex > lastIndex) {
        const text = template.substring(lastIndex, startIndex);
        this.textState[textPartIndex] = {
          value: text,
          originalValue: text,
          isEdited: false,
        };
        this.parsedTemplate.push({
          isPlaceholder: false,
          text: text,
          index: textPartIndex,
        });
        textPartIndex++;
      }

      const placeholder = match[0];
      let key: string;

      if (placeholder.startsWith("*") && placeholder.endsWith("*")) {
        key = placeholder.slice(1, -1);
      } else if (placeholder.startsWith("_") && placeholder.endsWith("_")) {
        key = placeholder.slice(1, -1);
      } else if (placeholder.startsWith("{") && placeholder.endsWith("}")) {
        key = placeholder.slice(1, -1);
      } else {
        key = placeholder;
      }

      this.parsedTemplate.push({ isPlaceholder: true, key });

      lastIndex = startIndex + placeholder.length;
    });

    if (lastIndex < template.length) {
      const text = template.substring(lastIndex);
      this.textState[textPartIndex] = {
        value: text,
        originalValue: text,
        isEdited: false,
      };
      this.parsedTemplate.push({
        isPlaceholder: false,
        text: text,
        index: textPartIndex,
      });
    }
   this.saveTemplateState();
   this.updatePaiButtonState();
  }

  onInputChange(
    event: Event,
    isPlaceholder: boolean,
    key?: string,
    index?: number
  ) {
    const value = (event.target as HTMLElement).innerText;
    this.inputSubject.next({ key: key || "", value, isPlaceholder, index });
  }

  onPlaceholderClick(key: string) {
    const currentState = this.placeholderState[key];
    if (currentState.isAccepted) {
      this.placeholderState[key].status = "edited";
      this.placeholderState[key].isAccepted = false;
    } else {
      this.placeholderState[key].status = "accepted";
      this.placeholderState[key].isAccepted = true;
      this.allPlaceholdersAccepted();
    }
    this.updateAcceptedState();
    this.saveTemplateState();
    this.updatePaiButtonState();
  }

  getFieldStateFromPlaceholdersState() {
    const fieldSet = Object.entries(this.placeholderState).map(
      ([_, state]) => ({
        key: state.originalPlaceholderForFieldSet,
        val: state.value,
      })
    );
    return fieldSet;
  }

  allPlaceholdersAccepted() {
    const allPlaceholders = Object.values(this.placeholderState);
    const acceptedPlaceholders = allPlaceholders.filter((e) => e.isAccepted);
    if (allPlaceholders.length === acceptedPlaceholders.length) {
      const fieldSet = this.getFieldStateFromPlaceholdersState();
      const finalText = this.getFinalText();
      this.saveCommitmentCallback(finalText, fieldSet);
    }
  }

  onBlur(event: Event, isPlaceholder: boolean, key?: string, index?: number) {
    const value = (event.target as HTMLElement).innerText.trim();
    if (isPlaceholder && key) {
      if (value === this.placeholderState[key].originalValue) {
        this.placeholderState[key].status = "default";
        this.placeholderState[key].isAccepted = false;
      }
    } else if (!isPlaceholder && index !== undefined) {
      if (value === this.textState[index].originalValue) {
        this.textState[index].isEdited = false;
      }
    }
  }

  disableAIButton() {
    return (
      Object.keys(this.placeholderState).length === 0 || !this.templateText
    );
  }


  private updatePaiButtonState(): void {
    if(!this.isAIUsedAlready()){
      this._paiButtonState = "disabled-state";
      return;
    }
    if (this.disableAIButton()) {
      this._paiButtonState = "disabled-state";
      return;
    }
  
    const hasEditedPlaceholders = Object.values(this.placeholderState).some(
      (state) => state.status === "edited"
    );
  
    const allPlaceholdersAccepted = Object.values(this.placeholderState).every(
      (state) => state.isAccepted
    );
  
    if (hasEditedPlaceholders) {
      this._paiButtonState = "edited-state";
    } else if (allPlaceholdersAccepted) {
      this._paiButtonState = "accepted-state";
    } else {
      this._paiButtonState = "default-state";
    }
  }

  handlePAIClick(event: Event) {
    if (!this.hasBeenClicked) {
      this.fetchPAIData();
      this.hasBeenClicked = true;
      this._paiButtonState = "default-state" //so the loader is blue
    } else {
      event.preventDefault();
    }
  }

  async fetchPAIData() {
    try {
      this.isLoading = true
      const response = await this.autoFillCallback();
      Object.entries(response).forEach(([key, value]) => {
        if (this.placeholderState[key]?.originalValue) {
          this.placeholderState[key] = {
            ...this.placeholderState[key],
            value: String(value),
            suggestedValue: value ? String(value) : null,
            status: isNA(value) ? "unsure" : "autofilled",
            isAccepted: false,
          };
        }
      });
      this.saveTemplateState();
      this.updatePaiButtonState();
    } catch (error) {
      console.error("Error fetching PAI data:", error);
    }
    this.isLoading = false
  }

  handleMarkAllComplete() {
    Object.keys(this.placeholderState).forEach((key) => {
      this.placeholderState[key].isAccepted = true;
      this.placeholderState[key].status = "accepted";
    });
    const finalText = this.getFinalText();
    const fieldSet = this.getFieldStateFromPlaceholdersState();
    this.updateAcceptedState();
    this.saveCommitmentCallback(finalText, fieldSet);
    this.saveTemplateState();
    this.updatePaiButtonState();
  }

  trackByIndex(index: number): number {
    return index;
  }

  hasMoreOriginalValues(parsedTemplate: any[], currentIndex: number): boolean {
    return parsedTemplate.slice(currentIndex + 1).some(part => 
      this.placeholderState[part.key]?.originalValue && 
      !this.placeholderState[part.key]?.isAccepted
    );
  }
}
