import { Injectable } from '@angular/core';
import { BaseAiSectionsService, SectionInfo } from '../base-ai-sections.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertySectionService extends BaseAiSectionsService {

  attributes = {}
  
  async getPropertySectionInfoFromAI(sectionInfo:SectionInfo, registryOptions, instrumentOptions){
    const registryNames = registryOptions.map(e=>e.Name || e.label)
    const instrumentNames = instrumentOptions.map(e=>e.Name || e.Interest_Type_Name)
    this.attributes = {
      Parcel: '',
      Development_Name: '',
      Registry: `select any one value from this list :- ${JSON.stringify(registryNames)}`,
      Legal_Description: '',
      Interest_Type_Id: `select any one value from this list :- ${JSON.stringify(instrumentNames)}`,
      Report_Lat: 'Latitude of the Property',
      Report_Lng: 'Longitude of the Property',
      Order_Subdivision: ''
    }
    const response = await this.getSectionInfoFromAI(sectionInfo).toPromise()
    if(response.Interest_Type_Id && response.Interest_Type_Id !=='NA'){
      const type = instrumentOptions.find(e=>e.Name === response.Interest_Type_Id)
      response.Interest_Type_Id = type ? type.Id : null
    }
    if(response.Registry && response.Registry !=='NA'){
      const registry = registryOptions.find(e=>e.Name === response.Registry)
      response.Registry = registry ? registry.Id : null
    }
    return response
  }

  getSectionInfoFromAI(sectionInfo: SectionInfo): Observable<any> {
    return this.reportBuilder.getSectionDataFromAI(this.attributes, sectionInfo); 
  } 
}
