import { forkJoin as observableForkJoin, Subject } from "rxjs";
import { Component, OnInit } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import * as momentTimeZone from "moment-timezone";
import {
  UsersService,
  ConfigService,
  PagerService,
  StateService,
  AuthService,
  ScrollService,
  OrdersService,
  SchedulingService,
} from "../../services";
import { CONSTANTS } from "../../app.constants";
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from "../../dialog/error-dialog/error-dialog.component";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { OrderAssignComponent } from "../../dialog/order-assign/order-assign.component";
import { EtaChangeReasonComponent } from "../../dialog/eta-change-reason/eta-change-reason.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-user-status",
  templateUrl: "./user-status.component.html",
  styleUrls: ["./user-status.component.scss"],
})
export class UserStatusComponent implements OnInit {
  users = [];
  usersToDisplay: any;
  totalCount: number;
  pager: any = {};
  listingType = "all";
  searchString: string;
  searchInProgress: boolean = false;
  pageNum: number;
  scrollEnable: boolean = true;
  constants = CONSTANTS;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  isErrorModal: boolean = true;
  searchQuery = new Subject<string>();
  sortField: string;
  orderBy: string;
  holidayList: any;
  timer: any = [];
  constructor(
    private router: Router,
    private pagerService: PagerService,
    private auth: AuthService,
    private config: ConfigService,
    private userService: UsersService,
    private stateService: StateService,
    private matDialog:MatDialog,
    private scrollService: ScrollService,
    private orderService: OrdersService,
    private holidayScheduling: SchedulingService
  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined) this.pageNum = 1;
    this.searchString = "";
    this.orderBy = "DESC";
    this.sortField = "Admin_InProgress_Count";
    this.searchQuery
      .pipe(debounceTime(this.config.getDebounceTime()), distinctUntilChanged())
      .subscribe((value) => this.searchField(value));
  }

  ngOnInit() {
    this.getholidaylistFromLocal();
    if (this.stateService.getUsers()) {
      this.initializeComponentData(this.stateService.getUsers());
    } else this.getInititalUsers();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(35), 0));
  }

  detectTypeChange(event) {
    this.pageNum = 1;
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosOperStat(x);
    this.getInititalUsers();
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  setDisplayUsers() {
    this.usersToDisplay = this.totalCount;
    this.pageNum = this.totalCount / this.config.getNumRecordsPerPage();
  }

  getUsers() {
    this.pageNum = 1;
    this.stateService.setUsers(this);
    this.userService
      .getOperUserStatus(
        this.getSearchString(),
        this.listingType,
        this.orderBy,
        this.sortField
      )
      .subscribe(
        (result) => {
          if (result) {
            this.users = result.rows;
            this.totalCount = result.count;
            console.log("Total users: ", this.totalCount);
            this.setDisplayUsers();
            console.log("Now displaying", this.usersToDisplay);
            this.pager = this.pagerService.getPager(
              this.totalCount,
              this.pageNum
            );
            this.searchInProgress = false;
          }
        },
        () => {
          this.searchInProgress = false;
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        }
      );
  }

  getInititalUsers() {
    if (this.sortField == "Recent_Order")
      this.sortField = "Admin_InProgress_Count";
    if (this.pageNum == 1) {
      this.stateService.setUsers(this);
      this.userService
        .getOperUserStatus(
          this.getSearchString(),
          this.listingType,
          this.orderBy,
          this.sortField
        )
        .subscribe(
          (result1) => {
            this.users = result1.rows;
            this.totalCount = result1.count;
            this.getUsersData(this.totalCount);
            this.getScrollPosData();
          },
          () => {
            this.searchInProgress = false;
            this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          }
        );
    } else {
      this.getRetainData();
    }
  }

  getRetainData() {
    var ObsCollection = [];
    ObsCollection.push(
      this.userService.getOperUserStatus(
        this.getSearchString(),
        this.listingType,
        this.orderBy,
        this.sortField
      )
    );

    observableForkJoin(ObsCollection).subscribe((retainedAdmin) => {
      if (retainedAdmin) {
        var totalOrders = [];
        for (var i = 0; i < retainedAdmin.length; i++) {
          this.stateService.setUsers(this);
          totalOrders = totalOrders.concat(retainedAdmin[i]["rows"]);
        }
        this.users = totalOrders;
        this.totalCount = retainedAdmin[0]["count"];
        this.getUsersData(this.totalCount);
        this.getScrollPosData();
      }
    });
  }

  getSearchString() {
    var format = /[!#$%^&*()\=\[\]{};':"\\|,<>\/?]/;
    if (format.test(this.searchString)) {
      return "";
    } else return this.searchString;
  }

  findUsers() {
    this.pageNum = 1;
    this.searchInProgress = true;
    this.getInititalUsers();
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.totalCount = data.totalCount;
    this.usersToDisplay = data.usersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.listingType = data.listingType;
    this.sortField = data.sortField;
    this.orderBy = data.orderBy;
    this.getInititalUsers();
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog
      .open(
        ErrorDialogComponent,
        {data:{
          message: msg,
        },
        ...this.config.getDialogOptions()}
      );
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold())
          this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else this.scrollEnable = true;
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    // if (this.pageNum < this.pager.totalPages) {
    //   this.pageNum++;
    //   this.userService.getOperUserStatus(this.getSearchString(), this.listingType, this.orderBy, this.sortField)
    //     .subscribe((result) => {
    //       if (result) {
    //         this.config.resetShowFlag()
    //         this.totalCount = result.count;
    //         if (this.totalCount > this.users.length) {
    //           this.users = this.users.concat(result.rows);
    //           this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
    //         }
    //       }
    //     })
    // }
  }

  getUsersData(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayUsers();
    this.pager = this.pagerService.getPager(
      result,
      this.pageNum,
      this.config.getNumRecordsPerPage()
    );
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  goToUserDetails(userId) {
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosOperStat(getPos);
    this.router.navigate(["/user/" + userId]);
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosOperStat()) {
      var x = this.scrollService.getScrollPosOperStat();
      this.timer.push(
        setTimeout(function () {
          window.scrollTo(0, x);
        })
      );
    }
  }

  showOrderSearch(event, adminDetails, flag) {
    event.stopPropagation();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        Admin_User_ID: JSON.stringify([adminDetails.User_ID]),
        Completed_Type: flag,
      },
    };
    this.router.navigate(["/orders/search"], navigationExtras);
  }

  searchField(search) {
    this.scrollService.setScrollPosOperStat(0);
    var format = /[!#$%^&*()\=\[\]{};':"\\|,<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if (this.searchString) {
      let dotIndex = this.searchString.indexOf(".");
      if (dotIndex == 0 && this.isErrorModal) {
        this.isErrorModal = false;
        this.openErrorPopup(
          "First character cannot be dot(.) in search field."
        );
      } else if (dotIndex != 0) {
        if (!format.test(this.searchString) && this.searchString == " ") {
          this.searchString = "";
          this.getInititalUsers();
        } else this.findUsers();
        this.isErrorModal = true;
      }
    } else {
      this.findUsers();
      this.isErrorModal = true;
    }
  }

  setSort(field: string) {
    this.pageNum = 1;
    console.log("setSort", field);
    if (field == this.sortField) {
      if (this.orderBy == "ASC") this.orderBy = "DESC";
      else this.orderBy = "ASC";
    } else {
      this.sortField = field;
      this.orderBy = "ASC";
    }
    this.getInititalUsers();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == "ASC") this.orderBy = "DESC";
      else this.orderBy = "ASC";
    } else {
      this.sortField = field;
      this.orderBy = "DESC";
    }
    this.getInititalUsers();
  }

  setSortRecentOrder(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == "ASC") {
        this.orderBy = "DESC";
        this.users.sort((a, b) => b.Dur_in_Minutes - a.Dur_in_Minutes);
      } else {
        this.orderBy = "ASC";
        this.users.sort((a, b) => a.Dur_in_Minutes - b.Dur_in_Minutes);
      }
    } else {
      this.sortField = field;
      this.orderBy = "DESC";
      this.users.sort((a, b) => b.Dur_in_Minutes - a.Dur_in_Minutes);
    }
  }

  goToOrderDetails(orderId) {
    if (this.checkAdminType()) {
      var getPos = document.documentElement.scrollTop;
      this.scrollService.setScrollPosOperStat(getPos);
      this.router.navigate(["/orders/" + orderId + "/view"]);
    }
  }

  checkAdminType() {
    if (
      Number(this.auth.getSearchMasterType()) ==
      CONSTANTS.userType.ReadOnlyAdmin
    )
      return false;
    else return true;
  }

  assignNewOrder(user) {
    this.orderService.getUnassignedOrders().subscribe((orderList) => {
      let disposable = this.matDialog
        .open(
          OrderAssignComponent,
          {data:{
            title: `${user.User_First_Name} ${user.User_Last_Name} - Assign order`,
            message: {
              orderList: orderList,
              selectedAdmin: user.User_ID,
            },
            listOfHolidays: this.holidayList,
          },
          ...this.config.getDialogOptions()}
        ).afterClosed()
        .subscribe(
          (res) => {
            if (res) {
              if (res.Order_ETA || res.Order_ETA_Range) this.confirmOrder(res);
              else this.assignRecievedOrder(res);
            }
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }

  isETAChanged(eta, Orig_ETA) {
    var oldETA;
    var newETA;
    if (Orig_ETA) {
      let estShift = momentTimeZone.tz(Orig_ETA, "America/New_York").isDST()
        ? 4
        : 5;
      oldETA = momentTimeZone
        .tz(Orig_ETA, "America/New_York")
        .add(estShift, "hours")
        .format("MM-DD-YYYYTHH:mm");
    } else oldETA = null;
    if (eta)
      newETA = momentTimeZone
        .tz(eta, "America/New_York")
        .format("MM-DD-YYYYTHH:mm");
    else newETA = null;
    if (oldETA != newETA) return true;
    else return false;
  }

  confirmOrder(res) {
    let msgType;
    if (res.msgText) msgType = CONSTANTS.messageType.internalNotes;
    else {
      res.msgText = "";
      msgType = CONSTANTS.messageType.internalNotes;
    }
    // if (this.isETAChanged(res.Order_ETA, res.Orig_ETA)) {
    //   this.openETAChangeMsgInterface(
    //     res.Order_ID,
    //     res.admin,
    //     res.Order_ETA,
    //     res.msgText,
    //     msgType,
    //     res.getTimeToCompl,
    //     res.Order_ETA_Range
    //   );
    // } else {
    //   this.assignOrder(
    //     res.Order_ID,
    //     res.admin,
    //     res.Order_ETA,
    //     res.msgText,
    //     msgType,
    //     res.getTimeToCompl,
    //     res.Order_ETA_Range,
    //     "",
    //     ""
    //   );
    // }
    this.assignOrder(
      res.Order_ID,
      res.admin,
      res.Order_ETA,
      res.msgText,
      msgType,
      res.getTimeToCompl,
      res.Order_ETA_Range,
      res.Order_ETA_Comment ? res.Order_ETA_Comment : '',
      res.Order_ETA_Change_Reason_Type_ID ? res.Order_ETA_Change_Reason_Type_ID : '',
      res.hasOwnProperty('Notify_Customer') ? res.Notify_Customer : null,
      res.Client_Notif_Status ? res.Client_Notif_Status : null,
      res.Email_Body ? res.Email_Body : null,
    );
  }

  // openETAChangeMsgInterface(
  //   orderId,
  //   assignUserId,
  //   orderETA,
  //   msgText,
  //   msgType,
  //   getTimeToCompl,
  //   orderETARange
  // ) {
  //   let disposable = this.matDialog
  //     .open(
  //       EtaChangeReasonComponent,
  //       {data:{},
  //       ...{ closeByClickingOutside: false }}
  //     ).afterClosed()
  //     .subscribe((res: any) => {
  //       if (res.state) {
  //         this.assignOrder(
  //           orderId,
  //           assignUserId,
  //           orderETA,
  //           msgText,
  //           msgType,
  //           getTimeToCompl,
  //           orderETARange,
  //           res.Message_Text,
  //           res.Order_ETA_Change_Reason_Type_ID
  //         );
  //       }
  //     });
  // }

  assignOrder(
    orderId,
    assignUserId,
    orderETA,
    msgText,
    msgType,
    getTimeToCompl,
    orderETARange,
    orderETAChangeReason,
    Order_ETA_Change_Reason_Type_ID,
    notifCust,
    clinNotifSts,
    emailbody
  ) {
    let additionalInfo = {
      Notify_Customer: notifCust,
      Client_Notif_Status: clinNotifSts,
      Email_Body: emailbody,
    }
    this.orderService
      .initOrderProcess(
        orderId,
        assignUserId,
        orderETA,
        msgText,
        msgType,
        getTimeToCompl,
        orderETARange,
        orderETAChangeReason,
        Order_ETA_Change_Reason_Type_ID,
        additionalInfo
      )
      .subscribe(
        () => this.getInititalUsers(),
        () => this.openErrorPopup(CONSTANTS.apiErrors.orderassign)
      );
  }

  assignRecievedOrder(res) {
    let msgType;
    if (res.msgText) msgType = CONSTANTS.messageType.internalNotes;
    else {
      res.msgText = "";
      msgType = CONSTANTS.messageType.internalNotes;
    }
    this.orderService
      .screenOrder(
        res.Order_ID,
        res.admin,
        res.msgText,
        msgType,
        res.getTimeToCompl
      )
      .subscribe(
        () => this.getInititalUsers(),
        () => this.openErrorPopup(CONSTANTS.apiErrors.orderassign)
      );
  }

  getholidaylistFromLocal() {
    let allHolidays = JSON.parse(sessionStorage.getItem("holidays"));
    if (allHolidays) this.setHoliday(allHolidays);
    else this.getholidaylist();
  }

  setHoliday(holiday) {
    this.holidayList = holiday;
  }

  getholidaylist() {
    this.holidayScheduling.getAllHolidays().subscribe(
      (holidays) => {
        if (holidays) {
          this.setHoliday(holidays);
          sessionStorage.setItem("holidays", JSON.stringify(holidays));
          setTimeout(function () {
            sessionStorage.removeItem("holidays");
          }, CONSTANTS.sessiontimeOut.timeOut);
        }
      },
      (err) => {
        console.log("Error", err);
      }
    );
  }

  checkIfSearchManager() {
    if (this.auth.isSearchManager()) return true;
    else return false;
  }
  
}
