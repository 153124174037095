import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { RptBuilderService } from "../rpt-builder.service";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, take, takeUntil } from "rxjs/operators";
import { CONSTANTS } from "app/app.constants";
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { RbDeedSectionComponent } from "./rb-deed-section/rb-deed-section.component";
import { getNextSortOrder } from "app/utils/number-utils";

@Component({
  selector: 'app-rb-deed',
  templateUrl: './rb-deed.component.html',
  styleUrls: ['./rb-deed.component.scss']
})
export class RbDeedComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  dataForm: UntypedFormGroup;
  sectionObject: any = {};
  basicSectionObject: any = {};
  searchPackage: any = {};
  sectionOptions: any;
  sectionData: any = [];
  dbSectionData: any = [];
  generalComments = null;
  deletedSectionData:any = [];
  isDragable: boolean = true;  
  constants= CONSTANTS;
  @ViewChildren('section') sections!: QueryList<RbDeedSectionComponent>;
  constructor(private builder: RptBuilderService,private frmBuilder: UntypedFormBuilder) {
    this.dataForm = this.frmBuilder.group({
      CT_Manual_Sort: [false],
    });
    this.dataForm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe(() => this.saveToDb(this.dataForm));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    this.builder.dataTypes$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (data) =>
          (this.sectionOptions = data ? data.SP_Chain_Of_Title_Entities : null)
      );
    this.builder.basic$
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data) => {
      if (data && data.dbData) {
        this.basicSectionObject = data;
        this.searchPackage = data.dbData;
        this.dataForm.controls["CT_Manual_Sort"].patchValue(
          this.searchPackage.CT_Manual_Sort == 1,
          { emitEvent: false }
        );
      }
    });
    this.builder.cot$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComponent(data));
    this.builder.generalComments$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initComments(data));
    this.builder.deletedItems$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => this.initDeletedItems(data));
  }

  initComments(data) {
    if (data && data.curData) {
      let comment = data.curData.find(
        (gc) =>
          gc.Comments_Type_ID == CONSTANTS.srchPkgItmTypes.chnOfTtl
      );
      this.generalComments = comment !== undefined ? comment : null;
    }
  }

  initComponent(data) {
    this.sectionObject = data;
    //modifiying thecondtion below because adding new assessments is not refrshing the list
    // if (data && data.curData && this.getLength() == 0) {
    if (data && data.curData && data.dbData) {
      this.sectionData = data.curData;
      this.dbSectionData = data.dbData;
    } else {
      this.sectionData = [];
      this.dbSectionData = [];
    }
  }

  initDeletedItems(data) {    
    if(data){
      if(data.hasOwnProperty('SP_Chain_Of_Titles_CT')) this.deletedSectionData = data['SP_Chain_Of_Titles_CT'];      
    }
  }

  getLength() {
    return this.sectionData ? this.sectionData.length : 0;
  }

  removeGenMessage() {
    let copyGenCom = {...this.generalComments}
    this.generalComments.Status = 0;
    this.builder.updateGeneralComment(this.generalComments, copyGenCom, this.searchPackage.Id, true).subscribe();
  }

  addGenMessage() {
    if (this.generalComments) this.generalComments.Status = 1;
    else {
      this.generalComments = {
        Id: "New",
        Sp_Id: this.searchPackage.Id,
        Comments: "",
        Comments_Type_ID: CONSTANTS.srchPkgItmTypes.chnOfTtl,
      };
    }
    this.builder.updateGeneralComment(this.generalComments, null, this.searchPackage.Id, true).subscribe();
  }

  addNewSectionItem(item, sortOrder) {
    const sortOrderOfNewItem = sortOrder > 0 ? sortOrder : getNextSortOrder(this.sectionData)
    let deed = {
      Id: "New",
      Sp_Id: this.searchPackage.Id,
      Entity_ID: item.Id,
      Instrument_Type_ID: null,
      Rec_Date: null,
      Instrument_Num: null,
      Deed_Consideration: null,
      Page:null,
      Comments: "",
      Sort_Order:sortOrderOfNewItem,
      Applies: 1,
      Derived_From: null,
      Status: 1,
      Is_Vesting_Deed: 0
    };
    if (item.Id == 1) {
      deed["Grantor"] = "";
      deed["Grantee"] = "";
      deed["Dated_Date"] = null;
    }
    if (item.Id == 2) {
      deed["Estate_Of"] = "";
      deed["Beneficiaries"] = "";
      deed["Date_Of_Death"] = null;
    }
    let payload = {
      curData: deed,
      dbData: null,
      Sp_Id: this.searchPackage['Id'],
      Derived_From: this.searchPackage['Derived_From']
    }    
    this.builder
      .saveChain(payload, true)
      .pipe(take(1))
      .subscribe(() => { setTimeout(() => {
        const index = this.sectionData.findIndex(e=> e.Sort_Order === sortOrderOfNewItem)
        this.focusAddedItem(index);
      }),100});
  }

  isDragEnable(val) {
    if (val) {
      this.isDragable = false;
    } else {
      this.isDragable = true;
    }
  }

  getdbData(deed) {
    let dbData = this.dbSectionData.find(
      (item) => item.Id == deed.Id
    );
    if (dbData !== undefined) return dbData;
    return null;
  }

  dropSectionBlock(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.sectionData,
      event.previousIndex,
      event.currentIndex
    );
    this.sectionData.forEach((item, index) => {
      item.Sort_Order = index;
    });
    let payload = {
      curData: this.sectionData,
      dbData: this.dbSectionData,
      Sp_Id: this.searchPackage['Id'],
      Derived_From: this.searchPackage['Derived_From']
    }
    this.builder.updateChainSortOrder(payload).subscribe();
  }
  
  saveToDb(event) {
    if (!event.pristine) {
      this.basicSectionObject.curData = this.searchPackage;
      this.basicSectionObject.Sp_Id = this.searchPackage.Id;
      this.basicSectionObject.Derived_From = this.searchPackage.Derived_From;
      this.builder.saveBasicData(this.basicSectionObject).pipe(take(1)).subscribe();
    }
  }

  updateCotSort(data) {
    this.searchPackage.CT_Manual_Sort = data.checked ? 1 : 0;
  }

  focusAddedItem(index){
    if(this.sections && this.sections.length > 0){
      if(this.sections.get(index).firstInput) this.sections.get(index).firstInput.focus()
    }
  }

  focusFirsttItem(){
    if(this.sections && this.sections.length > 0){
      if(this.sections.first['firstInput']) this.sections.first['firstInput'].focus()
    }
  }
}
