// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nowrap {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/app/abstractor/abstractor-list/abstractor-list.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ","sourcesContent":[".nowrap {\n    white-space: nowrap;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
